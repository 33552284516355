import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import {
  BASE_URL,
  RESEND_VERIFICATION_URL,
  auth_token,
} from "../../services/config";
// import useRegionData from "../../Componets/regions/regions";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Redux/Slice/authSlice";
import { setShowProfile } from "../../Redux/Slice/showprofileSlice";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { setOTPVerifyData } from "../../Redux/Slice/signupDetailsSlice";
import { setModalClose, setModalOpen } from "../../Redux/Slice/loginModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";
import { setShowMobileRegisterModal } from "../../Redux/Slice/mobileregisterModalSlice";

export default function OtpVerification({
  setShowOTPPopup,
  showOTPPopup,
  handleOtpPopupClose,
}) {
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const logo = "/images/win-logo.png";
  const LoginPoster = "/images/loginposter.jpg";
  const OtherRegionLoginPoster = "/images/WEBLOGINOther.jpg";

  const { registerDetails } = useSelector((state) => state.signup_data);
  const registerData = registerDetails?.data;

  useEffect(() => {
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
  }, []);

  const regionsData = useSelector((state) => state.region.regionsData);
  // const firebaseAnalytics = getAnalytics();
  const [currentIndex, setCurrentIndex] = useState(null);

  const [otps, setOtps] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [timer, setTimer] = useState(35);
  const [timerCompleted, setTimerCompleted] = useState(false);
  const dispatch = useDispatch();
  const [showSubscripeButton, setshowSubscripeButton] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setTimerCompleted(true);
    }
  }, [timer]);

  const handleInputChange = (index, value) => {
    const newOtp = [...otps];
    newOtp[index] = value;
    setOtps(newOtp);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otps[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  const fetchActivePlans = async (sessionID) => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
      const response = await axios.get(nextApiUrl);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        if (responsedata && responsedata.length > 0) {
          setshowSubscripeButton(false);
          localStorage.setItem("showSubscripeButton", false);
        }

        if (responsedata && responsedata.length == 0) {
          setshowSubscripeButton(true);
          localStorage.setItem("showSubscripeButton", true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    } finally {
      const showSubscripeButton = localStorage.getItem("showSubscripeButton");
      if (showSubscripeButton === "true") {
        // window.location.href = '/subscription';
        // window.location.href = '/home';
      } else {
        // window.location.reload();
      }
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();

    const enteredOTP = otps.join("");

    if (!enteredOTP) {
      setError("Please enter OTP");
      setCurrentIndex(0); 
      inputRefs[0]?.current?.focus();
      return;
    }

    const OTP_VERIFICATION_URL = `${BASE_URL}users/verification/${enteredOTP}?mobile_number=${registerData?.mobile_number}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
    setLoading(true)
    try {
      const response = await axios.get(OTP_VERIFICATION_URL);

      if (response?.status === 200) {
        dispatch(setOTPVerifyData({ otpVerifyData: response?.data?.data }));

        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("signup_success", {
            u_id: response?.data?.data?.user_id,
            method: "phone number",
            event_id: 3,
            platform:"web",
            device_id: "NA",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

        
        localStorage.setItem("session_id", response?.data?.data?.session);
        localStorage.setItem("addprofileInfo", true);
        dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
        setShowOTPPopup(false);
        dispatch(setShowRegisterModal({ showRegisterModal: false }))
        setOtps(["", "", "", "", "", ""]);

        localStorage.setItem("login_status", true);
        dispatch(
          setAuth({
            isLoggedIn: true,
            sessionId: response?.data?.data?.session,
          })
        );
        dispatch(setShowProfile({ showAddProfile: true }));
        localStorage.setItem("user_id", response?.data?.data?.user_id);
        // toast.success('Successfully Loggedin')
        await fetchActivePlans(response?.data?.data?.session);
        dispatch(setModalClose());
        // setShowLoginOTPPopup(false);
        // setLoginOTPs(["", "", "", "", "", ""]);
        setOtps(["", "", "", "", "", ""]);
        // window.sdk.trackEvent("login_success", {
        //   u_id: response?.data?.data?.user_id,
        //   method: "phone number",
        //   event_id: 5,
        //   platform:"web",
        //   device_id: "NA",
        //   city: regionsData?.city_name,
        //   state: regionsData?.state,
        //   ip: regionsData?.ip,
        //   postal_code: regionsData?.postal_code,
        // });

        // logEvent(firebaseAnalytics, "login_success", {
        //   method: "phone number",
        //   u_id: response?.data?.data?.user_id,
        //   device_id: "NA",
        // });
        toast.success("OTP Verified Successfuly");
      }
    } catch (err) {
      console.error("Server responded with an error:", err?.response?.data?.error?.message);

      if (err?.response?.status === 422) {
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("signup_failure", {
            error_type: err?.response?.data?.error?.message,
            method: "phone number",
            event_id: 4,
            platform:"web",
            device_id: "NA",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

      

        // logEvent(firebaseAnalytics, "signup_failure", {
        //   method: "phone number",
        //   error_type: error?.response?.data?.error?.message,
        //   device_id: "NA",
        // });
        if (err?.response?.data?.error?.message === "Enter valid OTP") {
          setError(err?.response?.data?.error?.message);
          setCurrentIndex(0); 
          setOtps(["", "", "", "", "", ""]);
          inputRefs[0]?.current?.focus(); 
        }
      }
    }finally {
      setLoading(false); 
    }
  };

  const handleResendOTP = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        RESEND_VERIFICATION_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            email_id: registerData?.mobile_number,
            region: regionsData?.country_code2,
            type: "msisdn",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success(response?.data?.data?.messgae);
        setOtps(["", "", "", "", "", ""]);

        setTimer(35);
        setTimerCompleted(false);
      }
    } catch (error) {
      console.error(
        "Server responded with an error:",
        error?.response?.data?.error?.message
      );

      if (error?.response?.status === 422) {
        if (error?.response?.data?.error?.message === "Enter valid OTP") {
        }
      }
    }
  };

  const LoginImage = "/images/login-left.png";

  const userConfig = useSelector((state) => state.user_config.userConfigData);

  return (
    <>
      {showOTPPopup ? (
        <>
          <Modal
            show={showOTPPopup}
            onHide={handleOtpPopupClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            className="border-class"
          >
            <Modal.Body>
              <div className="card-deck row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 padding-rt-40">
                <div className="d-none d-lg-block">
                <img src={regionsData?.country_code2 === "IN" ? LoginPoster : OtherRegionLoginPoster} className="w-100 rounded" loading="lazy" alt="Login Poster" height={400} /> 
                        </div>

                  <div className="login-left-text d-none">
                    <ul>
                      <li>
                        <p>Lorem Ipsum is simply</p>
                      </li>
                      <li>
                        <p>Lorem Ipsum ***</p>
                      </li>
                      <li>
                        <p>Lorem Ipsum is simply dummy text</p>
                      </li>
                      <li>
                        <p>Lorem Ipsum is simply dummy text of the printing </p>
                      </li>
                      <li>
                        <p>Lorem Ipsum is simply dummy text of the printing </p>
                      </li>
                      <li>
                        <p>Lorem Ipsum is simply dummy text of the printing </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                  <div className="login-left-styles">
                    <h6>OTP Verification</h6>
                    {error ? (
                      <p className="text-danger">{error} </p>
                    ) : (
                      <>
                        <p>{registerDetails?.message}</p>
                        <p>Enjoy ETV Win with More Benefits and Features </p>
                      </>
                    )}

                    <div className="pad-t-b">
                      <p className="fs-5 text-danger">
                        {registerData?.mobile_number.slice(4)}
                      </p>
                    </div>
                  </div>
                  <div className="form-style register">
                    <div className="form-style">
                      <form>
                        <ul className="otp-input">
                          {otps.map((digit, index) => (
                            <li key={index}>
                              <input
                                key={index}
                                autoComplete="off"
                                ref={inputRefs[index]}
                                type="text"
                                value={digit}
                                // onChange={(e) =>
                                //   handleInputChange(index, e.target.value)
                                // }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleInputChange(index, value);
                                  }
                                }}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                maxLength="1"
                                onFocus={() => setCurrentIndex(index)}
                                className={
                                  currentIndex === index ? "red-border" : ""
                                }
                              />
                            </li>
                          ))}
                        </ul>
                        <button
                          onClick={handleVerifyOTP}
                          className="next-button-color"
                        >
                         {loading ? <Spinner /> : 'Activate'}
                        </button>
                      </form>
                    </div>
                    <div className="time-div">
                      {timerCompleted ? (
                        <Link onClick={handleResendOTP}>Resend OTP</Link>
                      ) : (
                        <p>
                          Retry in{" "}
                          <span>00:{timer < 10 ? `0${timer}` : timer}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
}
