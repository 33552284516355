import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Componets/Header/Header";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Footer from "../../Componets/Footer/Footer";
import { useParams } from "react-router";
import { BASE_URL, acceptedMediaTypes, access_token, auth_token } from "../../services/config";
import axios from "axios";
import Slider from "react-slick";
import { horizontalmodel, verticalmodel } from "../../layout/gridmodel";
import { useNavigate, Link } from "react-router-dom";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import Loader from "../../Componets/loader.js";
import {
  SkeletonLoad,
  SkeletonLoadBox,
  SkeletonLoadBoxShows,
  SkeletonLoadSlideWinOriginals,
  SkeletonLoadSlider,SkeletonLoadBoxMOre,
} from "../skeletonLoad/skeletonthumbnail.jsx";
import {
  useComponentUtils,
} from "../../layout/utilscomponent";
import { useDispatch, useSelector } from "react-redux";
import { setTraynamedata } from "../../Redux/Slice/navlinkcallSlice.js";
const MoreDetails = () => {
  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const horizontaldefultimage = "/images/verticaldefault.jpg";
  const etv_exclusive_banner_image = "/images/etv-exclusive_banner.png";
  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const top10_image = "/images/top10default.jpg";
  const twoGridDefault = "/images/twogridimage.jpg";
  const singleGridDefault = "/images/singlegriddefault.jpg";
  const nonvegicon = "/images/nonveg.png";
  const vegicon = "/images/veg.png";
  const effectRan = useRef(false);
  const [currentImageClick, setCurrentImageClick] = useState(null);

  const [movieGenresList, setMovieGenresList] = useState([]);
  const regionsData = useSelector((state) => state.region.regionsData);
  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const [movieGenresData, setMovieGenresData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const dispatch =  useDispatch()
  const [healthGenresData, setHealthGenresData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [moreData, setMoreData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
    layout_type: "",
    filter_title: "",
  });
  // console.log("moreDatass",moreData);

  const [page, setPage] = useState(0);
  const [fromItem, setFromItem] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [isMoreAdded, setIsMoreAdded] = useState(true);
  const [catalogListData, setCatalogListData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [genresCatalogListData, setGenresCatalogListData] = useState([]);
  const [healthgenresCatalogListData, setHealthGenresCatalogListData] =
    useState([]);
  const [avilFilters, setAvilFilters] = useState([]);
  const {
    pageName,
    friendly_id,
    moreseo_url,
    category,
    main,
    channelID,
    pageName2,
    categoryItem,
    
  } = useParams();
  const seo_url = moreseo_url;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedvalue, setselectedValue] = useState("");
  const [selectedkey, setselectedKey] = useState("");
  const [initialApiCalled, setInitialApiCalled] = useState(false);
  const [loadingbusy, setLoadingbusy] = useState(false);
  const [moredataLoading, setMoredataLoading] = useState(false);
  const [isrecordsfound, setIsrecordsfound] = useState(true);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
// Define a state variable to hold the total number of items
const [totalItemsCount, setTotalItemsCount] = useState(0);
// Define a state variable to hold the loading state
  const { renderCategoryBlockWithLayoutType } = useComponentUtils(
    movieGenresList,

    true,
    {},
    {},
    false,
    {},
    {},
    {}
  );
  //alert(JSON.stringify(selectedkey));
  const [lastItemIndex, setLastItemIndex] = useState(0);
  const SublistTitle = localStorage.getItem("SublistTitle");
  // console.log(channelID, "channelID")
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 640);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [isMobile]);
  
  const friendlyIdToUrlMap = {
    [pageName ? pageName : pageName2]: pageName
      ? `catalog_lists/${pageName}.gzip`
      : `catalog_lists/${pageName2}.gzip`,
  };
  // console.log("morepage");
  const getMoreDetailsUrl = () => {
    // const baseUrl = `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
    var parentId = localStorage.getItem("more_parent_id");
    var localstoragecategory = localStorage.getItem("more_category");
    if (friendlyIdToUrlMap[pageName] && channelID == undefined) {
      // console.log("if");
      let more_parent_id = 0;
      let more_category = "";
      if (parentId !== undefined) {
        more_parent_id = parentId;
      }
      if (localstoragecategory !== undefined) {
        more_category = localstoragecategory;
      }

      if (more_parent_id != 0 && more_category != "") {
        return `${BASE_URL}${friendlyIdToUrlMap[pageName]}?category=${more_category}&parent_id=${more_parent_id}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
      } else {
        return `${BASE_URL}${friendlyIdToUrlMap[pageName]}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
      }
    } else if (channelID != undefined && pageName != "") {
      // console.log("else if");
    
      return `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&${selectedkey}=${channelID}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
    } else {
      return;
    }
  };

  const getmoredetailscontent = getMoreDetailsUrl();
//alert("hello");
  // console.log("getmoredetailscontent", getmoredetailscontent);
  const loginStatus = localStorage.getItem("login_status")
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const getuserid = localStorage.getItem("user_id")
  const { navItemCalled } = useSelector((state) => state.navmenu_called);

// console.log("navItemCalled", navItemCalled)
function getPageName() {
  const path = window.location.pathname;
  const parts = path.split('/'); // Split path by '/'
  return parts[1] ?? "home"; // Return the second part after localhost/
}
const param1Value = getPageName();
const sourcetab = localStorage.getItem("selectedMenuItem");

const itemSelected = (index,listItem, moredata) => {
  // console.log("moredatassss", moredata.title?.toLowerCase())
  // console.log("listItemssss", listItem)
  // console.log("indexssssss", index)
  localStorage.setItem("traynamedata", moredata.title?.toLowerCase())
  dispatch(setTraynamedata({ traynamedata:  listItem}));

  if (window && window.sdk && window.sdk.trackEvent) {
    window.sdk.trackEvent("artwork_tapped", {
      source:sourcetab?.toLowerCase(),
      country: regionsData?.country_name,
      event_time: new Date().toISOString().slice(0, 19),
      video_name:listItem.title,
      tray_name: moredata.title?.toLowerCase() || "NA",
      platform: "web",
      event_id: 21,
      u_id:
      loginStatus === "true"
      ? getuserid
      : guestuserid,
      content_type: listItem.web_layout_type ? listItem.web_layout_type : 'NA',
      position_within_tray: index + 1,
      artwork_type: listItem.web_layout_type ? listItem.web_layout_type : 'NA',
 });

  } else {
    console.error('Analytics SDK is not initialized yet.');
    // Handle initialization state or queue events for later
  }

 

};
  const fetchMoreDetailsData = async () => {
    //  alert(selectedvalue);
       // const baseUrl = `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
       var parentId = localStorage.getItem("more_parent_id");
       var localstoragecategory = localStorage.getItem("more_category"); 
       const dropdownChannelKey = selectedkey || localStorage.getItem("dropdownChannelKey") || "genres";
       //var dropdownChannelKey =  dropdownChannelKey;
       // if(selectedkey == ""){
       //   selectedkey = dropdownChannelKey;
       // }
    //   alert(dropdownChannelKey);
      // setselectedKey(dropdownChannelKey);
       var dropdownChannelValue = channelID;
 
       var url= "";
      //  console.log("dropdownChannelKey",dropdownChannelKey);
      //  console.log("dropdownChannelValue",dropdownChannelValue);
 
       // if (friendlyIdToUrlMap[pageName] && channelID == undefined) {
       //   console.log("if");
         let more_parent_id = 0;
         let more_category = "";
         if (parentId !== undefined) {
           more_parent_id = parentId;
         }
         if (localstoragecategory !== undefined) {
           more_category = localstoragecategory;
         }
   
       //   if (more_parent_id != 0 && more_category != "") {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?category=${more_category}&parent_id=${more_parent_id}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   } else {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   }
       // } else 
       // if (channelID != undefined && pageName != "" && selectedkey!="") {
       //   = `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&${selectedkey}=${channelID}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // } else {
 
       // }
 
       const parameters = {
         from: fromItem,
         page_size: pageSize,
         page: page,
         start_count: 0
       };
       if (dropdownChannelValue && dropdownChannelKey) {
         parameters[dropdownChannelKey] = dropdownChannelValue;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }

       // if (sessionID) {
       //   parameters.session_id = sessionID;
       // }
       // if (pageName === "movies-popular-genres") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-heros-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-directors-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }else{
         
       // }
      if(categoryItem!== undefined){
        // console.log("firstttt")
       url= `${BASE_URL}catalog_lists/${categoryItem}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }
      else if(navItemCalled){
        // console.log("seconddddd")
       url= `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }else{
        // console.log("thirdddddd", pageName)
        url= `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;

      }

 
      //alert(url);
     //setLoadingbusy(false);
     setLoading(true);
     try {
       const response = await axios.get(url, { params: parameters });
       if (response.status === 200) {
         setLoading(false);
          // console.log("moreresp",response);
         setLoadingbusy(true);
         const newItems = response?.data?.data?.catalog_list_items || response?.data?.data?.items || [];
         const totalItemsCount = response?.data?.data?.total_items_count || 0;
         const uniqueTitles = new Set(moreData.data.map((item) => item.title));
       //  console.log(uniqueTitles, "uniqueTitles"); // Logging unique titles for debugging
       //  console.log("New items before filtering:", newItems);
 
         // Filter out new items with titles that already exist in the current data
         const filteredNewItems = newItems.filter(
           (newItem) => !uniqueTitles.has(newItem.title)
         );
         const updatedResults = [...moreData?.data,...newItems ];
 
         setMoreData((prevData) => ({
           ...prevData,
           data: updatedResults, // Append only filtered new data to existing data
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title?( response?.data?.data?.display_title): response?.data?.data?.title,
        layout_type: (response?.data?.data?.web_layout_type ?? response?.data?.data?.layout_type),
           filter_title: response?.data?.data?.filter_title,
         }));
         const newPage = page + 1;
         setPage(newPage);
         setFromItem(updatedResults.length);
 
         // if (moreData?.data?.length > 0) {
         //   setLoading(true);
         // }
         setAvilFilters(response?.data?.data?.available_filters);
 
         setCatalogListData((prevData) => ({
           ...prevData,
           data: newItems,
 
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title,
         }));
         setTotalItemsCount(totalItemsCount);
         setLastItemIndex((prev) => prev + newItems.length);
       }else{
         setLoadingbusy(false);
       }
     } catch (error) {
       setLoading(false);
       setLoadingbusy(false);
       console.error("Error fetching details:", error);
       if (error.message === "Request failed with status code 422") {
         console.log(error.message);
       } else if (error?.response?.status === 422) {
         console.log(error?.response?.data?.error?.message);
       }
     }
   };


   const fetchMoreData = async () => {
    //  alert(selectedvalue);
   
       // const baseUrl = `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
       var parentId = localStorage.getItem("more_parent_id");
       var localstoragecategory = localStorage.getItem("more_category"); 
       const dropdownChannelKey = selectedkey || localStorage.getItem("dropdownChannelKey") || "genres";
       //var dropdownChannelKey =  dropdownChannelKey;
       // if(selectedkey == ""){
       //   selectedkey = dropdownChannelKey;
       // }
    //   alert(dropdownChannelKey);
      // setselectedKey(dropdownChannelKey);
       var dropdownChannelValue = channelID;
 
       var url= "";
      //  console.log("dropdownChannelKey",dropdownChannelKey);
      //  console.log("dropdownChannelValue",dropdownChannelValue);
 
       // if (friendlyIdToUrlMap[pageName] && channelID == undefined) {
       //   console.log("if");
         let more_parent_id = 0;
         let more_category = "";
         if (parentId !== undefined) {
           more_parent_id = parentId;
         }
         if (localstoragecategory !== undefined) {
           more_category = localstoragecategory;
         }
   
       //   if (more_parent_id != 0 && more_category != "") {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?category=${more_category}&parent_id=${more_parent_id}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   } else {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   }
       // } else 
       // if (channelID != undefined && pageName != "" && selectedkey!="") {
       //   = `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&${selectedkey}=${channelID}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // } else {
 
       // }
 
       const parameters = {
         from: fromItem,
         page_size: 24,
         page: page,
         start_count: 0
       };
       if (dropdownChannelValue && dropdownChannelKey) {
         parameters[dropdownChannelKey] = dropdownChannelValue;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }
       
       // if (sessionID) {
       //   parameters.session_id = sessionID;
       // }
       // if (pageName === "movies-popular-genres") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-heros-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-directors-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }else{
         
       // }
      if(categoryItem!== undefined){
       url= `${BASE_URL}catalog_lists/${categoryItem}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }else{
       url= `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }
 
      //alert(url);
     //setLoadingbusy(false);
     try {
      if (moredataLoading) return; 
    setMoredataLoading(true);  
       const response = await axios.get(url, { params: parameters });
       if (response.status === 200) {
         setLoading(false);
          // console.log("moreresp",response);
         setLoadingbusy(true);
         const newItems = response?.data?.data?.catalog_list_items || response?.data?.data?.items || [];
         const totalItemsCount = response?.data?.data?.total_items_count || 0;
         const uniqueTitles = new Set(moreData.data.map((item) => item.title));
       //  console.log(uniqueTitles, "uniqueTitles"); // Logging unique titles for debugging
       //  console.log("New items before filtering:", newItems);
 
         // Filter out new items with titles that already exist in the current data
         const filteredNewItems = newItems.filter(
           (newItem) => !uniqueTitles.has(newItem.title)
         );
         const updatedResults = [...moreData?.data,...newItems ];
 
         setMoreData((prevData) => ({
           ...prevData,
           data: updatedResults, // Append only filtered new data to existing data
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title?( response?.data?.data?.display_title): response?.data?.data?.title,
           layout_type: (response?.data?.data?.web_layout_type ?? response?.data?.data?.layout_type),
           filter_title: response?.data?.data?.filter_title,
         }));
         const newPage = page + 1;
         setPage(newPage);
         setFromItem(updatedResults.length); 
 
         // if (moreData?.data?.length > 0) {
         //   setLoading(true);
         // }
         setAvilFilters(response?.data?.data?.available_filters);
 
         setCatalogListData((prevData) => ({
           ...prevData,
           data: newItems,
 
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title,
         }));
         setTotalItemsCount(totalItemsCount);
         setLastItemIndex((prev) => prev + newItems.length);
         setMoredataLoading(false) 
         if(response?.data?.data?.catalog_list_items?.length === 0){
           setIsrecordsfound(false);
         }else{
         setIsrecordsfound(true);
         }
        //  console.log('moresrsssskkk', response?.data?.data)
       }else{
        setIsrecordsfound(true);
         setLoadingbusy(false);
       }
     } catch (error) {
      setMoredataLoading(false)
       setLoadingbusy(false);
       console.error("Error fetching details:", error);
       if (error.message === "Request failed with status code 422") {
         console.log(error.message);
       } else if (error?.response?.status === 422) {
         console.log(error?.response?.data?.error?.message);
       }
     }
   };

  const handleMore = (link, data, identifyunic) => {

    fetchMoreData();
  navigate(link);
  window.location.reload();

  };


  const handleMoreDataScroll = () => {
    const scrollTop = (document?.documentElement?.scrollTop || document?.body?.scrollTop || 0);
    const scrollHeight = (document?.documentElement?.scrollHeight || document?.body?.scrollHeight || 0);
    const clientHeight = document?.documentElement?.clientHeight || window.innerHeight;
  
  const isMobile = window.innerWidth <= 768; 

  const threshold = isMobile ? 1000 : 50;

    if ((page) * pageSize <= totalItemsCount && scrollTop + clientHeight >= scrollHeight - threshold) {
      fetchMoreData();
    }
  };
  
  useEffect(() => {
    const onScroll = () => handleMoreDataScroll();
  
    // Attach scroll event listener when component mounts
    window.addEventListener("scroll", onScroll);
  
    // Cleanup scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [catalogListData, page, totalItemsCount, moredataLoading, categoryItem, moreseo_url]);  // Dependencies



  
  


const allDataRendered = moreData?.data?.length > 0 && moreData?.data?.length === totalItemsCount;

const FooterCondition = () => {
  const allDataRendered = moreData?.data?.length > 0 && moreData?.data?.length === totalItemsCount;
  if (isMobile && !loading && allDataRendered) {
    return <Footer />;
  } 
};

// useEffect(() => {
// window.addEventListener('scroll', handleScroll);

// return () => {
//     window.removeEventListener('scroll', handleScroll);
// };
// }, [filterdataLoading, filterPage, filterEpisodeItemsData]);


const subListClicked = (selectedValue, selectedKey) => {

  localStorage.setItem("dropdownChannelKey", selectedKey);
    // Find the corresponding avilFilter based on selectedValue
    const sublistItem = avilFilters.find((sub) => sub.title === selectedValue);

    if (sublistItem) {

      localStorage.setItem("SublistKey", sublistItem.key);

      localStorage.setItem("SublistTitle", sublistItem.title);
      setPage(0);
      setFromItem(0);
      setMoreData({
        data: [], // Clear data
        total_items_count: 0, // Reset total items count
        title: '', // Reset title
        layout_type: '', // Reset layout type
        filter_title: '', // Reset filter title
      });
      const pageLocation = window.location.href.split("/")[3];
      const channelName = window.location.href.split("/")[4];
      // console.log("selectedValue",selectedValue);
      // console.log("selectedKey",selectedKey);

      setselectedValue(selectedValue);
      setselectedKey(selectedKey);
      if (pageLocation === "channels") {
        navigate(
          `/${pageLocation}/${channelName}/${pageName}/${sublistItem.value}`
        );
        // window.location.reload()
      } else {
        const path = `/home/${pageName}/${sublistItem.value}`;
        navigate(path);
        // window.location.reload()
      }
    
    }
  };

  useEffect(() => {
    fetchMoreDetailsData();
    
  }, [selectedvalue,selectedkey])
  
  
// console.log("moredata",moreData);
const [imageLoadedArray, setImageLoadedArray] = useState(new Array(moreData.data.length).fill(false));
const handleImageLoad = (index) => {
  setImageLoadedArray(prevState => {
    const newState = [...prevState];
    newState[index] = true;
    return newState;
  });
};
const formattedString = moreData.title
.split('_') // Split the string by underscores
.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
.join(' '); // Join the words back together with spaces
const handleImageClick = (ourseourl, index) => {
  setCurrentImageClick(index);
  setFromItem(0);
  setPage(0);
  // setStartCount(0);
  // fetchMoreDetailsData(); 
};


// const parameters = {
//   from: fromItem,
//   page_size: 24,
//   page: page,
//   start_count: 0
// };

// console.log("bsbsbbs", moreData)
  return (
    <div className='main-page'>
      {/* <Header /> */}
      {location.pathname === "/test" ? (
        <>
          <div className="main background-layer">
            <Container fluid className="pading-left-right-class">
              <Row>
                {movieGenresList.length
                  ? movieGenresList.map((val, i) => {
                      return renderCategoryBlockWithLayoutType(
                        (val.web_layout_type ?? val.layout_type),
                        val.display_title,
                        "vertical",
                        "high_3_4"
                      );
                    })
                  : null}
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <>
        
          <div className="main background-layer">
            <Container fluid className="pading-left-right-class">
            <div className="showsFilter">

            <Row className="align-items-center">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
                      <h5>{formattedString}</h5>
                    </Col>
            <Col lg="auto" md="auto" sm="auto" xs="auto">
                      {avilFilters.length > 0 && (
                        <select
                          className=""
                          onChange={(event) =>
                            subListClicked(
                              event.target.value,
                              avilFilters[0].key
                            )
                          }
                        >
                        {avilFilters && <option value="">{moreData.filter_title || "Select"} </option>  

                        }
                          {avilFilters.map((sub) => (
                            <option
                              key={sub.title}
                              value={sub.title}
                              
                              selected={
                               
                                sub.title === SublistTitle
                              }                              >
                              {sub.title.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
            </Row>
            </div>




              {/* {!loading && moreData && (
                <div className="showsFilter">
                  <Row className="align-items-center">
                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                      <h5>{formattedString}</h5>
                    </Col>
                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                      {avilFilters.length > 0 && (
                        <select
                          className=""
                          onChange={(event) =>
                            subListClicked(
                              event.target.value,
                              avilFilters[0].key
                            )
                          }
                        >
                        {avilFilters && <option value="">{moreData.filter_title || "Select"} </option>  

                        }
                          {avilFilters.map((sub) => (
                            <option
                              key={sub.title}
                              value={sub.title}
                              
                              selected={
                               
                                sub.title === SublistTitle
                              }                              >
                              {sub.title.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
                  </Row>
                </div>
              )} */}
              <>
                <br></br>
            
              </>


            
            
              {loading ?  <Loader /> :  <Row>
                    
                {moreData &&
                    (moreData?.web_layout_type ?? moreData?.layout_type)  === "genre" &&
                    moreData?.data?.length > 0 && (
                      <Container fluid className="pading-left-right-class">
                        {moreData?.data?.map((item, index) => {
                          const itemlayouttype = item?.web_layout_type ?? item?.layout_type
                          const dynamicUrl = item.seo_url.trim();
                            const ourseourl = dynamicUrl.startsWith("/")
                              ? dynamicUrl
                              : `/${dynamicUrl}`;
                              const ourseourlmore = "/more"+ourseourl;
                               let defultLocalimage = "";
                              if (itemlayouttype === 'etv-exclusive_banner' || itemlayouttype  === 'tv_shows_banner') {
                                defultLocalimage = etv_exclusive_banner_image;
                              } else if (['episode', 'latest_episode', 'recipe', 'mini_clips', 'videos', 'movie_poster','short'].includes(moreData?.layout_type)) {
                                defultLocalimage = verticaldefultimage;
                              }else if (['tv_shows', 'movies', 'top10', 'movie'].includes(moreData?.web_layout_type ?? moreData?.layout_type)) {
                                defultLocalimage = horizontaldefultimage;
                              }else{
                                defultLocalimage = verticaldefultimage;
                              }
                          return (
                            <>
                              {item?.catalog_list_items?.length > 0 && (
                                <Row>
                                  <Col>
                                    <h5>{item?.display_title} </h5>
                                  </Col>

                                  {item?.catalog_list_items?.length > 7 && (
                                    <Col
                                      style={{
                                        textAlign: "end",
                                        padding: "0px",
                                      }}
                                    > 
                                      <span
                                        key={index}
                                        onClick={() =>
                                          handleMore(
                                            ourseourlmore,
                                            item.catalog_list_items[0],
                                            "genre"
                                          )
                                        }
                                        className="more-heading"
                                      >
                                        
                                        {/* {ourseourlmore} */}
                                        More
                                        <span>
                                          <FaAngleDoubleRight />
                                        </span>
                                      </span>
                                    </Col>
                                  )}
                                </Row>
                              )}
                              {item?.catalog_list_items?.length > 0 && (
                                <Slider
                                  {...horizontalmodel}
                                  className="slider-two"
                                >
                                  {item?.catalog_list_items?.map(
                                    (innerlist, innerIndex) => {
                                      const maxCharacters = 24;

                                      let displayTitle = item?.title?.length > maxCharacters 
                                          ? item?.title?.substring(0, maxCharacters) + "..." 
                                          : item?.title;
                                          const dynamicUrlinnerlist = innerlist.seo_url;
                                          const ourseourlinnerlist = dynamicUrlinnerlist.startsWith("/")
                                            ? dynamicUrlinnerlist
                                            : `/${dynamicUrlinnerlist}`;
                                      return(
                                        <>
                                          <Link
                                        to={ourseourlinnerlist}
                                        key={`${index}-${innerIndex}`}
                                      >
                                        <div className="carousel-car">
                                          <div className="extra lock-icon-class cms-container">
                                            <div className="thumbnailMovieCard relateddet">
                                              {/* <img
                                                src={
                                                  innerlist?.thumbnails?.high_16_9
                                                    ?.url
                                                }
                                                width="100%"
                                                alt={`Slider ${innerIndex}`}
                                                className="img-border-radis"
                                              /> */}
 { !imageLoadedArray[index]? (
                           <img
                           src={!imageLoadedArray[index] ? defultLocalimage: (innerlist?.thumbnails?.high_16_9
                            ?.url)}
                           width="100%"
                           onLoad={() => handleImageLoad(index)}
                           loading="lazy"
                           onClick={() => itemSelected(innerIndex,innerlist)}
                           />
                         ):(
                          <img
                          src={imageLoadedArray[index] ? (innerlist?.thumbnails?.high_16_9
                            ?.url) : defultLocalimage}
                          width="100%"
                          onLoad={() => handleImageLoad(index)}
                          loading="lazy"
                          alt={`${index}`}
                           onClick={() => itemSelected(innerIndex,innerlist)}
                          />
                         ) }
                                            </div>

                                            {!innerlist?.access_control
                                              ?.is_free ? (
                                              <div className="lock-postion-class"></div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </Link>
                                        </>
                                      )
                                    }
                                  )}
                                </Slider>
                              )}
                            </>
                          );
                        })}
                      </Container>
                    )}
              
              {moreData  &&  (moreData?.web_layout_type ?? moreData?.layout_type) !== "genre" && 
          moreData.data.length > 0 &&
          moreData.data.map((item, index) => {
            let formattedDate = '';
            if (item.release_date_string) {
              let releaseDate = new Date(item.release_date_string);
              const day = releaseDate.getDate().toString().padStart(2, "0");
              const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
              const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
              formattedDate = `${day} ${monthAbbreviation} ${year}`;
            }
        
            let displayTitle = item.title;
            if (item.release_date_string) {
              displayTitle = item.title.length > 20 ? `${item.title.substring(0, 26)}... ` : `${item.title} (${formattedDate})`;
            } else {
              displayTitle = `${item.title.substring(0, 26)}... `;  // Full title when release_date_string is empty
            }
        
            let seourlbind = `/${item.seo_url}`;
            const dynamicUrl = item.seo_url;
            const ourseourl = dynamicUrl.startsWith("/")
              ? dynamicUrl
              : `/${dynamicUrl}`;
    // console.log('moreDatasss', moreData)
    // console.log("moreData.layout_type",moreData.layout_type);
              const layoutType = moreData.web_layout_type || moreData.layout_type;
              
              let thumbnailSize = null;let defultLocalimage = "";
              let layoutclassName
              if (['video_songs','episode', 'latest_episode', 'recipe', 'mini_clips', 'videos', 'movie_poster','short','promo'].includes(layoutType)) {
                thumbnailSize = item?.thumbnails?.medium_16_9?.url;
                // console.log("nsns", thumbnailSize)
                layoutclassName = 'see-all-eight-items'
                defultLocalimage = verticaldefultimage;
              }else if (['tv_shows', 'movies', 'top10', 'movie','etv-exclusive_banner', 'tv_shows_banner', 'shows'].includes(layoutType)) {
                thumbnailSize = item?.thumbnails?.medium_3_4?.url;
                layoutclassName = 'see-all-eight-items'
                defultLocalimage = horizontaldefultimage;
              }else{
                thumbnailSize = item?.thumbnails?.medium_3_4?.url || item?.thumbnails?.medium_16_9?.url ;
                layoutclassName = 'see-all-eight-items'
                defultLocalimage = verticaldefultimage;
                
              }
             
              
    
    
           
    
    
            return (
              <React.Fragment key={index}>
                <Col lg={2} md={3} sm={layoutType === 'tv_shows_banner' || layoutType === "etv-exclusive_banner" || layoutType === "tv_shows" || layoutType === "shows" ? 4 : 6} xs={layoutType === 'tv_shows_banner' || layoutType === "etv-exclusive_banner" || layoutType === "tv_shows" || layoutType === "shows" ? 4 : 6} 
                className={!isMobile ? ((layoutType === 'tv_shows_banner' || layoutType === "etv-exclusive_banner" || layoutType === "tv_shows" || layoutType === "shows") ? 'see-all-eight-items' : 'see-all-six-items') : ''}
                style={{
  paddingRight:
    isMobile && 
    (layoutType === 'tv_shows_banner' ||
     layoutType === 'etv-exclusive_banner' ||
     layoutType === 'tv_shows' ||
     layoutType === 'shows') 
      ? "1px" 
      : ""
}}              
                >
    
                  <Link
                    to={ourseourl}
                  >
                       { !loading && ( <div className="carousel-car continuewatch">
                        
                      <div className="lock-icon-class cms-container cw-card">
                          <>
                        <div className="thumbnailMovieCard">
                            
                       
                              
                            
    
                             { !imageLoadedArray[index]? (
                               <img
                               src={!imageLoadedArray[index] ? defultLocalimage: (thumbnailSize || item?.list_item_object?.banner_image)}
                               width="100%"
                               onLoad={() => handleImageLoad(index)}
                               alt={`${index}`}
                               onClick={() => itemSelected(index, item, moreData)}                               //  onClick={()=>handleImageClick(ourseourl)} 
                               />
                             ):(
                              <img
                              src={imageLoadedArray[index] ? (thumbnailSize || item?.list_item_object?.banner_image) : defultLocalimage}
                              width="100%"
                              onLoad={() => handleImageLoad(index)}
                              loading="lazy"
                              // onClick={()=>handleImageClick(ourseourl)} 
                              onClick={() => itemSelected(index, item, moreData)}                               alt={`${index}`}
                              />
                             ) }

{imageLoadedArray[index] && item.access_control &&
                          item.access_control.is_free ===
                                        false && (
                                        <div className="lock-postion-class"></div>
                                      )}

                            {item.media_type &&
                            acceptedMediaTypes.includes(
                            item.media_type
                            ) && (
                            <div className="iconcame">
                            <FaPlay /> 
                            </div>
                            )}
                            </div>
                      
                            { imageLoadedArray[index] && (item.show_meta_data === 'yes' && item?.media_type) && <p className="displayTitle"> {displayTitle}  </p>}
                            { imageLoadedArray[index] && (item?.sub_genres?.includes("veg") || item?.sub_genres?.includes("non_veg")) && <div className="FoodType">
                              <img
                                src={
                                  item?.sub_genres?.includes("veg")
                                    ? vegicon
                                    : nonvegicon
                                }
                                className=""
                                loading="lazy"
                                alt=""
                              />
                            </div>}
                          </>
                   
                     
                    
    
                      </div>
                    </div>
                         )}
                  </Link>
                </Col>
                {moredataLoading && 
         <div
        className="text-center mt-4"
        style={{
          position: "fixed",
          top: "60%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1050, 
        }}
      >
        <Spinner animation="border" variant="danger" />
      </div>
    }
                {/* Render loader after every 24 records if there are more records */}
               
              </React.Fragment>
            );
          })}
    
    
                    </Row>}

               
            
                   

            </Container>

           
          
           
          </div>
        </>
      )}

      
{/* {isMobile && <FooterCondition />} */}
      {!loading &&  <><Footer /> </>}
    </div>
  );
};

export default MoreDetails;
