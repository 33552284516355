import React from "react";
import { useParams, Link} from "react-router-dom";

import { Col, Container, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { access_token, auth_token } from "../../services/config";
import Header from "../../Componets/Header/Header";

export default function VerifyFailed({
  isVerified,
  setIsVerified,
  confirmationfailed,
}) {

  const EmailValidicon = "/images/emailvalid.png";
  const logo = "/images/win-logo.png";

  // console.log("confirmationfailed", confirmationfailed);
  const handleVerified = () => {
    setIsVerified(false);
  };

  return (
    <>
      {isVerified ? (
        <>
          <Modal
            show={isVerified}
            onHide={handleVerified}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            className="border-class"
          >
            <Modal.Body>
              <div className="card-deck row just-content">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                  <div className="">
                    <img src={EmailValidicon} alt="email validation icon" />
                    <h6 className="text-center fw-bold fs-3 my-3">
                      Validation Failed
                    </h6>
                    <div className="text-center">
                      <p className="emailvalid-text">
                        {confirmationfailed
                          ? `this ${confirmationfailed} is verification failed`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
}
