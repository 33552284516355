import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Slice/authSlice'
import videoReducer from './Slice/videodetailSlice'
import likedislikeReducer from './Slice/likeanddislikeSlice'
import showprofileReducer from './Slice/showprofileSlice'
import showStreamlimitReducer from './Slice/streamlimitSlice'
import showSlice from './Slice/showSlice'
import loginModalSlice from './Slice/loginModalSlice'
import regionSlice  from './Slice/regionslice'
import episodeSlice from './Slice/episodeSlice'
import activePlanSlice from './Slice/activePlanSlice'
import  emailRegisterSlice from './Slice/emailregisterSlice'
import userConfigSlice from './Slice/userConfigSlice'
import filterEpisodeSlice from './Slice/filterEpisodeSlice'
import  RegisterSlice  from './Slice/signupDetailsSlice'
import loginSlice  from './Slice/signinDetailSlice'
import logoutAllModalSlice from './Slice/logoutAllModalSlice'
import logoutModalSlice from './Slice/logoutModalSlice'
import registermodalSlice from './Slice/registermodalSlice'
import mobileregisterModalSlice from './Slice/mobileregisterModalSlice'
import accountsDataSlice  from './Slice/accountsDataSlice'
import getalldetailsSlice from './Slice/getalldetailsSlice'
import subscribeButtonSlice from './Slice/subscribeStatusSlice'
import innerPageDataFetch  from './Slice/fetchedDataSlice'
import isDataRendered  from './Slice/dataRender'
import filterAppliedSlice from './Slice/filterappliedSlice'
import menulistSlice from './Slice/menulistSlice'
import innerPageDataSlice from './Slice/innerPageDataSlice'
import filterDateSlice from './Slice/filterdateSlice'
import seasonsSlice from './Slice/seasonsSlice'
import navMenuCalledSlice from './Slice/navlinkcallSlice'
import updateMobilemodalSlice from './Slice/updateMobileSlice'
import profilepicremoveSlice from './Slice/profileremoveSlice'
import profilepicloadSlice from './Slice/profilespinnerslice'
import subscribesourceSlice from './Slice/subscriptionsource'
import passwordSuccessmodalSlice from './Slice/passwordsuccessslice'
import redeemSlice from './Slice/redeemSlice'
import accessTokenSlice from './Slice/accesstokenSlice'
import bufferingSlice  from './Slice/bufferSlice'


export const Store = configureStore({
  reducer: {
    auth : authReducer,
    profile_pic_remove: profilepicremoveSlice,
    profilepic_load: profilepicloadSlice,
    video_details : videoReducer,
    filter_episode_details : filterEpisodeSlice,
    like_dislike_details: likedislikeReducer,
    showprofile_details: showprofileReducer,
    streamlimit_details: showStreamlimitReducer ,
    shows : showSlice,
    loginModal: loginModalSlice,
    logoutallModal: logoutAllModalSlice,
    fetchinnerpageData: innerPageDataFetch,
    datarendered: isDataRendered,
    logoutModal: logoutModalSlice,
    registerModal: registermodalSlice,
    subscribebuttonstatus: subscribeButtonSlice,
    mobileregisterModal: mobileregisterModalSlice,
    region: regionSlice,
    episodes: episodeSlice,
    active_plan: activePlanSlice,
    menu_list: menulistSlice,
    email_data: emailRegisterSlice,
    signup_data: RegisterSlice,
    signin_data: loginSlice,
    user_config: userConfigSlice,
    accesstoken_data: accessTokenSlice,
    account_data: accountsDataSlice,
    getalldata: getalldetailsSlice,
    filter_applied: filterAppliedSlice,
    buffer_loading: bufferingSlice,
    inner_page_data: innerPageDataSlice,
    filter_date: filterDateSlice,
    dropdown_data: seasonsSlice,
    navmenu_called: navMenuCalledSlice,
    update_mobile_modal: updateMobilemodalSlice,
    subscribesourcetitle: subscribesourceSlice,
    password_success: passwordSuccessmodalSlice,
    redeem_coupon: redeemSlice,
  },
})