import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPasswordSucccessModalOpen: false,
  passwordSuccessMessage: {}
};

export const passwordSuccessmodalSlice = createSlice({
  name: "passwordmodal",
  initialState,
  reducers: {
    setIsPasswordSucccessModalOpen: (state) => {
      state.isPasswordSucccessModalOpen = true;
    }, 
    setIsPasswordSucccessModalClosed: (state) => {
        state.isPasswordSucccessModalOpen = false;
      },
    setPasswordSuccessMessage: (state, action) => {
       state.passwordSuccessMessage = action.payload.passwordSuccessMessage
     },
  },
});

export const { setIsPasswordSucccessModalOpen, setIsPasswordSucccessModalClosed, setPasswordSuccessMessage } = passwordSuccessmodalSlice.actions;

export default passwordSuccessmodalSlice.reducer;
