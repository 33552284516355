import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Paymentstatus = () => {
  const successicon = "/images/success.png";
  const failedicon = "/images/payment_fail.png";

  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [message1, setMessage1] = useState('');
  const [message2, setMessage2] = useState('');
  const [orderId, setOrderId] = useState('');
  const [subscriptionLocation, setSubscriptionLocation] = useState('');
  const [sourceTitle, setSourceTitle]=useState("");
  const regionsData = useSelector((state) => state.region.regionsData);
  const loginStatus = localStorage.getItem("login_status")
  const userrid = localStorage.getItem("user_id");
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentGateway = urlParams.get('payment_gateway');
    const paymentStatusParam = urlParams.get('payment_status');
    const msg1 = urlParams.get('message1');
    const msg2 = urlParams.get('message2');
    const order = urlParams.get('order_id');

    setPaymentStatus(paymentStatusParam);
    setMessage1(msg1);
    setMessage2(msg2);
    setOrderId(order);

    const storedSubscriptionLocation = localStorage.getItem('subscriptionLocation');
    setSubscriptionLocation(storedSubscriptionLocation);
    if (paymentGateway === 'billdesk') {
      const param = urlParams.get('msg');
      const res = param.split('|');

      if (res[14] === '0300') {
        handleSuccess(res);
      } else {
        handleFailure(res);
      }
    } else {
      handleMPGSPaymentStatus(paymentStatusParam);
    }
  }, [location.search]);
  const {subscribeSource} = useSelector(state => state.subscribesourcetitle)

  useEffect(() => {
    if(subscribeSource === true){
      const sourcetitles = localStorage.getItem("relatedtitle")
      setSourceTitle(sourcetitles)
  
    }
      }, [subscribeSource])
  const handleSuccess = (res) => {
    // Handle success logic
    // ...

    // console.log('Subscription success:', res);


  //   window.sdk.trackEvent('subscription_success', {
  //     order_id: response.data.data.transaction_id,
  //     source: "before_payment",
  //     payment_mode:paymentgateway.toLowerCase(),
  //     coupon_code: iscouponcode,
  //     coupon_code_type: coupon_code_type,
  //     plan_name: selectedPackageData?.plan_id,
  //     plan_type: selectedPlanData?.period,
  //     pack_value_currency: selectedPlanData.currency,
  //     coupon_code_name: couponCode,
  //     price_charged: totalPayableAmt.toString(),
  //     platform: isMobile ? 'Mobile' : 'Desktop',
  //     transanction_id: "",
  //     t_action: JSON.stringify(response.data.data),
  //     event_id: 14,
  //     // event_time: new Date().toISOString().slice(0, 19),
  // });

  };

  const handleFailure = (res) => {
    // Handle failure logic
    // ...

    // console.log('Subscription failure:', res);
  };

  const handleMPGSPaymentStatus = (status) => {
    // Handle MPGSPaymentStatus logic
    // ...

    // console.log('MPGS Payment Status:', status);
  };
  if(paymentStatus === "success"){
    const subscription_events = localStorage.getItem("subscription_events");

     if(subscription_events){
           const convertStgtoObject = JSON.parse(subscription_events);

           if (window && window.sdk && window.sdk.trackEvent) {
            window.sdk.trackEvent('subscription_success', {
              order_id: convertStgtoObject.order_id,
              country: regionsData?.country_name,
              // u_id:
              // loginStatus === "true"
              // ? userrid
              // : guestuserid,
              source: subscribeSource === true ? sourceTitle : "subscription",
              payment_mode:convertStgtoObject.payment_mode,
              coupon_code: convertStgtoObject.coupon_code,
              coupon_code_type: convertStgtoObject.coupon_code_type,
              plan_name: convertStgtoObject.plan_name,
              plan_type: convertStgtoObject.plan_type,
              pack_value_currency: convertStgtoObject.pack_value_currency,
              coupon_code_name: convertStgtoObject.couponCode,
              price_charged: convertStgtoObject.price_charged,
              platform: convertStgtoObject.platform,
              transanction_id:orderId,
              t_action: JSON.stringify(location),
              event_id: 14,
              });
        
          } else {
            console.error('Analytics SDK is not initialized yet.');
            // Handle initialization state or queue events for later
          }

       
     }
    
    // Check the type and structure of the data
    
  }else{
    const subscription_events = localStorage.getItem("subscription_events");

    if(subscription_events){
      const convertStgtoObject = JSON.parse(subscription_events);

      if (window && window.sdk && window.sdk.trackEvent) {
        window.sdk.trackEvent('subscription_failure', {
          order_id: convertStgtoObject.order_id,
          source: subscribeSource === true ? sourceTitle : "subscription",
          payment_mode: convertStgtoObject.payment_mode,
          coupon_code: convertStgtoObject.coupon_code,
          coupon_code_type:  convertStgtoObject.coupon_code_type,
          plan_name: convertStgtoObject.plan_name,
          plan_type:convertStgtoObject.plan_type,
          pack_value_currency: convertStgtoObject.pack_value_currency,
          coupon_code_name: convertStgtoObject.coupon_code_name,
          price_charged: convertStgtoObject.price_charged,
          subscription_type: convertStgtoObject.subscription_type,
          platform: convertStgtoObject.platform,
          faliure_reason: message1?paymentStatus:paymentStatus,
          t_action: JSON.stringify(location),
          event_id: 15,
          // event_time: new Date().toISOString().slice(0, 19),
          });
    
      } else {
        console.error('Analytics SDK is not initialized yet.');
        // Handle initialization state or queue events for later
      }

       
      
    }
  }

  return (
    <div className='alitems'>
    <div className='ali-items about-main'>
    <div className="feedback-content feedback-content-center text-center align-content-center">
    <img 
  src={paymentStatus === "success" ? successicon : failedicon} 
  style={paymentStatus === "success" ? {} : { width: "100px", height: "100px" }}
  alt={paymentStatus === "success" ? "payment success" : "payment failed"} 
/>    <h3 className='text-green' >Payment Status : {paymentStatus} </h3>
    <p >{message1}</p>
    <p >{message2}</p>
    <p>Your Order ID: <span className="pay-order-id" style={{color:"#fff8f88c"}}>{orderId}</span></p>
    <p className="payment-note" >Note: <span style={{color:"#fff8f88c"}}>Kindly note down this Order ID for future reference</span></p>
    <div className="button-centred" >
        <a className="btn btn-default form-button full-width-button proceed-button" href="/home" style={{background:"#d41117"}}>PROCEED </a>
    </div>
    </div>
   </div>
   </div>
  )
}

export default Paymentstatus