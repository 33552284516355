import React, { useState } from "react";
import { FaCopy, FaFacebookF, FaShare } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { RiTwitterXFill } from "react-icons/ri";
import { Link } from "react-router-dom";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { FacebookShareButton, TwitterShareButton, XIcon } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FaTwitter, FaX } from "react-icons/fa6";
import { guestuserid } from "../../App";
export default function ShareFeature() {
  const { videodetailsitems } = useSelector((state) => state.video_details);

  const [showshareoptions, setShowshareoptions] = useState(false);

  const handleShareOptions = () => {
    setShowshareoptions(!showshareoptions);
  };

  const handleMouseEnter = () => {
    setShowshareoptions(true);
  };

  const handleMouseLeave = () => {
    setShowshareoptions(false);
  };

  const handleShareOptionsToggle = (state) => {
    setShowshareoptions(state);
  };

  const regionsData = useSelector((state) => state.region.regionsData);

  const loginStatus = localStorage.getItem("login_status");
  const getuserid = localStorage.getItem("user_id");
  // console.log("videodetailsitems",videodetailsitems);
  const Main_URL = window.location.origin;
  const [copied, setCopied] = useState(false);
  // const firebaseAnalytics = getAnalytics();

  const copyToClipboard = () => {
    const dynamicUrl = videodetailsitems?.seo_url;
    const ourseourl = dynamicUrl.startsWith("/")
      ? dynamicUrl
      : `/${dynamicUrl}`;

    const videoURL = `${Main_URL}${ourseourl}`;
    // console.log("videoURL", videoURL);
    const textToCopy = `${videoURL}`;
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);

    window.sdk.trackEvent("share", {
      content_type: videodetailsitems?.media_type,
      content_value: videodetailsitems?.content_value,
      genre: videodetailsitems.genres.join(", "),
      event_time: new Date().toISOString().slice(0, 19),
      video_name: videodetailsitems?.title,
      video_language: videodetailsitems?.language,
      platform: "web",
      country: regionsData?.country_name,
      u_id: loginStatus === "true" ? getuserid : guestuserid,
      event_id: 11,
    });
    //   firebaseAnalytics.logEvent('share', {
    //     content_type:videodetailsitems?.media_type ? videodetailsitems?.media_type : 'NA',
    //     video_name: videodetailsitems?.title,
    //     genre: videodetailsitems?.genres ? videodetailsitems?.genres : 'NA',
    //     video_language: videodetailsitems?.language,
    //     content_value: videodetailsitems?.content_value
    // });

    // logEvent(firebaseAnalytics, "share", {
    //   content_type: videodetailsitems?.media_type
    //     ? videodetailsitems?.media_type
    //     : "NA",
    //   video_name: videodetailsitems?.title,
    //   genre: videodetailsitems?.genres ? videodetailsitems?.genres : "NA",
    //   video_language: videodetailsitems?.language,
    //   content_value: videodetailsitems?.content_value,
    // });
  };

  const handleTwitterShare = () => {
    window.sdk.trackEvent("share", {
      content_type: videodetailsitems?.media_type,
      content_value: videodetailsitems?.content_value,
      genre: videodetailsitems.genres.join(", "),
      video_name: videodetailsitems?.title,
      platform: "web",
      u_id: loginStatus === true ? getuserid : guestuserid,
      video_language: videodetailsitems?.language,
      event_id: 11,
      event_time: new Date().toISOString().slice(0, 19),
      country: regionsData?.country_name,
    });
    const tweetUrl = encodeURIComponent(videodetailsitems?.web_share_url);
    const text = encodeURIComponent("Check out this video");
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${tweetUrl}&text=${text}`;

    window.open(twitterShareUrl, "_blank");
  };

  const handleFacebookShare = () => {
    window.sdk.trackEvent("share", {
      content_type: videodetailsitems?.media_type,
      content_value: videodetailsitems?.content_value,
      genre: videodetailsitems.genres.join(", "),
      video_name: videodetailsitems?.title,
      video_language: videodetailsitems?.language,
      event_time: new Date().toISOString().slice(0, 19),
      country: regionsData?.country_name,
      platform: "web",
      u_id: loginStatus === "true" ? getuserid : guestuserid,
      event_id: 11,
    });
    // console.log("videodetailsitems",videodetailsitems);

    const shareUrl = encodeURIComponent(videodetailsitems?.web_share_url);
    const appId = "457037700151513";
    const link = encodeURIComponent(
      videodetailsitems?.web_share_url || Main_URL
    );
    const imageUrl = encodeURIComponent(
      videodetailsitems?.thumbnails?.medium_4_3?.url ||
        "http://url-of-image.com/image.jpg"
    );

    const redirectUri = encodeURIComponent(
      "https://developers.facebook.com/tools/explorer"
    );
    const facebookShareUrl = `https://www.facebook.com/dialog/feed?app_id=${appId}&display=popup&link=${link}&redirect_uri=${redirectUri}&images=${imageUrl}`;
    // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?s=100&p[title]=${title}&p[summary]=${summary}&p[url]=${url}&p[images][0]=${imageUrl}`;

    window.open(facebookShareUrl, "_blank");
  };

  const handleMailShare = () => {
    window.sdk.trackEvent("share", {
      content_type: videodetailsitems?.media_type,
      content_value: videodetailsitems?.content_value,
      genre: videodetailsitems.genres.join(", "),
      video_name: videodetailsitems?.title,
      video_language: videodetailsitems?.language,
      event_time: new Date().toISOString().slice(0, 19),
      country: regionsData?.country_name,
      platform: "web",
      u_id: loginStatus === "true" ? getuserid : guestuserid,
      event_id: 11,
    });
    const subject = encodeURIComponent("Check out this video");
    const body = encodeURIComponent(
      `Check out this video: ${videodetailsitems?.title}${videodetailsitems?.dynamic_url}`
    );

    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  };
  //alert(videodetailsitems?.dynamic_url);
  const shareUrl =
    videodetailsitems?.web_share_url || videodetailsitems?.share_url;
  const title = videodetailsitems?.title;
  const description =
    videodetailsitems?.short_description || "Check out this video on ETVWin";
  const imageUrl =
    videodetailsitems?.thumbnails?.medium_4_3?.url || "default-image-url";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>{title || 'ETVWin'}</title> */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={shareUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@etvwin" />
        <meta name="twitter:creator" content="@etvwin" />
        <meta
          name="twitter:title"
          content={videodetailsitems?.title || "ETVWin"}
        />
        <meta
          name="twitter:description"
          content={
            videodetailsitems?.description || "Check out this video on ETVWin"
          }
        />
        <meta
          name="twitter:image"
          content={
            videodetailsitems?.thumbnails?.medium_4_3?.url ||
            "default-image-url"
          }
        />
        <meta name="twitter:url" content={videodetailsitems?.web_share_url} />
      </Helmet>
      <div  onMouseEnter={() => handleShareOptionsToggle(true)}
            onMouseLeave={() => handleShareOptionsToggle(false)} className="dropdown">
        <div
          className="combination"
          // onMouseEnter={() => handleShareOptionsToggle(true)}
          // onMouseLeave={() => handleShareOptionsToggle(false)}
        >
          <div className="other-icons">
            <FaShare style={{ cursor: "pointer" }} onClick={() => setShowshareoptions(!showshareoptions)} />
          </div>
          <div className="text">Share</div>
        </div>

        {showshareoptions && (
          <div
            className="dropdown-content bg-color-drop"
            // onMouseEnter={() => handleShareOptionsToggle(true)}
            // onMouseLeave={() => handleShareOptionsToggle(false)}
          >
            <div className="copiedtext">

            {copied && (
              <div style={{ color: "#fff", textAlign: "center" }} className="copied">
                Copied website url
              </div>
            )}
            </div>
            <p style={{ textAlign: "center" }}>Share This Video</p>
            <ul className="share-icos">
              <li className="facebook">
              {shareUrl &&
                <FacebookShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                  onClick={handleFacebookShare}
                >
                  <FaFacebookF />
                </FacebookShareButton>
              }
              </li>
              <li className="copypast">
                <p style={{ cursor: "pointer" }} onClick={copyToClipboard}>
                  <FaCopy />
                </p>
              </li>
              <li className="email">
                <p style={{ cursor: "pointer" }} onClick={handleMailShare}>
                  <IoMail />
                </p>
              </li>
              <li className="twitter">
              {shareUrl && (
                  <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="Demo__some-network__share-button"
                    onClick={handleTwitterShare}
                  >
                    <RiTwitterXFill />
                  </TwitterShareButton>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
