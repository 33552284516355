import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Componets/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Componets/Footer/Footer";
import { useParams } from "react-router";
import { BASE_URL, acceptedMediaTypes, access_token, auth_token } from "../../services/config";
// import useRegionData from "../../Componets/regions/regions";
import axios from "axios";
import Slider from "react-slick";
import { verticalmodel } from "../../layout/gridmodel";
import { useNavigate, Link } from "react-router-dom";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import { getMovies } from "../../Api/movie"
import { useSelector } from "react-redux";

const MoreDetails = () => {
  // const { region, regiondetails } = useRegionData();
  const regionsData = useSelector(state => state.region.regionsData)

  const [moreData, setMoreData] = useState({});
  const [catalogListData, setCatalogListData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const {genere} = useParams();
  const effectRan = useRef(false)


  useEffect(() => {
    if(effectRan.current === false){
      getMoviesData();
      return () => {
        effectRan.current = true
      }
    }
  }, []);


  let getMoviesData = async () => {
    // let url = `${BASE_URL}catalog_lists/movies-genre-classic.gzip?item_language=eng&region=IN&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&from=0&page=0&page_size=24&start_count=0`;
    // const response = await axios.get(url);
    // setMoreData(response.data.data)
    // setCatalogListData(response.data.data.catalog_list_items)
    // console.log("respons 122-44--0>", response.data.data.catalog_list_items)

    let payload = {
      genere: genere,
      region: regionsData?.country_code2,
      auth: auth_token,
      accessToken: access_token
    }
    const response = await getMovies(payload);
    if(response.data){
      setMoreData(response.data.data)
      setCatalogListData(response.data.data.catalog_list_items)
    }
  
  }



  // console.log("avilFilters", moreData);


  return (
    <>

        <div className="main background-layer">

          <Container fluid className="pading-left-right-class">


            <Row>
              {moreData &&
                moreData.catalog_list_items &&
                moreData.catalog_list_items.length > 0 &&
                catalogListData.map((item, index) => {
                  return (
                    <>
                      <Col lg={2} md={3} sm={4} xs={6} key={index}>
                        <Link to={`${item.seo_url}`}>
                          <div className="carousel-car">
                            <div className="lock-icon-class cms-container">
                              <img
                                src={
                                  item?.thumbnails?.high_16_9?.url
                                }
                                width="100%"
                                alt={`${index}`}
                              />
                              {item?.media_type && acceptedMediaTypes.includes(item.media_type) && (
  <div className="iconcame ">
    <FaPlay />
  </div>
)}

                              {!item?.access_control?.is_free ? <div className="lock-postion-class"></div> : ""}

                            </div>
                          </div>
                        </Link>
                      </Col>
                    </>
                  );
                })}
            </Row>
          </Container>



          <Footer />
        </div>
      </>

  );
};

export default MoreDetails;
