import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessTokenData: {
    access_token: "Ay6KCkajdBzztJ4bptpW"
  },
  accessToken: "Ay6KCkajdBzztJ4bptpW"
};

export const accessTokenSlice = createSlice({
  name: "accesstokendetails",
  initialState,
  reducers: {
    setAccessTokenData: (state, action) => {
      state.accessTokenData = action.payload.accessTokenData;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const { setAccessTokenData, accessTokenData, setAccessToken, accessToken } = accessTokenSlice.actions;

export default accessTokenSlice.reducer;
