import React, { useEffect, useState } from 'react';

const GPTAdThree = () => {
  const adId = 'div-gpt-ad-320x50'; // Unique ID for this ad slot
  const [adSlotEmpty, setAdSlotEmpty] = useState(true);

  useEffect(() => {
    const loadGPT = () => {
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(() => {
          try {
            const existingSlot = window.googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === adId);
            if (existingSlot) {
              console.log('Destroying existing slot for', adId);
              window.googletag.destroySlots([existingSlot]);
            }

            const adSlot = window.googletag
              .defineSlot('/23111733658/ca-pub-6245873721150554-tag/Test1D', [320, 50], adId)
              .addService(window.googletag.pubads());

            console.log('Ad slot defined:', adSlot);

            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
            window.googletag.display(adId);

            window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
              if (event.slot.getSlotElementId() === adId) {
                if (event.isEmpty) {
                  console.log('Ad slot is empty:', adId);
                } else {
                  console.log('Ad loaded successfully for:', adId);
                }
                setAdSlotEmpty(event.isEmpty); // Set adSlotEmpty based on whether ad slot is empty
              }
            });

            const refreshInterval = setInterval(() => {
              console.log('Refreshing ad slot:', adId);
              window.googletag.pubads().refresh([adSlot]);
            }, 20000);

            return () => clearInterval(refreshInterval);

          } catch (e) {
            console.error('Error setting up GPT:', e);
            setAdSlotEmpty(true);
          }
        });
      } else {
        console.error('GPT is not loaded or googletag.cmd is not available.');
      }
    };

    let script;
    if (window.googletag && window.googletag.apiReady) {
      loadGPT();
    } else {
      script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async = true;
      script.onload = loadGPT;
      document.head.appendChild(script);
    }

    return () => {
      if (script && document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return (
    <div 
      className="ad-container text-center d-lg-none d-md-none"
      style={{ display: adSlotEmpty ? 'none' : 'block' }}
    >
      <center>
        <div
          id={adId}
          className="gpt-ad"
          style={{
            width: '320px',
            height: '50px'
          }}
        ></div>
      </center>
    </div>
  );
};

export default GPTAdThree;
