import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const NoInternetScreen = () => {
  const NoInternetImage = "/images/nointernet.jpg";

  return (
    <>
        <div className='noInternetPage'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={5} mb={7} xs={12}>
                        <div className='noNetCard'>
                            <div className='gifIcon'>
                                <img src={NoInternetImage} alt="" />
                            </div>
                            <h3 className=''><span>Oops!</span> No Internet</h3>
                            <p>Please check your network connection.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    </>
  )
}