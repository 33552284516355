import React, { useEffect, useRef } from 'react';

const GPTAdFour = () => {
    const adId = 'div-gpt-ad-300x250-2';
    const adUnitPath = '/23111733658/ca-pub-6245873721150554-tag/Test2D'; // Unique path for the second ad
  
    useEffect(() => {
      const loadGPT = () => {
        if (window.googletag && window.googletag.cmd) {
          window.googletag.cmd.push(() => {
            try {
              const existingSlot = window.googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === adId);
              if (existingSlot) {
                window.googletag.destroySlots([existingSlot]);
              }
  
              const adSlot = window.googletag
                .defineSlot(adUnitPath, [300, 250], adId)
                .addService(window.googletag.pubads());
  
              window.googletag.pubads().enableSingleRequest();
              window.googletag.enableServices();
              window.googletag.display(adId);
  
            } catch (e) {
              console.error('Error setting up GPT for GPTAdFour:', e);
            }
          });
        } else {
          console.error('GPT is not loaded or googletag.cmd is not available.');
        }
      };
  
      if (window.googletag && window.googletag.apiReady) {
        loadGPT();
      } else {
        const script = document.createElement('script');
        script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
        script.async = true;
        script.onload = loadGPT;
        document.head.appendChild(script);
      }
      
    }, []);
  
    return <div id={adId} style={{ width: '300px', height: '250px', backgroundColor: '#f1f1f1', margin: '10px auto' }} />;
  };
  

export default GPTAdFour;
