import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  redeemData: {},
}

export const redeemSlice = createSlice({
  name: 'redeem coupon',
  initialState,
  reducers: {
    setRedeemData: (state, action) => {
      state.redeemData = action.payload.redeemData
    },
  },
})

export const { setRedeemData } = redeemSlice.actions

export default redeemSlice.reducer