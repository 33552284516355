import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import InputField from "../../Componets/inputfield";
import axios from "axios";
// import { getAnalytics,logEvent  } from 'firebase/analytics';


import {
  SIGNUP_OTP_URL,
  auth_token,
} from "../../services/config";
import OtpVerification from "../otpverify/signupmobileverify";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setRegisterDetails } from "../../Redux/Slice/signupDetailsSlice";
import { setShowMobileRegisterModal } from "../../Redux/Slice/mobileregisterModalSlice";
import { setModalClose } from "../../Redux/Slice/loginModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";

const RegisterByMobile = ({
  handleLogin,
  showOTPPopup,
  setShowOTPPopup,
  handleOtpPopupClose,
}) => {
  const logo = "/images/win-logo.png";
  const LoginPoster = "/images/loginposter.jpg";
  const OtherRegionLoginPoster = "/images/WEBLOGINOther.jpg";
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const regionsData = useSelector(state => state.region.regionsData)
  const [formData, setFormData] = useState({
    firstname: "",
    user_id: "",
    agreeTerms: false,
  });

  const dispatch = useDispatch()
  const [formValid, setFormValid] = useState(false);
  const [formDataErrors, setFormDataErrors] = useState({
    firstname: "",
    user_id: "",
    agreeTerms: false,
  });
  const [loading, setLoading] = useState(false);

  // const firebaseAnalytics = getAnalytics();
 
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;
  
    if (!formData.firstname) {
      errors.firstname = "Please enter firstname";
      formIsValid = false;
    }
  
    if (!formData.user_id) {
      errors.user_id = "Please enter mobile number";
      formIsValid = false;
    }
  
    setFormDataErrors(errors);
  
    return formIsValid;
  };

  const handleEmailRegister = () => {
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
    dispatch(setModalClose())
    dispatch(setShowRegisterModal({ showRegisterModal: true }))
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));


    setFormValid(validateForm());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formIsValid = validateForm();

    if (!formIsValid) {
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        SIGNUP_OTP_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            user_id: `0091${formData.user_id}`,
            mobile_number: `0091${formData.user_id}`,
            firstname: formData.firstname,
            region: regionsData?.country_code2,
            type: "msisdn",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success?.status === 200) {
        // console.log("jsjksks", response.data)
        dispatch(setModalClose())
        dispatch(setRegisterDetails({registerDetails: response?.data?.success}))
        setFormValid(true);
        toast.success(response?.data?.success?.message);
      
        setShowOTPPopup(true);
      } else {
        setShowOTPPopup(false);
      }

      setFormData({
        firstname: "",
        user_id: "",
        agreeTerms: false,
      });
    } catch (error) {
      if (error.response) {
        if (error?.response?.status === 422) {
          toast.error(error.response.data.error.message);
 
        }
        setShowOTPPopup(false);
        setFormValid(false);
        console.error(
          "Server responded with an error:",
          error.response
          // error.response.data.error.message
        );
      }

      if (error?.response?.status === 422) {
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("signup_failure", {
            error_type: error?.response?.data?.error?.message,
            method: "phone number",
            event_id: 4,
            platform:"web",
            device_id: "NA",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

       
        // logEvent(firebaseAnalytics, 'signup_failure', {
        //   error_type: error?.response?.data?.error?.message,
        //   method: "phone number",
        //   device_id: 'NA'
        // });
     

        if (
          error?.response?.data?.error?.message === "User already registered."
        ) {
          setShowOTPPopup(false);
          setFormValid(false);
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }
        if (error?.response?.data?.error?.message === "Invalid number") {
          setShowOTPPopup(false);
          setFormValid(false);
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }
      }
    }finally {
      setLoading(false); 
    }
  };

  const handleMobileRegisterClose = () => {
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
    dispatch(setModalClose())
    setFormData({
      firstname: "",
      user_id: "",
      password: "",
    });

    setFormDataErrors({
      firstname: "",
      user_id: "",
      password: "",
    });
  };


  const handleMobileInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (name === "user_id") {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      const limitedValue = numericValue.slice(0, 10); // Limit to 10 digits
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
  
      const errors = {};
  
      if (!/^\d+$/.test(value)) {
        // Check if there are non-numeric characters
        errors.user_id = "Mobile number should contain only numbers";
      } else if (limitedValue.length !== 10) {
        errors.user_id = "Mobile number must be 10 digits";
      } else {
        errors.user_id = "";
      }
  
      setFormDataErrors(errors);
  
      const isFormValid = Object.values(errors).every((error) => !error);
      setFormValid(isFormValid);
    } else {
      // For other fields, simply update the form data
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  
  const { showMobileRegisterModal } = useSelector(
    (state) => state.mobileregisterModal
  );

  const userConfig = useSelector((state) => state.user_config.userConfigData);

  return (
    <>
      {showOTPPopup ? (
        <OtpVerification
          setShowOTPPopup={setShowOTPPopup}
          formData={formData}
          showOTPPopup={showOTPPopup}
          handleOtpPopupClose={handleOtpPopupClose}
        />
      ) : (
        <>
          {showMobileRegisterModal && (
            <>
              <Modal
                show={showMobileRegisterModal}
                onHide={handleMobileRegisterClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="border-class"
              >
                <Modal.Body>
                  <div className="card-deck">
                    <Row className="mb-2">
                      <Col lg="12">
                        <div className="login-logo d-flex justify-content-between align-items-center">
                          <div className="">
                                <img src={logo} />
                          </div>

                          <div className="">
                            <Link onClick={handleMobileRegisterClose}><FaTimes className="fa-lg"/></Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 padding-rt-40">
                      <div className="d-none d-lg-block">
                      <img src={regionsData?.country_code2 === "IN" ? LoginPoster : OtherRegionLoginPoster} className="w-100 rounded" loading="lazy" alt="Login Poster" /> 
                        </div>
                        <div className="login-left-text d-none">
                          <ul>
                            <li>
                              <p>Lorem Ipsum is simply</p>
                            </li>
                            <li>
                              <p>Lorem Ipsum ***</p>
                            </li>
                            <li>
                              <p>Lorem Ipsum is simply dummy text</p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                              </p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                              </p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="login-left-styles">
                          <h6>Register Now</h6>
                          <p>Enjoy ETV Win with More Benefits and Features </p>
                        </div>
                        <div className="form-style register">
                          <form onSubmit={handleSubmit}>
                            <InputField
                              type="text"
                              name="firstname"
                              value={formData.firstname}
                              onChange={handleInputChange}
                              placeholder="Full Name*"
                              autocomplete="off"
                            />
                            <InputField
                              type="tel"
                              name="user_id"
                              value={formData.user_id}
                              onChange={handleMobileInputChange}
                              placeholder="Mobile Number*"
                              autocomplete="off"
                              error={formDataErrors.user_id}
                            />

                            <InputField
                              type="checkbox"
                              name="agreeTerms"
                              checked={formData.agreeTerms}
                              onChange={handleInputChange}
                              checkboxLabel="I agree to the <Link to='/termsandconditions'>TERMS OF USE</Link> and <Link className='privacypolicy' to='/privacypolicy'>PRIVACY POLICY</Link>"
                              // checkboxLabel="I agree to the TERMS OF USE and PRIVACY POLICY"
                            />

                            <button disabled={!formValid || !formData.agreeTerms} className="next-button-color"> {loading ? <Spinner /> : 'Next'}</button>
                          </form>
                          <div className="registe-color">
                            <div className="register-left">
                              <p>
                                Register via
                                <Link onClick={handleEmailRegister}>
                                  &nbsp; Email ID
                                </Link>
                              </p>
                            </div>
                            <div className="register-right">
                              <p>
                                Already a Member?
                                <Link onClick={handleLogin}> Login</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegisterByMobile;
