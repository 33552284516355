import React, { useEffect } from "react";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Faq = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Header /> */}

      <div className="main">
        <Container>
          <h1 className="faqh">Frequently Asked Questions</h1>
          <div className=" about-main">
          <p><strong>1)</strong> How do I create an account on ETV Win</p>
<ol>
<li><strong>A)</strong> You can Signup for ETV Win by using of the below methods:</li>
<li><p>* Email address, Password creation, Mail Verification &amp; followed by Profile Info</p>
<p>* Mobile Number, OTP Verification &amp; followed by Profile info</p></li>
</ol>

<p><strong>2</strong><strong>)</strong> I forgot my password how can I reset it ?</p>
<ol>
<li><strong>A)</strong> In case you have forgotten your password, click on forgot password link and an email would sent to you with a link to reset password.</li>
</ol>
<p><strong>3</strong><strong>)</strong> How I can subscribe to ETV Win App?</p>
<ol>
<li><strong>A) </strong>Subscribe on ETV Win with simple steps and enjoy the service on the web, mobile devices &amp; Smart TVs</li>
 <li><p>Login to ETV Win with your credentials</p>
<p>Go to Profile &amp; click on Subscription status</p>
<p>Select the plan of your choice</p>
<p>Click on Subscribe &amp; make Payment</p>
<p>Or</p>
<p>Login to ETV Win with your credentials</p>
<p>Click on any premium content &amp; hit subscribe button</p>
<p>Select the plan of your choice</p>
<p>Click on Subscribe &amp; make Payment</p>
</li>
</ol>
<p><strong>4</strong>) What are the different subscription plans available?</p>
<ol>
<li><strong>A</strong>) In India, currently two plans are available:</li>
<p><b>Premium Plan:</b></p>
<li><p>Monthly (3 screens) - Rs. 99/-</p></li>
<li><p>Yearly (3 screens) - Rs. 499/-</p></li>
</ol>


<p><strong>5)</strong> How many devices I can stream simultaneously?</p>
<ol>
<li><strong>A</strong>) You can Stream in different platforms for different subscription Plans</li>
<li>
<p>* Premium per month &ndash; Rs .99</p>
<p>Any Devices but restricts to 1 TV in 3</p>
<p>*Premium Per Year &ndash; Rs 499</p>
<p>Any 3 Devices but restricts to 1 TV in 3&nbsp;</p>
</li>
</ol>
<p><strong>6</strong><strong>)</strong> What payment methods are accepted for subscription?</p>

<ol>
<li><strong>A</strong>) Mobile App (Android) &amp; Website: Credit Cards, Debit Cards, Debit Card + ATM Pin, Internet Banking, and UPI</li>
<li><p>Mobile App (IOS):</p>
<p>You can typically use these methods with your Apple ID:</p>
<p>*Apple Pay</p>
<p>*Most credit and debit cards</p>
<p>*Country-specific or region-specific additional methods</p>
</li>
</ol>
<p><strong>7</strong>) How do I upgrade my subscription plan?</p>
<ol>
<li><strong>A</strong>) You can upgrade to the yearly pack at any time, depending on the type of subscription you currently have (monthly) &amp; it is possible only when your subscription period is active. After upgrading, your subscription period will be adjusted considering the pack you have chosen to upgrade and the number of days used in your current subscription.</li>
<li><p>Or</p></li>
<li><p>Just wait for your current subscription to end. Once it does, you can purchase another one.</p></li>
</ol>


<p><strong>8)</strong> Can I cancel my subscription? How?</p>
<ol>
<li><strong>A</strong>) If the subscription is paid via Net banking, UPI, it does not require cancellation and will expire automatically at the end of the current subscription period.</li>
 <li><p>*All plans are non-refundable</p>
<p>*Cancellation does not lead to a refund. It only ensures that you will not be charged a renewal fee in future</p>
<p>*Your access remains active till the end of the current billing period even if you cancel membership midway</p>
<p>*For any other queries regarding your subscription, please reach out to us at support@etvwin.com</p>
</li>
</ol>
<p><strong>9)</strong> . I have opted for Auto-pay option, how I can deactivate it?</p>
<ol>
<li><strong>A</strong>) To deactivate auto-pay, you'll typically need to follow these steps:</li>
<li><p>* Identify the Payment Method: Determine which payment method is set up for auto-pay. It could be a credit/debit card, bank account, or digital payment service like Paytm , phonepay etc...</p>
<p>* Log into Your Account: Access your account on the platform or service where auto-pay is set up. This could be a bank's online portal, a utility company's website, or a subscription service.</p>
<p>* Navigate to Billing or Payment Settings: Look for a section related to billing, payments, or subscriptions. There should be an option to manage your payment settings.</p>
<p>*<u>Find Auto-Pay Settings:</u> Within the payment settings, locate the section specifically for auto-pay or recurring payments.</p>
<p>*<u>Turn Off Auto-Pay:</u> There should be an option to disable or turn off auto-pay. Click on this option to deactivate it.</p>
<p>*<u>Confirm Deactivation (if necessary):</u> Some services may require you to confirm the deactivation of auto-pay. Follow any additional prompts or steps to complete the process.</p>
<p>*<u>Save Changes:</u> After disabling auto-pay, make sure to save your changes if there's a "Save" or "Update" button.</p>
<p>*<u>Verify Deactivation:</u> Check to ensure that auto-pay has been successfully deactivated. You may receive a confirmation message or email confirming the change.</p></li>
</ol>
<p><strong>10</strong>) Despite the payment successful message why my account is inactive?</p>

<ol>

<li><strong>A</strong>) Sometimes it takes a 24 &ndash; 48 hrs. time after a successful payment to activate your subscription or Account.</li>
</ol>
<p>11) . How much does a subscription cost?</p>
<ol>
<li><strong>A</strong>) <strong><u>Inside India </u></strong></li>
<li><p>* Premium per Month &ndash; Rs 99/-</p>
<p>* Premium Per Year &ndash; Rs 499/-</p></li>
<li>
<p><strong><u>Outside India </u></strong></p>
<ul>
<li>Monthly ( 3 screens ) &ndash; 7.99 <strong>US</strong> $</li>
<li>Yearly ( 3 screens ) &ndash; 39.99 <strong>US</strong> $</li>
</ul>
</li>
</ol>


<p><strong>12)</strong> Does the ETV Win App work with Chrome Cast?</p>
<ol>
<li><strong>A)</strong> Yes ETV Win App Support Chrome cast Only For Android TV</li>
<li><p>For Samsung and LG and Fire Stick TV Please Use Third party Screen Sharing Apps based on Device Compatibility</p>
</li>
</ol>
<p><strong>13 </strong>) How to access ETV Win Content on TV using Chrome cast ?</p>
<ol>
<li><strong>A)</strong> * At first, Make sure that your mobile device and TV screen is connected with the same Wi-Fi</li>
<li><p>*Then Tap on the chrome cast Icon &amp; select the device you want to cast on</p>
</li>
</ol>
<p><strong><u>Technical Support </u></strong></p>
<p><strong>1</strong>) I am unable to login to my account? (Mobile Login, Apple id login, E-mail login)</p>
<ol>
<li><strong>A</strong>) If your device is not allowing you to log in Please check your internet connection. If the issue persists, Please visit <u>https://www.etvwin.com/contactus</u> to speak with a member of our customer care team. They will get in touch with you and take care of your problem.</li>
</ol>
<p><strong>2</strong>) My account is deleted how I can recover it?</p>
<ol>
<li><strong>A)</strong> Please visit https://www.etvwin.com/contactus to talk with a member of our customer service team if your account is deleted. They will address your issue and get in contact with you and they will solve your issue as soon as possible.</li>
</ol>
<p><strong>3</strong>) How I can activate ETV Win on my Android TV / Samsung TV / LG TV / Fire TV and Apple TV ?</p>
<p>* Visit http://www.etvwin.com/activatetv by typing the URL in the web browser of your mobile/laptop with your registered ID.</p>
<p>* Enter the code you see on your TV screen and click on verify</p>
<p>Or</p>
<p>* Log into your ETV Win mobile app/website, then click on Activate TV from the Menu icon &amp; enter the code you see on your TV screen &amp; click on Activate</p>
<p>* Once you verify the device, all your account information will be synced with the device such as your profile, My Subscription, Watchlist etc.</p>
<p><strong>4</strong>) My session is automatically signed out, how I can restore it?</p>
<p><u>* Check Internet Connection:</u> Ensure that you have a stable internet connection. Sometimes, a poor or intermittent connection can cause sessions to be terminated.</p>
<p><u>* Clear Browser Cache and Cookies:</u> Your Mobile or Mobile browser&rsquo;s cache and cookies may be causing issues with your session. Try clearing them and then attempt to sign in again.</p>
<p><u>*Restart Your Device :</u> Close and reopen your web browser or Mobile . This can sometimes resolve issues related to session management.</p>
<p><u>* Try a Different Browser or Device:</u> If possible, try signing in using a different web browser or device. This can help determine if the issue is specific to your current setup.</p>
<p><u>* Check Session Timeout Settings:</u> Some websites or applications have session timeout settings that automatically sign users out after a period of inactivity. If this is the case, you may need to sign in again.</p>
<p><u>* Contact Support:</u> If none of the above steps work, Please visit <u>https://www.etvwin.com/contactus</u> to speak with a member of our customer care team. They will get in touch with you and take care of your problem.</p>
<p><strong>5</strong>) How do I update the app to the latest version?</p>
<ol>
<li><strong><u>A</u></strong><u>) * Navigate to Updates:</u> In the App Store or Google Play Store, find the "Updates" tab. This tab typically appears at the bottom of the screen.</li>
</ol>
<p><u>* Check for Updates: </u>Once you're in the Updates tab, the store will automatically check for any available updates for your installed apps. You should see a list of apps that have updates available.</p>
<p><u>* Update All or Selective Update:</u> You usually have the option to update all apps at once or to select specific apps for updating. Choose the option that suits you best.</p>
<p><u>* Update Confirmation:</u> Confirm the update process. Depending on your device settings, you may need to enter your Apple ID password, Google account password, or use biometric authentication (such as fingerprint or Face ID) to confirm the update.</p>
<p><u>* Wait for Download and Installation:</u> The store will download the updates in the background, and once they are downloaded, the apps will be automatically updated. Depending on the size of the updates and your internet connection speed, this process may take a few moment</p>
<p><u>* Restart the App:</u> After the updates are installed, you can open the updated app from your device's home screen.</p>
<p>Remember, if you have automatic updates enabled on your device, apps will update automatically in the background without requiring manual intervention</p>
<p><strong>6)</strong> The app is crashing frequently. How can I fix this?</p>
<ol>
<li><strong>A)</strong> If the application crashes Kindly email contact@etvwin.com or https://www.etvwin.com/contactus with a screen grab or recorded video describing the problems you are having. When they get in touch with you, our Customer Team will address your problem.</li>
</ol>
<p><strong>7)</strong> . ETV Win is loading very slow on my mobile / TV, please help?</p>
<ol>
<li><strong>A) </strong></li>
</ol>
<p><u>*Check Your Internet Connection: </u>Slow loading times can often be attributed to a poor internet connection. Make sure you're connected to a stable and high-speed Wi-Fi network or have a strong cellular signal if you're using mobile data.</p>
<p><u>*Restart the Device:</u> Sometimes, simply restarting your mobile device or TV can help resolve temporary issues that may be causing slow loading times.</p>
<p><u>*Close Background Apps:</u> If you have multiple apps running in the background, they could be consuming resources and affecting the performance of ETV Win. Close any unnecessary apps to free up system resources.</p>
<p><u>*Clear App Cache:</u> Over time, the cache accumulated by the ETV Win app can slow down its performance. Try clearing the app cache from your device's settings and then relaunch the app.</p>
<p><u>*Update the App:</u> Ensure that you're using the latest version of the ETV Win app. Developers often release updates to improve performance and fix bugs. Go to the app store on your device and check for any available updates for the ETV Win app.</p>
<p><u>*Check for Device Updates:</u> If you're experiencing slow loading times on a smart TV or Mobile , make sure that your TV's firmware is up to date. Manufacturers often release updates to improve performance and compatibility.</p>
<p><u>*Contact ETV Win Support:</u> If you've tried the above steps and are still experiencing slow loading times, it's possible that there may be an issue on ETV Win's end. Please Please visit <u>https://www.etvwin.com/contactus</u> to speak with a member of our customer care team. They will get in touch with you and take care of your problem.</p>
<p>8) Video is constantly buffering on my device (device name), please help?</p>
<ol>
<li><u>A) * Check Your Internet Connection:</u> Slow or unstable internet connection is one of the most common causes of buffering issues. Run a speed test on your device to check your internet speed. You can use websites like speedtest.net or download a speed test app from your device's app store. Ensure that you have a stable and high-speed connection, especially if you're streaming HD or 4K content.</li>
</ol>
<p><u>* Restart Your Router: </u>Sometimes, a simple router restart can help improve internet performance. Unplug your router from power, wait for about 30 seconds, and then plug it back in. Allow your router to reboot fully, and then check if the buffering issue persists.</p>
<p><u>* Move Closer to the Router:</u> If you're streaming over Wi-Fi, try moving closer to your router to improve signal strength. Walls and other obstructions can weaken Wi-Fi signals, leading to slower speeds and buffering issues.</p>
<p><u>* Use Wired Connection (Ethernet): </u>If possible, connect your Tv devices directly to your router using an Ethernet cable instead of relying on Wi-Fi. Wired connections typically offer more stable and faster speeds compared to wireless connections.</p>
<p><u>* Close Background Apps:</u> Other apps running in the background on your device could be consuming bandwidth and causing buffering issues. Close any unnecessary apps or processes to free up resources for streaming.</p>
<p><u>* Lower Video Quality Settings:</u> If you're experiencing buffering while streaming HD or 4K content, try lowering the video quality settings to reduce the bandwidth requirements. Most streaming services allow you to adjust video quality settings manually.</p>
<p><u>* Update Your Device Software: </u>Make sure that your device's operating system and streaming apps are up to date. Developers often release updates to fix bugs and improve performance.</p>
<p><u>* Contact Your Internet Service Provider (ISP):</u> If you've tried the above steps and are still experiencing buffering issues, it's possible that there may be an issue with your internet service provider. Contact your ISP's customer support for assistance. They can help diagnose and troubleshoot any issues with your internet connection.</p>
<p><u>*VPN : </u>If you connect to a VPN over Wi-Fi or your network, the content cannot be played</p>
<p>9) Why I am unable to screen cast ETV Win on my TV?</p>
<ol>
<li><u>A) * App Restrictions:</u> For Some Un verified Devices we have restrictions that prevent screen casting or mirroring to external devices like TVs. This restriction could be intentional to protect copyrighted content or due to technical limitations of the app.</li>
</ol>
<p><u>* Device Compatibility:</u> Not all devices support screen casting or screen mirroring. Make sure that both your mobile device or computer and your TV support the same screen casting technology, such as Chrome cast, Air Play,</p>
<p><u>* Network Issues:</u> Screen casting often relies on a stable Wi-Fi network. If your Wi-Fi network is experiencing issues or if there's congestion on the network, it may affect your ability to screen cast effectively.</p>
<p><u>* App or Device Updates:</u> Ensure that both the ETV Win app and your device's operating system are up to date. Developers often release updates to fix bugs and improve compatibility with external devices.</p>
<p><u>* Screen Casting Method: </u>Depending on your device and TV, there may be different methods for screen casting, such as using built-in features like Chrome cast or Air Play, or using third-party apps. Make sure you're using the correct method for your devices.</p>
<p><u>* Troubleshoot Specific Devices:</u> If you're using a specific device or TV brand, consult the manufacturer's support documentation or website for troubleshooting tips related to screen casting.</p>
<p>If you've tried the above steps and are still unable to screen cast ETV Win to your TV Please visit <u>https://www.etvwin.com/contactus</u> to speak with a member of our customer care team. They will get in touch with you and take care of your problem.</p>
<p><strong>10)</strong> I can't connect to the internet. Can I still watch content?</p>
<ol>
<li><strong>A</strong>) No Without internet access, Content cannot be watched.</li>
</ol>
<p><strong><u>Content and Streaming </u></strong>&nbsp;</p>
<p><strong>1)</strong> What type of content is available on the platform?</p>
<ol>
<li><strong>A)</strong> Movies, Shows, Serials, Web series, Events, Recipes, Health Videos, Before TV content.</li>
</ol>
<p><strong>2)</strong> Are the subtitles available?</p>
<ol>
<li><strong>A</strong>) No, subtitles are not available at this time. They will become accessible with feature App updates.</li>
</ol>
<p><strong>3)</strong> . Can I download content for offline viewing?</p>
<ol>
<li><strong>A</strong>) Offline viewing is not available on our app at the moment, but it will be added in a future update.</li>
</ol>
<p><strong>4</strong>) How frequently is new content added to the platform?</p>
<ol>
<li><strong>A</strong>) New Content is Updated Daily .</li>
</ol>
<p><strong>5</strong>) Is there parental control available for restricting content?</p>
<ol>
<li><strong>A</strong>) No Parental control is not available at this time . they will become accessible with feature App updates</li>
</ol>
<p><strong>6)</strong> How do I search for content?</p>
<ol>
<li><strong>A)</strong> Click on Search Icon and the enter the text of content name what you want to watch</li>
</ol>
<p><strong>8</strong>) What is the video quality?&nbsp;</p>
<ol>
<li><strong>A</strong>) Video Qualities are Low to Up to Full HD</li>
</ol>
<p><strong>9)</strong> I'm having trouble streaming content. What should I do?&nbsp;</p>
<ol>
<li><strong>A)</strong> Please check your internet issue if the issue is still persisting please visit to https://www.etvwin.com/contactus</li>
</ol>
<p>Top of Form</p>
<p><strong>10)</strong> Do you have international content dubbed in Telugu?</p>
<ol>
<li><strong>A)</strong> Yes we have some international dubbed content in Telugu.</li>
</ol>
<p><strong>11)</strong> Which other language content you have, which is dubbed in Telugu?</p>
<ol>
<li><strong>A</strong>) We have Tamil &amp; Malayalam movies dubbed in Telugu</li>
</ol>
<p><strong>12)</strong> What are the upcoming movies?</p>
<ol>
<li><strong>A)</strong> You can check in Home screen Upcoming movies section for daily upcoming movie updates</li>
</ol>
<p><strong>13)</strong> Why the video is not playing in my device?</p>
<ol>
<li><strong>A</strong>) Check your internet, if the issue is still persisting persisting Please visit to https://www.etvwin.com/contactus</li>
</ol>
<p><strong>14</strong>) Why I can't see Bal Bharat programs on ETV Win?</p>
<ol>
<li><strong>A)</strong> You can Watch ETV Balbharat Programs on www.etvbalbharat.com</li>
</ol>
<p><strong>16)</strong> Why I am unable to see all episodes of (name of the TV program / show?</p>
<ol>
<li><strong><u>A)</u></strong> Some Content like Sadhguru sai and jai ganesha will have content only up to a week.</li>
</ol>
<p><strong><u>Device Compatibility</u></strong></p>
<p><strong>1) </strong>Which devices are compatible with the platform?</p>
<ol>
<li><strong>A)</strong> We are Available on Android Mobile , iOS Mobile , Android TV , Website , LG TV , Tizen TV , Firestick TV and Apple TV.</li>
</ol>
<p><strong>2)</strong> Can I use the platform on multiple devices simultaneously?</p>
<ol>
<li><strong>A)</strong> You can stream in different platforms for different subscription plans</li>
</ol>
<p>*Premium per Month - Rs.99 Any 3 Devices but restricts to 1 Tv in 3</p>
<p>*Premium Per Year - Rs.499 Any 3 Devices but restricts to 1 Tv in</p>
<p><strong>3)</strong> How do I set up the platform on my Smart TV?</p>
<ol>
<li><strong>A)</strong> To Install ETV Win, please visit the below stores in respective Smart TV platforms:</li>
</ol>
<p>Android TV: Visit Google Play Store, Download &amp; Install ETV Win</p>
<p>iOS Mobile : Vist App Store, Download &amp; Install ETV Win Tv</p>
<p>Fire TV Stick: Visit Amazon App store, Download &amp; Install ETV Win</p>
<p>LG Tv :- Visit Lg App store, Download &amp; Install ETV Win</p>
<p>Tizen Tv :- Visit Tizen store, Download &amp; Install ETV Win</p>
<p>Apple Tv :- Vist App Store, Download &amp; Install ETV Win Tv</p>
<p><strong>4)</strong> Can I watch content on my computer or laptop?</p>
<ol>
<li><strong>A) </strong>Yes , You can Watch Please visit www.etvwin.com to watch content on laptop or computer</li>
</ol>
<p><strong>5</strong>) Which CTVs brands ETV Win is available? (Android TV, Samsung , LG, FireTV &amp; iOS TV)</p>
<ol>
<li><strong>A)</strong> We are Available on Andriod Tv , Firestick Tv , Samsung Tv , Lg Tv and IOS TV.</li>
</ol>
<p><u> </u><strong><u>Billing &amp; Payments</u></strong></p>
<p><strong>1) </strong>I have been charged incorrectly, what should I do now?</p>
<ol>
<li>A) You can please visit to speak with a member of our customer care team. They will get in touch with you and take care of your problem.</li>
</ol>
<p><strong>2)</strong> How can I get a refund?</p>
<ol>
<li><strong>A)</strong> There is no refund policy with us</li>
</ol>
<p><strong>3</strong>) In how many days I can get a refund?</p>
<ol>
<li><strong>A</strong>) There is no refund Policy with us</li>
</ol>
<p><strong>4</strong>) How do I update my payment method / information?</p>
<ol>
<li><strong>A</strong>) It depends upon your payment Method &amp; Information which you have provide on payment time</li>
</ol>
<p><strong>5)</strong> Will I be notified before my subscription renews</p>
<ol>
<li><strong>A</strong>) Yes you will receive a Subscription renew Alert Mail before 10 days</li>
</ol>
<p><strong>6)</strong> Can I get a receipt for my subscription payments?</p>
<ol>
<li><strong>A) </strong>Yes you will receive a Payment successful receipt via mail</li>
</ol>
<p><strong>7</strong>) Are there any additional charges or hidden fees?</p>
<ol>
<li><strong>A)</strong> There is no additional charges and hidden fees</li>
</ol>
<p><strong>8)</strong> When will I be charged for my subscription?</p>
<ol>
<li><strong>A)</strong> You won&rsquo;t be charged if you don&rsquo;t purchase anything</li>
</ol>
<p><strong>9)</strong> Can I cancel my subscription in between and get refund for unused days?</p>
<ol>
<li><strong>A</strong>) No you cannot cancel subscription in between unused days.</li>
</ol>
<p>10) I made a payment by mistake. Can I get a refund?</p>
<ol>
<li>A) Unfortunately, we do not offer refunds for payments made by mistake. Please review all payment details carefully before completing your transaction. If you need further assistance, feel free to contact our support team.</li>
</ol>
<p>&nbsp;</p>
<p><strong><u>Regional Access</u></strong></p>
<p><strong>1) </strong>Is the platform available in regions outside of India?</p>
<ol>
<li><strong>A)</strong> Yes ETV Win Platform is Available all over the world</li>
</ol>
<p><strong>2)</strong> Do you offer customer support in languages other than Telugu?</p>
<ol>
<li><strong>A)</strong> Yes we do offer customer support in Hindi and English</li>
</ol>
<p><strong>3)</strong> How can I switch the language preferences on the platform?</p>
<ol>
<li><strong>A)</strong> You can&rsquo;t switch the language in ETV win App because ETV Win Platform is offering only Telugu content.</li>
</ol>
<p><strong>4)</strong> Do you offer services in my country (name of the country)?</p>
<ol>
<li><strong>A)</strong> ETV Win services is all over the world For Telugu language</li>
</ol>
<p><strong>5)</strong> How much does a subscription cost in my currency?</p>
<p><strong><u>Inside India </u></strong></p>
<p>* Premium per Month &ndash; Rs 99/-</p>
<p>* Premium Per Year &ndash; Rs 499/-</p>
<p><strong><u>Outside India </u></strong></p>
<ul>
<li>Monthly ( 3 screens ) &ndash; 7.99 <strong>US</strong> $</li>
<li>Yearly ( 3 screens ) &ndash; 39.99 <strong>US</strong> $</li>
</ul>
<p><strong>7)</strong>. I took subscription in India; can I use it in the country I am presently residing in (name of the country)?</p>
<ol>
<li><strong>A</strong>) You can watch content only in the region where you purchased the subscription but if you purchase the plan in abroad it will work in india also</li>
</ol>
<p><strong>8</strong>) ETV Win is not available on (aggregator platform name)?</p>
<p><strong>A)</strong></p>
<p>* Samsung: - Samsung TV is only compatible with a few 2019 models. From 2020 onwards, it will work with models from 202<strong>0</strong> and above</p>
<p>* Lg :- Lg TV is only compatible with a few 202<strong>0</strong> models. From 2021 onwards, it will work with models from 2021and above</p>
<p>* Android TV :- A few Android TV apps may not work due to device version issues</p>
<p>* Other TVs :- Some TVs come with webOS instead of Android, and on those TVs, the app will not work</p>
<p><strong><u>Feedback &amp; Suggestions </u></strong></p>
<p><strong>1) </strong>How can I provide feedback about the platform or content?S</p>
<ol>
<li><strong>A)</strong> 1) Go to www.etvwin.com</li>
</ol>
<p>2) Scroll down to footer part there you will find feed back &amp; suggestions form</p>
<p>3) Write your valuable feedback</p>
<p><strong>2)</strong> Can I suggest new features or improvements?</p>
<ol>
<li><strong>A)</strong> Yes you can suggest new features or improvements because we will gave more focus on users suggestions</li>
</ol>
<p><strong>4)</strong> How can I contact customer support for further assistance?</p>
<ol>
<li><strong>A)</strong> You can visit us on <u>https://www.etvwin.com/contactus </u>or give us a call on phone :- +8142212345 , 8008020555 or write us to our Email : <a href="mailto:contact@etvwin.com">contact@etvwin.com</a></li>
</ol>
<ol>
<li><strong> When can I renew the 99 plan?</strong></li>
</ol>
<p>You can renew the 99 plan up to <strong>7 days</strong> before its expiration date. This ensures continuous access to all features without any interruptions.</p>
<ol start="2">
<li><strong> When can I renew the 499 plan?</strong></li>
</ol>
<p>You can renew the 499 plan up to <strong>15 days</strong> before its expiration date. This allows you ample time to maintain your plan's benefits without any service disruption.</p>
<ol start="3">
<li><strong> What happens if I miss renewing within the specified period?</strong></li>
</ol>
<p>If you miss renewing within the given timeframe, your subscription may expire, and you could lose access to the plan&rsquo;s benefits until you renew it.</p>
<ol start="4">
<li><strong> How do I check the expiration date of my plan?</strong></li>
</ol>
<p>You can check the expiration date of your plan by logging into your account and navigating to the <strong>Subscription</strong> or <strong>Plan Details</strong> section.</p>
                   </div>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Faq;
