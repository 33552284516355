import devtools from 'devtools-detect'
import AnalyticsSdk from "./sdk_analytics_v_3.js";
// import "./Style.css";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layout/Layout";
import { useEffect, useRef, useState } from "react";
import { BASE_URL, auth_token } from "./services/config";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getRegionData } from "./Componets/regions/regions";
import { useDispatch, useSelector } from "react-redux";
import { NoInternetScreen } from "./pages/nointernet";
import ErrorBoundary from "./Componets/errorboundary";
import { Col, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaCalendar, FaTimes } from "react-icons/fa";
import { IoMale } from "react-icons/io5";
import { IoMdFemale } from "react-icons/io";
import Verifyotp from "./verifyotp";
import { getUserConfigData } from "./Componets/ParamcConfig/useParamConfig";
import { setShowSuccessMessageClose } from "./Redux/Slice/showprofileSlice";
import "react-datepicker/dist/react-datepicker.css";
import { subYears } from "date-fns";
import { setAuth } from "./Redux/Slice/authSlice";
import { setModalOpen } from "./Redux/Slice/loginModalSlice";
import { getAccountsData } from "./Componets/regions/getAccountData";
import { setUpdateMobileModalShow } from "./Redux/Slice/updateMobileSlice.js";
import { setIsPasswordSucccessModalClosed, setIsPasswordSucccessModalOpen } from "./Redux/Slice/passwordsuccessslice.js";
import { getAccessTokenData } from './Componets/regions/accessTokenData.js';
export const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;

function App() {
  const successgif = "/images/passwordsuccess.png";
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const accessTokenRef = useRef(false);
  const effectRan = useRef(false);
  const useParamRan = useRef(false);

  const hostname = window.location.hostname;
  let analyticsapiUrl
  // const accessTokenRan = useRef(false);

  if (
    hostname === 'etvwinseo.colormoon.in' ||
    hostname === 'stagingweb.etvwin.com' ||
    hostname === 'staging.etvwin.com:3000' ||
    hostname === 'staging.etvwin.com' ||
    hostname === 'localhost'
  ) {
    analyticsapiUrl = "https://apicollect-etvwin.apisaranyu.in/"
  } else if (hostname === 'prodweb.etvwin.com') {
    analyticsapiUrl = "https://apicollect-etvwin.apisaranyu.in/";
  } else if (hostname === 'etvwin.com' || hostname === 'www.etvwin.com') {
    analyticsapiUrl = "https://ingest.etvwin.com/"
  } else {
    // Default to staging URL
    analyticsapiUrl = "https://ingest.etvwin.com/"
  }
  

  const initializeAnalyticsSdk = async () => {
    window.sdk = new AnalyticsSdk("Etv-web-react", {
      apiUrl: analyticsapiUrl,
      batchSize: 1,
      timeout: 3000,
    });
  };



  
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  // console.log("getaccessTokenjsjsj", getaccessToken)

  useEffect(() => {
    initializeAnalyticsSdk();
  }, []);
  // const [showUpdateMobileModal, setShowUpdateMobileModal] = useState(false);
  const { otpVerifyData } = useSelector((state) => state.signup_data);
  const { loginDetails } = useSelector((state) => state?.signin_data || {});
  const { emailRegisterData } = useSelector((state) => state?.email_data || {});
  const { isPasswordSucccessModalOpen, passwordSuccessMessage } = useSelector(
    (state) => state.password_success
  );
  const isMobile = window.innerWidth < 640
  const { accountData } = useSelector((state) => state.account_data);

  let sessionID =
    otpVerifyData?.session ||
    loginDetails?.session ||
    emailRegisterData?.session || localStorage.getItem("session_id")

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [showProfileinfo, setShowProfileinfo] = useState(false);
  const [showVerifyOTPPopup, setShowVerifyOTPPopup] = useState(false);
  const [updatedMobile, setUpdatedMobile] = useState("");
  const { showAddProfile } = useSelector((state) => state.showprofile_details);
  const [addProfileInfoData, setAddProfileInfoData] = useState({});
  const navigate = useNavigate();
  const regionsData = useSelector((state) => state.region.regionsData);

  const [formData, setFormData] = useState({
    dob: new Date(),
    selectedGender: null,
  });

  useEffect(() => {
    if (!accessTokenRef.current) {
      dispatch(getAccessTokenData());
      accessTokenRef.current = true;
    }
  }, [ dispatch])
  const [mobileErrorMessage, setMobilErrorMessage] = useState("")

  const [mobileNumberData, setMobileNumberData] = useState({
    user_id: "",
  });

  const [mobileNumberDataErrors, setMobileNumberDataErrors] = useState({
    user_id: "",
  });

  useEffect(() => {
    if (showAddProfile) {
      setShowProfileinfo(true);
    }
  }, [showAddProfile]);


  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const title = path.replace(/^\//, "");
    document.title = ` ${title.toUpperCase()}`;
  }, [path]);




  // useEffect(() => {
  //   const initializeSdk = async () => {
  //     try {
  //       window.sdk = new AnalyticsSdk("Etv-web-react", {
  //         apiUrl: "https://apicollect-etvwin.apisaranyu.in/",
  //         batchSize: 1,
  //         timeout: 3000,
  //       });
  //     } catch (error) {
  //       console.error("Error initializing AnalyticsSdk:", error);
  //     }
  //   };

  //   initializeSdk(); 

  //   return () => {
  //     window.sdk = null;
  //   };
  // }, []); 

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };

  const handleGenderSelection = (gender) => {
    setFormData((prevData) => ({ ...prevData, selectedGender: gender }));
  };

  const addprofileinfoUpdateurl = `${BASE_URL}users/${sessionID}/account`;

  const handleProfileInfoUpdate = async (e) => {
    e.preventDefault();

    try {
      const formattedDate = formData.dob
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-");

      const response = await axios.put(
        addprofileinfoUpdateurl,
        JSON.stringify({
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            birthdate: formattedDate,
            gender: formData.selectedGender,
            address: "",
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        localStorage.removeItem("addprofileInfo");
        toast.success(response?.data?.data?.message);
        setAddProfileInfoData(response?.data?.data);
        setShowProfileinfo(false);
        // window.location.reload()
        if (
          emailRegisterData?.first_time_login &&
          emailRegisterData?.profile_obj?.region === "IN"
        ) {
          // setShowUpdateMobileModal(true);
          dispatch(setUpdateMobileModalShow({updateMobileNumber: true}))
        } else {
          // window.location.href='/home'
          navigate('/home')
        }
      }

      // await fetchProfileDetails();
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...mobileNumberDataErrors };

    if (!/^\d{10}$/.test(value)) {
      errors.user_id = "Mobile number must be 10 digits";
    } else {
      delete errors.user_id;
    }

    setMobileNumberData({
      ...mobileNumberData,
      [name]: value,
    });

    setMobileNumberDataErrors(errors);
  };

  const handleMobileNumberUpdate = async (event) => {
    event.preventDefault();
    const mobile_update_url = `${BASE_URL}users/${sessionID}/generate_mobile_otp`;

    try {
      const response = await axios.post(
        mobile_update_url,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          profile: {
            user_id:  `0091${mobileNumberData.user_id}` ,
            type: "msisdn",
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        if (response?.data?.success?.status === 200) {
          setShowVerifyOTPPopup(true);
          setUpdatedMobile(response?.data?.success?.data?.mobile_number);
          setMobileNumberData({
            user_id: "",
          });
          toast.success(response?.data?.success?.message);
          // setShowUpdateMobileModal(false);
          dispatch(setUpdateMobileModalShow({updateMobileNumber: false}))
        }
      }
    } catch (error) {
      if (error) {
        if (error?.response?.data?.error?.message) {
          console.log(error?.response?.data?.error?.message);
        }
      }

      if (error.response) {
        console.error(
          "Server responded with an error:",
          error?.response?.data?.error?.message
        );
      }
      if (error?.response?.status === 422) {
        if (
          error?.response?.data?.error?.message ===
          "Your account not yet verified"
        ) {
          setMobileNumberDataErrors({
            ...mobileNumberDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }

        if (
          error?.response?.data?.error?.message === "User account already exist"
        ) {
          setMobilErrorMessage(error?.response?.data?.error?.message)
          setMobileNumberDataErrors({
            ...mobileNumberDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }
      }
    }
  };

  

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const storedData = localStorage.getItem("loginDetailsdata");
  const parsedData = storedData ? JSON.parse(storedData) : null;
  const handleProfileInfoClose = () => {
    setShowProfileinfo(false);
    if(parsedData?.profile_obj?.region === "IN" && parsedData?.login_type === "email" && parsedData?.mobile_number === ""){
      dispatch(setUpdateMobileModalShow({updateMobileNumber: true}))
    } 
    navigate('/home')
  };

  const handleMobileUpdateModalClose = () => {
    // setShowUpdateMobileModal(false);
    dispatch(setUpdateMobileModalShow({updateMobileNumber: false}))
    setMobileNumberData({ user_id: "" });
  setMobileNumberDataErrors({ user_id: "" });
  setMobilErrorMessage("")

  };

  const CustomDatePickerInput = ({ value, onClick }) => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="dob"> DOB</label>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={onClick}
          >
            <input
              className="dob"
              type="text"
              id="dob"
              value={value}
              readOnly
              style={{ cursor: "pointer" }}
              placeholder="Select DOB"
            />
            <span className="calenderIcon">
              <FaCalendar />
            </span>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!effectRan.current) {
      dispatch(getRegionData())
      effectRan.current = true;
    }
  }, [sessionID])


    useEffect(() => {
    if (!useParamRan.current) {
      dispatch(getUserConfigData(regionsData));
      useParamRan.current = true;
    }
  }, [])

 

  // useEffect(() => {
  //   const disableRightClick = (e) => e.preventDefault();
    
  //   document.addEventListener("contextmenu", disableRightClick);
    
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);
  
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent F12 (opens DevTools)
      if (e.keyCode === 123) {
        e.preventDefault();
      }
      // Prevent Ctrl+Shift+I or Cmd+Shift+I (opens Elements tab)
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 73) {
        e.preventDefault();
      }
      // Prevent Ctrl+Shift+J or Cmd+Shift+J (opens Console tab)
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 74) {
        e.preventDefault();
      }
      // Prevent Ctrl+U or Cmd+U (view page source)
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 85) {
        e.preventDefault();
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  
  // useEffect(() => {
  //   const disableDebugger = () => {
  //     // This infinite loop tries to prevent users from opening dev tools for debugging
  //     setInterval(() => {
  //       if (window.console) {
  //         console.log("%c", "color: transparent;");
  //       }
  //     }, 1000);
  //   };
    
  //   disableDebugger();
  // }, []);


  // const [isFullscreen, setIsFullscreen] = useState(false);

  // useEffect(() => {
  //   let initialWidth = window.innerWidth;
  //   let initialHeight = window.innerHeight;

  //   const handleResize = () => {
  //     const currentWidth = window.innerWidth;
  //     const currentHeight = window.innerHeight;

  //     // Only reload if the width has changed and the page is not in fullscreen mode
  //     if (!isFullscreen && currentWidth !== initialWidth) {
  //       window.location.reload();
  //     }
  //   };

  //   const handleFullscreenChange = () => {
  //     // Check if any element is in fullscreen mode
  //     setIsFullscreen(!!document.fullscreenElement);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   document.addEventListener('fullscreenchange', handleFullscreenChange);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     document.removeEventListener('fullscreenchange', handleFullscreenChange);
  //   };
  // }, [isFullscreen]);



// useEffect(() => {
//   if(devtools.isOpen && isMobile){
//    window.location.href = '/'
//   }

// }, [devtools])






// const [isFullscreen, setIsFullscreen] = useState(false);

// useEffect(() => {
//   let initialWidth = window.innerWidth;
//   let initialHeight = window.innerHeight;

//   const handleResize = () => {
//     const currentWidth = window.innerWidth;
//     const currentHeight = window.innerHeight;

//     // Only reload if the width has changed, the page is not in fullscreen mode,
//     // and the change is not triggered by exiting fullscreen mode.
//     if (!isFullscreen && currentWidth !== initialWidth) {
//       window.location.reload();
//     }

//     // Update the initial width and height after resize to avoid repeated reloads
//     initialWidth = currentWidth;
//     initialHeight = currentHeight;
//   };

//   const handleFullscreenChange = () => {
//     // Check if any element is in fullscreen mode
//     const fullscreenStatus = !!document.fullscreenElement;
//     setIsFullscreen(fullscreenStatus);

//     // Update initial dimensions when entering or exiting fullscreen mode to prevent reloads
//     if (fullscreenStatus) {
//       initialWidth = window.innerWidth;
//       initialHeight = window.innerHeight;
//     }
//   };

//   window.addEventListener('resize', handleResize);
//   document.addEventListener('fullscreenchange', handleFullscreenChange);

//   return () => {
//     window.removeEventListener('resize', handleResize);
//     document.removeEventListener('fullscreenchange', handleFullscreenChange);
//   };
// }, [isFullscreen]);




// useEffect(() => {
//   const detectDevTools = () => {
//     const start = performance.now();
//     debugger; // Adding debugger here to slow execution when dev tools are open
//     const end = performance.now();

//     // If there's a noticeable delay, dev tools are probably open
//     const threshold = 100; // Adjust based on your needs
//     if (end - start > threshold) {
//       setDevtools({ isOpen: true });
//     } else {
//       setDevtools({ isOpen: false });
//     }
//   };

//   const interval = setInterval(detectDevTools, 1000); // Check every 1 second
//   return () => clearInterval(interval);
// }, []);

// useEffect(() => {
//   if (devtools.isOpen && isMobile) {
//     window.location.href = '/'; // Redirect to home page
//   }
// }, [devtools, isMobile]);
    
  const lastSessionID = useRef(null);
  
  // useEffect(() => {
  //   dispatch(getUserConfigData(regionsData));
  // }, []) 
  
  
  // useEffect(() => {
  //   dispatch(getAccountsData());
  // }, [sessionID])



  useEffect(() => {
    if (sessionID !== lastSessionID.current) {
      dispatch(getAccountsData());
      lastSessionID.current = sessionID;
    }
  }, [sessionID, dispatch]);


  // const acountsdata = useSelector((state) => state.account_data.accountData);

  // console.log("acountsdatasss", acountsdata)


  const { showSuccessMessage } = useSelector(
    (state) => state.showprofile_details
  );

  const CustomYearNavigation = ({
    previousYearButtonLabel,
    nextYearButtonLabel,
    onClick,
  }) => (
    <div>
      <button onClick={() => onClick(-1)}>{previousYearButtonLabel}</button>
      <button onClick={() => onClick(1)}>{nextYearButtonLabel}</button>
    </div>
  );


  const { accountErrorData } = useSelector(
    (state) => state.account_data
  );  
  
  // console.log("accountErrorData", accountErrorData)
  
  useEffect(() => {
    const errorMes = accountErrorData?.error?.message;
    if(errorMes === "Invalid Session Id"){
      localStorage.removeItem("session_id");
      localStorage.setItem("login_status", false);
      dispatch(setAuth({ isLoggedIn: false, usersessionID: "" }));
    }
  }, [accountErrorData])

const { showStreamLimit } = useSelector((state) => state.streamlimit_details);

  
  // console.log("accountErrorData", accountErrorData)
  


  

const [streamlimitappear, setStreamlimitappear] = useState(false);

useEffect(() => {
  if (showStreamLimit) {
    setStreamlimitappear(true);
  }
}, [showStreamLimit]);


const handleStreamLimitInfoClose = () => {
  setStreamlimitappear(false);
};

const handleLoginModalOpen = () => {
  setStreamlimitappear(false);
  dispatch(setModalOpen())
};

const {updateMobileNumber} = useSelector(state => state.update_mobile_modal)

const logindata = JSON.parse(localStorage.getItem("loginDetailsdata"))
const isUserLoggedin = localStorage.getItem("login_status")

useEffect(() => {
  if (isUserLoggedin === "true" && showProfileinfo === false && parsedData?.profile_obj?.region === "IN" && parsedData?.login_type === "email" && parsedData?.mobile_number === "") {
    dispatch(setUpdateMobileModalShow({ updateMobileNumber: true }));
    localStorage.setItem('showUpdateMobileModal', 'true');
  }
}, [loginDetails, parsedData]);

useEffect(() => {
  const shouldShowModal = localStorage.getItem('showUpdateMobileModal');
  if ((shouldShowModal === 'true' || shouldShowModal === true) && regionsData?.country_code2 === "IN" && updateMobileNumber === true) {
    dispatch(setUpdateMobileModalShow({ updateMobileNumber: false }));
    localStorage.removeItem('showUpdateMobileModal');
  }
}, [dispatch]);

const handlePasswordSuccessModalClose = () => {
  dispatch(setIsPasswordSucccessModalClosed({ isPasswordSucccessModalOpen: false }))
  dispatch(setModalOpen())
}

// console.log("passwordSuccesssjsjjs", passwordSuccess)

// useEffect(() => {
//   if (showAddProfile) {
//     setShowProfileinfo(true);
//   }
// }, [showAddProfile]);


  return (
    <>
        <>
          {showProfileinfo ? (
            <Modal
              show={showProfileinfo}
              onHide={handleProfileInfoClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="md"
              className="border-class"
            >
              <Modal.Body>
                <div className="card-deck row justify-content-md-center ">
                  <div className="col-lg-12">
                    <h3>Add Profile Info</h3>
                    <form onSubmit={handleProfileInfoUpdate}>
                      <div className="profileInfo">
                        <DatePicker
                          id="dob"
                          showYearDropdown
                          yearDropdownItemNumber={80}
                          scrollableYearDropdown
                          minDate={subYears(new Date(), 80)}
                          maxDate={new Date()}
                          className="form-control"
                          selected={formData.dob}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          customInput={<CustomDatePickerInput />}
                          customYearNavigation={<CustomYearNavigation />}
                        />
                        <div className="mb-3">
                          <label>Gender:</label>
                          <div className="gender-buttons">
                            <button
                              type="button"
                              className={`gender-btn ${
                                formData.selectedGender === "Male"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleGenderSelection("Male")}
                            >
                              <IoMale style={{ color: "#f00" }} /> Male
                            </button>
                            <button
                              type="button"
                              className={`gender-btn ${
                                formData.selectedGender === "Female"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleGenderSelection("Female")}
                            >
                              <IoMdFemale style={{ color: "#f00" }} /> Female
                            </button>

                            <button
                              type="button"
                              className={`gender-btn ${
                                formData.selectedGender === "Others"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleGenderSelection("Others")}
                            >
                              Others
                            </button>
                          </div>
                        </div>
                      </div>

                      <button type="submit" className="showprofile">
                        Continue
                      </button>
                      <button
                        type="button"
                        className="btn gray-btn"
                        onClick={handleProfileInfoClose}
                      >
                        Skip
                      </button>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          ) : (
            ""
          )}



{streamlimitappear ? (
            <Modal
              show={streamlimitappear}
              onHide={handleStreamLimitInfoClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="lg"
              className="border-class customeModal"
            >
              <Modal.Body>
            <div className="card-deck row just-content">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <div className="login-left-styles">
                  <h6 className="text-center">
                    You have been logged out of your device.
                    <span>Please login again to continue.</span>
                  </h6>
                  
                </div>

                <div className="form-style">
                  <Row className="justify-content-center">
                    <Col lg={4} md={6} sm={6} xs={12}>
                      <div className="">
                      <Link
                    to=""
                    className="login-button w-100"
                    onClick={handleLoginModalOpen}
                  >
                    Login
                  </Link>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="login-button w-100">
                        <Link to="" onClick={handleStreamLimitInfoClose}>
                         Continue without login
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal.Body>
            </Modal>
          ) : (
            ""
          )}

          {updateMobileNumber ? (
            <Modal
              show={updateMobileNumber}
              // onHide={handleMobileUpdateModalClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="md"
              keyboard={false}
              
              className="border-class"
            >
             <Modal.Body>
  <div className="card-deck row justify-content-md-center">
   {mobileErrorMessage ===  "User account already exist" && <div className="w-100 d-flex justify-content-end">
      <Link onClick={handleMobileUpdateModalClose}>
        <FaTimes className="fa-lg" />
      </Link>
    </div>}
    <div className="col-lg-12">
      <h3 className="mb-3">
        Update the Mobile Number 
      </h3>
      <form className="" onSubmit={handleMobileNumberUpdate}>
        <div className="profileInfo mb-3">
          <input
            type="text"
            className="customTextBox"
            name="user_id"
            maxLength={10}
            inputMode="numeric"
            pattern="[0-9]*"
            value={mobileNumberData.user_id}
            onChange={handleChange}
            placeholder="Mobile Number"
            onKeyPress={handleKeyPress}
            required
          />
          {mobileNumberDataErrors.user_id ? (
            <div className="error">
              {mobileNumberDataErrors.user_id}
            </div>
          ) : null}
        </div>
        <button
          type="submit"
          className="showprofile"
          disabled={
            Object.keys(mobileNumberDataErrors).length > 0 ||
            mobileNumberData.user_id.length !== 10
          }
        >
          Next
        </button>
      </form>
    </div>
  </div>
</Modal.Body>
            </Modal>
          ) : (
            ""
          )}

          {showVerifyOTPPopup ? (
            <Verifyotp
              sessionID={sessionID}
              emailRegisterData={emailRegisterData}
              updatedMobile={updatedMobile}
              showVerifyOTPPopup={showVerifyOTPPopup}
              setShowVerifyOTPPopup={setShowVerifyOTPPopup}
            />
          ) : (
            ""
          )}
         
        </>

      {showSuccessMessage && (
        <div>
          <Modal
            show={showSuccessMessage}
            onHide={() => {
              dispatch(setShowSuccessMessageClose());
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size=""
            className="border-class"
          >
            <Modal.Body>
              <div className="row just-content">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="verifiedMessage">
                    <h6 className="text-center">Updated Successfully!</h6>
                    <div className="text-center">
                      <p className="resend-verified-text">
                        Now both Email/mobile number can be used for Signin!
                      </p>
                      <Link
                        className="customBtn"
                        onClick={() => {
                          dispatch(setShowSuccessMessageClose());
                        }}
                      >
                        OK
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}


      {isPasswordSucccessModalOpen  && (
        <Modal
          show={isPasswordSucccessModalOpen}
          onHide={handlePasswordSuccessModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
          <div className="card-deck">
              <Row className="justify-content-center">
                <Col lg={12} md={12} sm={12}>
                  <div className="successpassword">
                    <img src={successgif} alt="" height="210px" />
                    <h6>Successful!</h6>
                    <p>{`${passwordSuccessMessage}!`}</p>
                      <Link style={{borderRadius: "50px"}} onClick={handlePasswordSuccessModalClose} className="backToHome">
                         Go to Sign in
                      </Link>
                  </div>
                  
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}

{isOnline ? (
  <ErrorBoundary>
<Layout />
  </ErrorBoundary>
 ) : <NoInternetScreen /> }
      <ToastContainer />
    </>
  );
}

export default App;
