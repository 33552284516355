import React, { useRef } from "react";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { Container, Col, Row } from "react-bootstrap";
// import "./ActiveTv.css";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

import axios from "axios";
import {
  GENERATE_TOKEN_URL,
  GENERATE_SESSION_URL,
  auth_token,
  BASE_URL,
  userregionapi,
} from "../../services/config";
import { useSelector } from "react-redux";

const ActiveTv = () => {
  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id") : sessionId;
  const [activatetvotp, setActivatetvotp] = useState(['', '', '', '', '', '']);
  const [currentIndex, setCurrentIndex] = useState(null);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [timer, setTimer] = useState(35);
  const regionsData = useSelector(state => state.region.regionsData)
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, [])


  const handleChange = (index, value) => {
    const newOtp = [...activatetvotp];
    newOtp[index] = value;
    setActivatetvotp(newOtp);

    if (value !== '' && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && activatetvotp[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };

  const clearCookies = () => {
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
    console.log("Cookies cleared.");
  };


  const clearServiceWorkerCaches = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      cacheNames.forEach(async (cacheName) => {
        await caches.delete(cacheName);
        console.log("Cache cleared.");
      });
    }
  };

  const handleGenerateTVToken = async (event) => {
    event.preventDefault();
    const enteredTVOTP = activatetvotp.join("");

    if (!enteredTVOTP) {
      setError("Please enter code");
      return;
    }

    const token = enteredTVOTP;
    const user = { token, session_id: sessionID };

    try {
      const response = await axios.post(
        `${BASE_URL}generate_session_tv`,
        {
          auth_token,
          access_token: getaccessToken?.access_token,
          user,
          region: regionsData?.country_code2,
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        setSuccess("Connecting...");
        setActivatetvotp(["", "", "", "", "", ""]);
        clearCookies();
        await clearServiceWorkerCaches();
        localStorage.setItem("cacheCleared", "true");
        window.location.href = "/home";
      }
    } catch (err) {
      if(err.status === 422){
        setActivatetvotp(["", "", "", "", "", ""]);
         inputRefs[0].current.focus();
         const errorMessage = err?.response?.data?.error?.message || "An error occurred";
         setError(errorMessage);
         console.error("Server responded with an error:", err.status);
      }
    }
  };

  

  return (
    <div className="main-page">
      <div className="main background-layer">
        <div className="row widht-658">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
            <div className="login-left-styles active-center-two">
              <h6>Activate ETV WIN on your TV</h6>
            
         <p className="text-center text-danger">{error ?  `${error}`  : ''}   </p> 
         <p className="text-center text-success">{success ?  `${success}`  : ''}   </p> 
              <p>Enjoy ETV Win with More Benefits and Features </p>
            </div>
            <div className="form-style">
              <form onSubmit={handleGenerateTVToken}>
                <ul className="otp-input-two">
{activatetvotp.map((digit, index) => (
   <li key={index}>

     <input
       key={index}
       autoComplete='off'
       ref={inputRefs[index]}
       type="text"
       value={digit}
      //  onChange={(e) => handleChange(index, e.target.value)}
       onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
          handleChange(index, value);
        }
      }}
       onKeyDown={(e) => handleKeyDown(index, e)}
       maxLength="1"
       onFocus={() => setCurrentIndex(index)} 
       className={currentIndex === index ? 'red-border' : ''}
     />
   </li>
      ))}
                </ul>

                <button type="submit" className="next-button-color">
                  Activate
                </button>
              </form>

              {/* <button onClick={handleGenerateSessionTV}>Generate Session for tv</button> */}
            </div>
          </div>
        </div>
        <div className="padding-betw">
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ActiveTv;
