import axios from 'axios';
import { BASE_URL, auth_token } from '../../services/config';
import { setAccessToken, setAccessTokenData } from '../../Redux/Slice/accesstokenSlice';

export const getAccessTokenData = () => {
    
    return async dispatch => {
    const accessTokenURL = `${BASE_URL}/access_token.gzip?auth_token=${auth_token}`;

    try{
      const response = await axios.get(accessTokenURL)

      if(response.status === 200){
        // console.log("smsmsmsm",response?.data?.data )
        localStorage.setItem("access_token", response?.data?.data?.access_token)
        dispatch(setAccessTokenData({ accessTokenData: response?.data?.data}));
        dispatch(setAccessToken({ accessToken: response?.data?.data?.access_token}));
      }
    }catch(err){
      console.log(err);
    }
  }
  }
