import React, { useEffect, useRef } from "react";
import Header from "../../Componets/Header/Header";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../Componets/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../Componets/inputfield";
import { useState } from "react";
import axios from "axios";
import {
  BASE_URL,
  FEEDBACK_URL,
  USERS_ENDPOINT,
  auth_token,
} from "../../services/config";
import { useSelector } from "react-redux";
// import useRegionData from "../../Componets/regions/regions";

const Feedback = () => {
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const contactimg = "/images/contactus.png";
  const successgif = "/images/success.gif";

  // const { region } = useRegionData();
  const regionsData = useSelector((state) => state.region.regionsData);
  const effectRan = useRef(false)

  
  const navigate = useNavigate();
  const [channelDetails, setChannelDetails] = useState([]);
  const [channelTitles, setChannelTitles] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [isFormValid, setIsFormValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [feedbackmodal, setFeedbackmodal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  
  const handleFeebackModalClose = () => {
    setFeedbackmodal(false);
  };

  const [formData, setFormData] = useState({
    email: "",
    showName: "",
    selectedChannel: "",
    comments: "",
  });
  const CHANNELS_URL = `${BASE_URL}catalog_lists/channels.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;

  useEffect(() => {
    if(effectRan.current === false){
      fetchChannelDetails();
      return () => {
        effectRan.current = true
      }
    }
  }, []);

  
  const fetchChannelDetails = async () => {
    try {
      const response = await axios.get(CHANNELS_URL);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        setChannelDetails(responsedata?.catalog_list_items);
        const channelTitles = responsedata?.catalog_list_items.map(
          (item) => item?.display_title
        );
        setChannelTitles(channelTitles);
        // console.log("fetchChannelDetails", responsedata?.catalog_list_items);
        // console.log("channelTitles", channelTitles);
        setFormData({ ...formData, selectedChannel: channelTitles[0] });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFeedbackSend = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(
        FEEDBACK_URL,
        {
          auth_token,
          access_token: getaccessToken?.access_token,
          region: regionsData?.country_code2,
          email_id: formData.email,
          channel_name: formData.selectedChannel,
          show_name: formData.showName,
          comments: formData.comments,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        setFeedbackmodal(true);
        toast.success(response?.data?.message);
        setFormData({
          email: "",
          showName: "",
          selectedChannel: "",
          comments: "",
        });
        // navigate("/home");
        // console.log("feedback data", response?.data);
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    } finally {
      setLoading(false); 
    }
  };

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobile = (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) && !window.MSStream;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "email") {
      const isValidEmail = emailRegex.test(value);
      setEmailError(isValidEmail ? "" : "Invalid email format");
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // const allFieldsFilled =
    //   formData.email.trim() !== "" &&
    //   emailRegex.test(formData.email.trim()) &&
    //   formData.showName.trim() !== "" &&
    //   formData.selectedChannel.trim() !== "" &&
    //   formData.comments.trim() !== "";
    // setIsFormValid(allFieldsFilled);

    // console.log(allFieldsFilled, "allFieldsFilled");
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isFormValid =
    formData.email.trim() !== "" &&
    emailRegex.test(formData.email.trim()) &&
    formData.showName.trim() !== "" &&
    formData.selectedChannel.trim() !== "" &&
    formData.comments.trim() !== "";
  // console.log(isFormValid, "isFormValid............");

  // console.log(isFormValid, "isFormValid");

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      {/* <Header /> */}

      <div className="main ">
        <Container>
          <h4>Feedback </h4>
          <div className="about-main">
            <Row>
              <Col lg={6}>
                <div className="etv-data">
                  <img src={contactimg} />
                  <h6 className="etv-heding-style">ETV Win</h6>
                  <p className="p-heding-style">
                    ETV Building 2nd Floor Ramoji Film City <br /> Hyderabad - 501512
                    - Telangana - India
                  </p>
                  <div className="phone-number-style">
                    <p>
                      Phone: <Link to="tel:8142212345">+91 8142212345, &nbsp; </Link>
                      <Link to="tel:8008020555">+91 8008020555</Link>
                    </p>
                  </div>
                  <div className="emailadres-style">
                    <p>Mon-Sun 7 AM - 10 PM (IST)</p>Email :{" "}
                    <Link to="mailto:contact@etvwin.com">
                      contact@etvwin.com
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6} className="pading-botm-40">
                <div className="form-styling-new content-profiles border-styw">
                  <form onSubmit={(event) => handleFeedbackSend(event, setLoading)}>
                    <div className="form-group lable-space">
                      <label htmlFor="email">Email *</label>
                      <InputField
                        type="email"
                        name="email"
                        autoComplete="off"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {emailError && (
                        <span style={{color: '#ffff00'}} className="error-message">{emailError}</span>
                      )}
                    </div>

                    <div className="form-group lable-space">
                      <label htmlFor="showName">Show Name *</label>
                      <InputField
                        type="text"
                        name="showName"
                        autoComplete="off"
                        value={formData.showName}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group lable-space">
                      <label htmlFor="selectedChannel">
                        Select any channel *
                      </label>
                      <select
                        name="selectedChannel"
                        tabIndex="-1"
                        value={formData.selectedChannel}
                        onChange={handleInputChange}
                      >
                        {channelTitles.map((title, index) => (
                          <option key={index} value={title}>
                            {title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label htmlFor="comments">Comments *</label>
                    <textarea
                      name="comments"
                      value={formData.comments}
                      onChange={handleInputChange}
                    />
                    <button className="next-button-color" disabled={!isFormValid || loading}>
                    {loading ? <Spinner /> : 'Submit'}
                    </button>

                    {/* <button class="next-button-color" onClick={handleFeebackModal}>
                      modalshow
                    </button> */}
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>

      {feedbackmodal && (
        <Modal
          show={feedbackmodal}
          onHide={handleFeebackModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="card-deck">
              <Row className="justify-content-center">
                <Col lg={12} md={12} sm={12}>
                  <div className="successPopup">
                    <img src={successgif} alt="" />
                    <h6>successful!</h6>
                    <p>We appreciate your valuable time Thank you</p>
                    {!isMobile && (
                <Link to="/home" className="backToHome">
                  Back to Home
                </Link>
              )}
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Feedback;
