import React, { useState, useMemo, useEffect } from "react";
import { Form, Modal, Row, Spinner } from "react-bootstrap";
import { FaPlay, FaSearch, FaTimes, FaUndo } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import axios from "axios";
import { useSelector } from "react-redux";
// import { getAnalytics, logEvent } from 'firebase/analytics';
import { guestuserid } from "../../App";

const SearchFilter = () => {
  const { seo_url } = useParams();
  const regionsData = useSelector((state) => state.region.regionsData);
  // const firebaseAnalytics = getAnalytics();
  const { videodetailsitems } = useSelector((state) => state.video_details);
  const verticaldefultimage = "/images/horizontaldefault.jpg";

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [finalSearchResults, setFinalSearchResults] = useState([]);
  const [previousResults, setPreviousResults] = useState([]);
  const [loadMoreResultsData, setLoadMoreResultsData] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [count, setCount] = useState("");
  const getUserId = localStorage.getItem("user_id");
  const getLoginStatus = localStorage.getItem("login_status");
  // console.log("getUserIdskks", getUserId)
  // console.log("getLoginStatus", getLoginStatus)
  const contentId = videodetailsitems?.content_id;
  const [nextPage, setnextPage] = useState(1);
  const navigate = useNavigate();
  const specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const [imageLoadedArray, setImageLoadedArray] = useState(
    new Array(finalSearchResults?.length).fill(false)
  );
  const nonvegicon = "/images/nonveg.png";
  const vegicon = "/images/veg.png";

// useEffect(() => {
//   localStorage.setItem("selectedMenuItem", "search");
// }, [seo_url])

  const handleImageLoad = (index) => {
    setImageLoadedArray((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };


  const handleSearchQuery = useMemo(
    () => async (queryString) => {
      setSearchLoading(true);
      const trimmedQueryString = queryString.trim();

      if (trimmedQueryString === "") {
        setFinalSearchResults([]);
        setCount("");
        setSearchLoading(false);
        return;
      }

      try {
        if (!specialCharPattern.test(trimmedQueryString)) {
          const searchurl = `${BASE_URL}search?q=${encodeURIComponent(trimmedQueryString)}&page_size=24&from=0&start_count=0&page=0&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
          const response = await axios.get(searchurl);

          const results = response.data.data.items || [];
          setFinalSearchResults(results);
          setPreviousResults(results);
          setCount(response.data.data.count);
        } else {
          // When special characters are detected, show previous results
          if (previousResults.length > 0) {
            setFinalSearchResults(previousResults);
          }
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setFinalSearchResults([]);
        setCount("");
      } finally {
        setSearchLoading(false);
      }
    },
    [regionsData, previousResults]
  );



  

  const loadMoreResults = async () => {
    try {
      const searchurl = `${BASE_URL}search?q=${searchQuery}&page_size=24&from=${finalSearchResults.length}&start_count=0&page=${nextPage}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      setLoading(true);
      const response = await axios.get(searchurl);

      const additionalResults = response.data.data.items || [];
      const updatedResults = [...finalSearchResults, ...additionalResults];

      setFinalSearchResults(updatedResults);
      setPreviousResults(updatedResults); // Update previous results
      setnextPage(nextPage + 1);
      setLoadMoreResultsData(response.data.data.items);

      if (response.data.data.items.length <= 5) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    } finally {
      setLoading(false);
    }
  };

  const clearInputData = () => {
    setSearchQuery("");
    setFinalSearchResults([]);
    setPreviousResults([]);
    setCount("");
    setnextPage(1);
    setLoadMore(false);
    setShowIcon(false);
  };

  const handleSearchQueryChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim().length > 0) {
      handleSearchQuery(query.trim());
      setShowIcon(true);
    } else {
      clearInputData();
    }
  };

  useEffect(() => {
    if (finalSearchResults.length < 5 || finalSearchResults.length === 0) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
    }
  }, [finalSearchResults]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchQuery(searchQuery);
    setShowIcon(true);
  };

  const handlesearchUrl = (seo_url) => {
    navigate(seo_url);
  };

  const itemSelected = (index, listItem) => {
    localStorage.setItem("selectedMenuItem", "search");
    localStorage.setItem("selectedItem", "NA")
    // const sourcetab = localStorage.getItem("selectedMenuItem");
    // console.log("listItemsss",listItem)
    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("search_result_clicked", {
        video_name: listItem.title,
        source: "search",
        event_id: 22,
        keyword: searchQuery,
        clicked: true,
        content_type: listItem.media_type ? listItem.media_type : 'NA',
        content_value: listItem.content_value ? listItem.content_value : 'NA',
        device_id: "NA",
        city: regionsData?.city_name,
        tray_name: "NA",
        platform: "web",
        state: regionsData?.state,
        ip: regionsData?.ip,
        postal_code: regionsData?.postal_code,
        u_id: getUserId,
        country: regionsData?.country_name,
        event_time: new Date().toISOString().slice(0, 19),
      });
    }

    // logEvent(firebaseAnalytics, 'search_result_clicked', {
    //   video_name: listItem.title,
    //   keyword: searchQuery,
    //   clicked: true,
    //   content_type: listItem.media_type ? listItem.media_type : 'NA',
    //   content_value: listItem.content_value ? listItem.content_value : 'NA'
    // });

    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source: "search",
        event_id: 21,
        video_name: listItem.title,
        tray_name: "NA",
        platform: "web",
        event_time: new Date().toISOString().slice(0, 19),
        u_id: getLoginStatus === "true" ? getUserId : guestuserid,
        country: regionsData?.country_name,
        content_type: listItem.media_type ? listItem.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: listItem.media_type ? listItem.media_type : 'NA',
      });
    }
  };


  // console.log("FinalSearchResults", finalSearchResults.length)
  // console.log("previousResults", previousResults)


  useEffect(() => {
    handleClose();  // Close modal on navigation change
  }, [seo_url]);  // Depends on URL parameter change

  
  return (
    <div>
      <Form className="search-bar" onSubmit={(e) => e.preventDefault()}>
        <div onClick={handleShow}>
          <div variant="" id="button-addon1">
            <FaSearch className="icon-size" />
          </div>
        </div>
      </Form>

      <div className="popd">
        <Modal show={show} onHide={handleClose} className="entry">
          <Modal.Body>
            <div className="cancle-button">
              <Form onSubmit={handleSubmit}>
                <div className="search-botton-postion">
                  <FaSearch />
                </div>
                <Form.Group controlId="searchQuery">
                  <Form.Control
                    type="text"
                    placeholder="Search for Shows, Serials, Episodes, Movies, Recipes and Videos"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    autoFocus={true}
                    autoComplete="off"
                  />
                </Form.Group>
                {showIcon && (
                  <div className="clear-icon" onClick={clearInputData}>
                    <FaTimes />
                  </div>
                )}
              </Form>
              <p className="cancle-class" onClick={handleClose}>
                Cancel
              </p>
            </div>
            <div className="tetxt">
              {searchQuery ? (
                <h2 className="mt-5 mb-4">
                  {count} results for "{searchQuery}"
                </h2>
              ) : (
                ""
              )}

              {searchLoading ? (
                <div
                  className="text-center mt-4"
                  style={{
                    position: "fixed",
                    top: "60%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1050,
                  }}
                >
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                <div className="row">
                  {searchQuery && (finalSearchResults?.length === 0) ? (
                    <h4 className="text-center">No Data Found</h4>
                  ) : (
                    finalSearchResults.map((item, index) => {
                      const maxCharacters = 24;
                      let displayTitle = item?.title?.length > maxCharacters 
                        ? item?.title?.substring(0, maxCharacters) + "..." 
                        : item?.title;

                      const dynamicUrl = item.seo_url;
                      const ourseourl = dynamicUrl.startsWith("/")
                        ? dynamicUrl
                        : `/${dynamicUrl}`;

                        const imageSource = !imageLoadedArray[index]
                        ? verticaldefultimage
                        : item.thumbnails.medium_16_9.url;

                        // console.log("itemsss", item)

                      return (
                        <div key={index} className="col-lg-2 col-md-3 col-sm-6 col-6">
                          <div className="carousel-card extraicon continuewatch">
                            <div
                              onClick={async () => {
                                itemSelected(index, item);
                                handleClose();
                                await new Promise(resolve => setTimeout(resolve, 300))
                                handlesearchUrl(item.seo_url);
                              }}
                            >
                              <div className="extra lock-icon-class cms-container cw-card">
                                <div className="search-img-style">
                                  <div className="thumbnailMovieCard">
                                  <img
                              src={item?.thumbnails?.medium_16_9?.url}
                              alt=""
                            />

                                    <div className="iconcame">
                                      <FaPlay />
                                    </div>


                                    {(item?.sub_genres?.includes("veg") ||
                                      item?.sub_genres?.includes(
                                        "non_veg"
                                      )) && (
                                      <div className="FoodType">
                                        <img
                                          src={
                                            item?.sub_genres?.includes(
                                              "veg"
                                            )
                                              ? vegicon
                                              : nonvegicon
                                          }
                                          // className={`${title === 'Health & Fitness' ? 'healthlayout' : layoutType}`}
                                          loading="lazy"
                                          alt=""
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <h4 className="text-white">{displayTitle}</h4>
                                  {item?.access_control?.is_free === false && (
                                    <div className="lock-postion-class"></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              )}

              <Row></Row>
              {!searchLoading && (
                <div className="text-center paddin-25">
                  {loading ? <Spinner /> : (
                    <>
                      {loadMore && (
                        <button onClick={loadMoreResults} className="loadMoreBtn">
                          Load More <FaUndo />
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SearchFilter;
