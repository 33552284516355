import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Redux/Slice/authSlice";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  OTP_VERIFICATION_URL,
  SIGNIN_OTP_URL,
  auth_token,
  BASE_URL,
} from "../../services/config";
import { setShowProfile } from "../../Redux/Slice/showprofileSlice";
import { setLoginDetails } from "../../Redux/Slice/signinDetailSlice";
import { setModalClose } from "../../Redux/Slice/loginModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";

export default function OtpLogin({
  setShowLoginOTPPopup,
  showLoginOTPPopup,
  handleLoginOtpPopupClose,
  userid,
  mobileValue,
  errorMes,
}) {
  const etvlogo = "/images/win-logo.png";
   const LoginImage = "/images/login-left.png";
   const LoginPoster = "/images/loginposter.jpg";
   const OtherRegionLoginPoster = "/images/WEBLOGINOther.jpg";

  const [currentIndex, setCurrentIndex] = useState(null);
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const dispatch = useDispatch();
  const regionsData = useSelector((state) => state.region.regionsData);
  const [loginOTPs, setLoginOTPs] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [error, setError] = useState("");
  const [timer, setTimer] = useState(40);
  const [timerCompleted, setTimerCompleted] = useState(false);
  const [showSubscripeButton, setshowSubscripeButton] = useState(true);
  const [isResendClicked, setIsResendClicked] = useState(false); // Tracks resend click status
  // const firebaseAnalytics = getAnalytics();

  useEffect(() => {
    let interval;
    if (errorMes !== "Your account not yet verified" || isResendClicked) {
      // Only start the timer if the error is not 'Your account not yet verified' OR 'Resend OTP' has been clicked
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }
  
    return () => clearInterval(interval);
  }, [errorMes, isResendClicked]);

  useEffect(() => {
    if (timer === 0) {
      setTimerCompleted(true);
    }
  }, [timer]);

  const handleInputChange = (index, value) => {
    const newOtp = [...loginOTPs];
    newOtp[index] = value;
    setLoginOTPs(newOtp);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && loginOTPs[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleVerifyOTPwithregisteredVerify = async (event) => {
    event.preventDefault();

    const enteredOTP = loginOTPs.join("");

    if (!enteredOTP) {
      setError("Please enter OTP");
      setCurrentIndex(0); 
      inputRefs[0]?.current?.focus();
      return;
    }

    try {
      const response = await axios.post(
        OTP_VERIFICATION_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            user_id: `0091${mobileValue}`,
            firstname: "",
            region: regionsData?.country_code2,
            type: "msisdn",
            action: "signin",
            key: enteredOTP,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        const userData = response?.data?.data;
          localStorage.setItem("user_id", userData?.user_id);
  
          window.sdk.trackEvent("login_success", {
            method: "phone number",
            event_id: 4,
            u_id: userData?.user_id,
            platform:"web",
            device_id: "NA",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
        dispatch(setModalClose())
        dispatch(setLoginDetails({ loginDetails: response?.data?.data }));
        localStorage.setItem("login_status", true);
        dispatch(
          setAuth({
            isLoggedIn: true,
            sessionId: response?.data?.data?.session,
          })
        );

        localStorage.setItem("session_id", response?.data?.data?.session);
        localStorage.setItem("user_id", response?.data?.data?.user_id);
        setShowLoginOTPPopup(false);
        await fetchActivePlans(response?.data?.data?.session);
        dispatch(setShowRegisterModal({ showRegisterModal: false }))
        setLoginOTPs(["", "", "", "", "", ""]);
      

       
        toast.success("Successfully Loggedin");
        // window.location.reload()
      }
    } catch (err) {
      console.error("Server login responded with an error:", err);
      
      if (err?.response?.status === 422) {
        localStorage.setItem("login_status", false);
        window.sdk.trackEvent("login_failure", {
          method: "phone number",
          error_type: err?.response?.data?.error?.message,
          event_id: 6,
          platform:"web",
          device_id: "NA",
          city: regionsData?.city_name,
          state: regionsData?.state,
          ip: regionsData?.ip,
          postal_code: regionsData?.postal_code,
        });

        if (err?.response?.data?.error?.message === "Enter valid OTP") {
          setError(err?.response?.data?.error?.message);
          setCurrentIndex(0); 
          inputRefs[0]?.current?.focus(); 
        }
      }
    }

    setLoginOTPs(["", "", "", "", "", ""]);
  };

  const handleVerifyOTPWithoutRegisterVerify = async (event) => {
    event.preventDefault();
  
    const enteredOTP = loginOTPs.join("");
    
    if (!enteredOTP) {
      setError("Please enter OTP");
      setCurrentIndex(0); 
      inputRefs[0]?.current?.focus();
      return;
    }
  
    const verifyotpurl = `${BASE_URL}users/verification/${enteredOTP}?mobile_number=0091${userid}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
  
    try {
      const response = await axios.get(verifyotpurl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response?.status === 200) {
        const userData = response?.data?.data;
        localStorage.setItem("user_id", userData?.user_id);
  
        window.sdk.trackEvent("login_success", {
          method: "phone number",
          event_id: 4,
          u_id: userData?.user_id,
          platform: "web",
          device_id: "NA",
          city: regionsData?.city_name,
          state: regionsData?.state,
          ip: regionsData?.ip,
          postal_code: regionsData?.postal_code,
        });
  
        dispatch(setModalClose());
        dispatch(setLoginDetails({ loginDetails: response?.data?.data }));
        localStorage.setItem("addprofileInfo", true);
        dispatch(setShowProfile({ showAddProfile: true }));
        localStorage.setItem("login_status", true);
        dispatch(setAuth({
          isLoggedIn: true,
          sessionId: response?.data?.data?.session,
        }));
        localStorage.setItem("session_id", response?.data?.data?.session);
        localStorage.setItem("user_id", response?.data?.data?.user_id);
  
        await fetchActivePlans(response?.data?.data?.session);
        setShowLoginOTPPopup(false);
        dispatch(setShowRegisterModal({ showRegisterModal: false }));
        setLoginOTPs(["", "", "", "", "", ""]);
  
        // logEvent(firebaseAnalytics, "login_success", {
        //   method: "phone Number",
        //   u_id: response?.data?.data?.user_id,
        //   device_id: "NA",
        // });
      }
    } catch (err) {
      console.error("Server login responded with an error:", err);
  
      if (err?.response?.status === 422) {
        localStorage.setItem("login_status", false);
  
        window.sdk.trackEvent("login_failure", {
          method: "phone number",
          error_type: err?.response?.data?.error?.message,
          event_id: 6,
          platform: "web",
          device_id: "NA",
          city: regionsData?.city_name,
          state: regionsData?.state,
          ip: regionsData?.ip,
          postal_code: regionsData?.postal_code,
        });
        if (err?.response?.data?.error?.message === "Enter valid OTP") {
          setError(err?.response?.data?.error?.message);
          setCurrentIndex(0); 
          inputRefs[0]?.current?.focus(); 
        }
      }
    }
  
    setLoginOTPs(["", "", "", "", "", ""]);
  };

  const fetchActivePlans = async (sessionID) => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
      const response = await axios.get(nextApiUrl);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        if (responsedata && responsedata.length > 0) {
          // setactivePlanId(responsedata[0].plan_id);
          setshowSubscripeButton(false);
          localStorage.setItem("showSubscripeButton", false);
        }

        if (responsedata && responsedata.length == 0) {
          setshowSubscripeButton(true);
          localStorage.setItem("showSubscripeButton", true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    } finally {
      const showSubscripeButton = localStorage.getItem("showSubscripeButton");
      if (showSubscripeButton === "true") {
        // window.location.href = '/subscription';
        // window.location.href = '/home';
      } else {
        // window.location.reload();
      }
    }
  };

  const ResendOTPWithoutMobileRegisterVerify = `${BASE_URL}users/resend_verification_link`;

  const handleResendOTPForNOTVerified = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        ResendOTPWithoutMobileRegisterVerify,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            email_id: `0091${mobileValue}`,
            type: "msisdn",
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success(response?.data?.data?.messgae || response?.data?.data?.message);

        setLoginOTPs(["", "", "", "", "", ""]);

        setTimer(40);
        setTimerCompleted(false);
        setIsResendClicked(true); //
      } else {
      }
    } catch (error) {
      if (error.response) {
        toast.error(error?.response?.data?.error?.message);
        console.error("Server responded with an error:", error?.response);
      }
    }
  };

  const handleResendLoginOTP = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        SIGNIN_OTP_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            password: "",
            user_id: `0091${mobileValue}`,
            type: "msisdn",
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success(response?.data?.message);

        setLoginOTPs(["", "", "", "", "", ""]);

        setTimer(35);
        setTimerCompleted(false);
      } else {
      }
    } catch (error) {
      if (error.response) {
        toast.error(error?.response?.data?.error?.message);
        console.error(
          "Server responded with an error:",
          error?.response
          // error?.response?.data?.error?.message
        );
      }
    }
  };

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);


  const userConfig = useSelector((state) => state.user_config.userConfigData);

  return (
    <>
      <Modal
        show={showLoginOTPPopup}
        onHide={handleLoginOtpPopupClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="border-class"
      >
        <Modal.Body>
          <div className="card-deck row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 padding-rt-40">
            <div className="d-none d-lg-block">
            <img src={regionsData?.country_code2 === "IN" ? LoginPoster : OtherRegionLoginPoster} className="w-100 rounded" loading="lazy" alt="Login Poster" height={400} /> 
                        </div>

              <div className="login-left-text d-none">
                <ul>
                  <li>
                    <p>Lorem Ipsum is simply</p>
                  </li>
                  <li>
                    <p>Lorem Ipsum ***</p>
                  </li>
                  <li>
                    <p>Lorem Ipsum is simply dummy text</p>
                  </li>
                  <li>
                    <p>Lorem Ipsum is simply dummy text of the printing </p>
                  </li>
                  <li>
                    <p>Lorem Ipsum is simply dummy text of the printing </p>
                  </li>
                  <li>
                    <p>Lorem Ipsum is simply dummy text of the printing </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <div className="login-left-styles">
                <h6>OTP Verification</h6>

                {error ? (
                  <p className="text-danger">{error} </p>
                ) : (
                  <>
                    <p>Enjoy ETV Win with More Benefits and Features </p>
                    <div className="pad-t-b">
                      <p className="fs-5 text-danger">
                        {mobileValue}
                      </p>
                    </div>
                  </>
                )}

                <div className="pad-t-b"></div>
              </div>
              <div className="form-style register">
                <div className="form-style">
                  <form
                    onSubmit={
                      errorMes === "Your account not yet verified"
                        ? handleVerifyOTPWithoutRegisterVerify
                        : handleVerifyOTPwithregisteredVerify
                    }
                  >
                    <ul className="otp-input">
                      {loginOTPs.map((digit, index) => (
                        <li key={index}>
                          <input
                            key={index}
                            ref={inputRefs[index]}
                            type="text"
                            value={digit}
                            // onChange={(e) =>
                            //   handleInputChange(index, e.target.value)
                            // }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                handleInputChange(index, value);
                              }
                            }}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            maxLength="1"
                            autoComplete="off"
                            onFocus={() => setCurrentIndex(index)}
                            className={
                              currentIndex === index ? "red-border" : ""
                            }
                          />
                        </li>
                      ))}
                    </ul>
                    <button className="next-button-color">Activate</button>
                  </form>
                </div>
                <div className="time-div">
                {errorMes === "Your account not yet verified" ? (
      <>
        {!isResendClicked ? (
          <Link onClick={handleResendOTPForNOTVerified}>
            Resend OTP
          </Link>
        ) : (
          !timerCompleted && (
            <p>
              Retry in &nbsp;
              <span>00:{timer < 10 ? `0${timer}` : timer}</span>
            </p>
          )
        )}
        {timerCompleted && (
          <Link onClick={handleResendOTPForNOTVerified}>
            Resend OTP
          </Link>
        )}
      </>
    ) : timerCompleted ? (
      <Link onClick={handleResendLoginOTP}>Resend OTP</Link>
    ) : (
      <p>
        Retry in &nbsp;
        <span>00:{timer < 10 ? `0${timer}` : timer}</span>
      </p>
    )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
