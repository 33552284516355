import React from 'react'
import Header from '../../Componets/Header/Header'
import Footer from '../../Componets/Footer/Footer'
import { Container } from 'react-bootstrap'
import { FaDownload, FaFileDownload, FaRupeeSign } from 'react-icons/fa'
// import "./MyTransactions.css"
import axios from "axios";
import { toast } from "react-toastify";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useEffect,useState,useRef } from "react";

import {
    BASE_URL,
    userregionapi,
    auth_token,
  } from "../../services/config";
import { useSelector } from 'react-redux'
const MyTransactions = () => {
  const downloadIcon = "/images/Downloadicon.png";  

  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : sessionId
    const effectRan = useRef(false)
    const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const regionsData = useSelector(state => state.region.regionsData)

   // https://stagingott.etvwin.com/users/3ad2d9aab4a362bb72851778b661753e/user_plans.gzip?auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&tran_history=true&region=IN
   

  useEffect(() => {
    if (effectRan.current === false) {
      fetchTrasactions();
      return () => {
        effectRan.current = true
      }
    }
  }, []);


    
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);


  const [transactionlist, setTransactionlist] = useState([]);
  const [userdataMain, setUserdataMain] = useState({
    subscrip: [],
    expiredPlans: [],
    allPlans: [],
  });

  const [subsub, setSubsub] = useState(true);
  const [subsubplans, setSubsubplans] = useState(true);

  const handleDownloadPdf = async (transaction) => {
    try {
        
      const pdfDownloadUrl = `${BASE_URL}/users/user_plans/download_invoice.pdf` // Replace with your actual API URL
      const invoiceName = `${transaction.transaction_id}.pdf`;

      const response = await fetch(pdfDownloadUrl, {
        method: "POST",
        body: JSON.stringify({
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          transaction_id: transaction.transaction_id,
          user_id: sessionID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to download PDF: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = invoiceName;
      document.body.appendChild(a);
      a.click();

      //saveAs(blob, invoiceName);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const fetchTrasactions = async () => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans.gzip?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}&tran_history=true&region=${regionsData?.country_code2}`;
      const response = await axios.get(nextApiUrl);

      if (response?.status === 200) {
      //  alert(JSON.stringify(response?.data));
      var responsedata = response.data?.data;
      // console.log("trasaction api"+JSON.stringify(responsedata));
      setUserdataMain({
        subscrip: [...responsedata],
        expiredPlans: [...response.data.expired],
        allPlans: [...responsedata, ...response.data.expired],
      });

      if (response.data.length > 0) {
        setSubsub(false);
        const lastIndex = responsedata.length - 1;
        setUserdataMain((prevData) => ({
          ...prevData,
          subscrip: [
            ...prevData.subscrip.slice(0, lastIndex),
            {
              ...prevData.subscrip[lastIndex],
              valid_till: new Date(prevData.subscrip[lastIndex].valid_till),
            },
          ],
        }));
      }

      setSubsubplans(responsedata.length > 0 || responsedata.expired.length > 0 ? false : true);


       // setTransactionlist(response?.data);
       // setRegionCode(responsedata.country_code2);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };
    // console.log("userdataMain",userdataMain.allPlans);
   return (
        <div className='main-page'>
            {/* <Header /> */}
            <div className='main background-layer'>

                <Container fluid className=''>
                    <h6 className='heading-style mb-4'>My Transactions</h6>
                    <div className='my-pre-main'>
                        <div className='my-pre-main-contant-paind'>

                            <div className='genre-main'>
                                <div className=''>
                                    
                                <Table striped bordered hover responsive className="bordered striped myTable">
      <Thead>
        <Tr className='border-class'>
          <Th>Date</Th>
          <Th>Description</Th>
          <Th>Plan Duration</Th>
          <Th>Status</Th>
          <Th>Amount</Th>
        </Tr>
      </Thead>
      <Tbody>
        {userdataMain?.allPlans?.length > 0 ? (
          userdataMain?.allPlans?.map((item, index) => (
            <Tr key={index}>
              <Td>
                {new Date(item.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
              </Td>
              <Td>{item.subscription_title} {item.plan_title}</Td>
              <Td>{formatDate(item.start_date)} to {formatDate(item.valid_till)}</Td>
              <Td className="activeStatus">
                <span className='green-icon-active me-2'>
                  {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                </span>
                {item.price_charged > 0 && (
                  <img
                  className='downloadicon'
                    alt='downloadicon'
                    onClick={() => handleDownloadPdf(item)}
                    src={downloadIcon}
                  />
                )}
              </Td>
              <Td>{item.currency_symbol}{item.price_charged}</Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan="5">Transaction not found</Td>
          </Tr>
        )}
      </Tbody>
    </Table>

                                    {/*  */}


                                </div>



                            </div>


                        </div>
                    </div>

                </Container>



            </div>
                <Footer />
        </div>
    )
}

export default MyTransactions