import { useEffect } from "react";

const useGoogleIMA = (callback) => {
  useEffect(() => {
    let isCancelled = false;
    let retryCount = 0;
    const maxRetries = 3;

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src="${url}"]`);
        if (existingScript) {
          console.log("Google IMA SDK already loaded");
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Script load error for ${url}`));
        document.head.appendChild(script);
      });
    };

    const initializeSDK = async () => {
      try {
        await loadScript("https://imasdk.googleapis.com/js/sdkloader/ima3.js");
        
        // Wait for IMA SDK to fully initialize
        if (!isCancelled && window.google && window.google.ima) {
          console.log("Google IMA SDK initialized successfully");
          if (callback && typeof callback === "function") {
            callback();
          }
        } else {
          throw new Error("Google IMA SDK did not initialize correctly");
        }
      } catch (error) {
        console.error("Failed to load Google IMA SDK:", error);

        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying to load IMA SDK... Attempt ${retryCount}`);
          initializeSDK();  // Retry SDK initialization
        } else {
          console.error("Max retries reached. Failed to initialize IMA SDK.");
        }
      }
    };

    initializeSDK();

    return () => {
      isCancelled = true;
    };
  }, [callback]);
};

export default useGoogleIMA;
