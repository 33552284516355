import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Componets/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Componets/Footer/Footer";
import { useParams } from "react-router";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import axios from "axios";
import Slider from "react-slick";
import { verticalmodel } from "../../layout/gridmodel";
import { useNavigate, Link } from "react-router-dom";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import {
    useComponentUtils,
} from "../../layout/utilscomponent";
import { useSelector } from "react-redux";

const MoreDetails = () => {
    const [movieGenresList, setMovieGenresList] = useState([]);
    const regionsData = useSelector(state => state.region.regionsData)

    const [movieGenresData, setMovieGenresData] = useState({});
    const [healthGenresData, setHealthGenresData] = useState({});
    const [moreData, setMoreData] = useState({});
    const [catalogListData, setCatalogListData] = useState([]);
    const [genresCatalogListData, setGenresCatalogListData] = useState([]);
    const [healthgenresCatalogListData, setHealthGenresCatalogListData] = useState([]);
    const [avilFilters, setAvilFilters] = useState([]);
    const { pageName, friendly_id, moreseo_url, category, main, channelID } = useParams();
    const seo_url = moreseo_url;
    const location = useLocation();
    const navigate = useNavigate();

    const { renderCategoryBlockWithLayoutType } = useComponentUtils(
        movieGenresList

        ,
        true,
        {},
        {},
        false,
        {},
        {},
        {},
        'channels'
    );
    //alert(channelID);



    const getDetailsUrl = () => {
        if (pageName === "recipes" && seo_url === "quick-recipes") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "breakfast") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "snacks") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "soups") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "drinks-juices") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "pickles") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "traditional") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "salads") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "continental") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        }
        if (pageName === "recipes" && seo_url === "master-chefs") {
            return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        } else {
            return null;
        }
    };

    const detailsUrl = getDetailsUrl();

    const fetchMoreRecipeDetails = async (url) => {
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                // console.log("lsss", response?.data?.data);
                // console.log("subcitem", response?.data?.data?.items)
                setMoreData(response?.data?.data);
                setCatalogListData(response?.data?.data?.catalog_list_items);

                // console.log("moredata:", response?.data?.data.catalog_list_items);
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            if (error.message === "Request failed with status code 422") {
                console.log(error.message);
            } else if (error?.response?.status === 422) {
                console.log(error?.response?.data?.error?.message);
            }
        }
    };

    useEffect(() => {
        if (detailsUrl) {
            fetchMoreRecipeDetails(detailsUrl);
        } else {
            return;
        }
    }, []);

    const getMovieGenresUrl = () => {
        if (pageName === "movies-popular-genres") {
            return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        } if (pageName === "movies-heros-special") {
            return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        } if (pageName === "movies-directors-special") {
            return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        } else {
            return null;
        }
    };

    // https://stagingott.etvwin.com/catalog_lists/movies-genre-classic.gzip?item_language=eng&region=IN&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&from=0&page=0&page_size=24&start_count=0


    const movieGenresDetailsURL = getMovieGenresUrl();

    const fetchMovieGenresDetails = async (url) => {
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                // console.log("lsss", response?.data?.data);
                // console.log("subcitem", response?.data?.data?.items)
                setMovieGenresData(response?.data?.data);
                setGenresCatalogListData(response?.data?.data?.catalog_list_items);

                // console.log("moredata:", response?.data?.data.catalog_list_items);
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            if (error.message === "Request failed with status code 422") {
                console.log(error.message);
            } else if (error?.response?.status === 422) {
                console.log(error?.response?.data?.error?.message);
            }
        }
    };

    useEffect(() => {

        getMovieGenere()


        if (movieGenresDetailsURL) {
            fetchMovieGenresDetails(movieGenresDetailsURL);
        } else {
            return;
        }
    }, []);



    const getHealthGenreUrl = () => {
        if (pageName === "etv-life-popular-categories") {
            return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
        } else {
            return null;
        }
    };

    let getMovieGenere = async () => {
        let url = `${BASE_URL}catalog_lists/movies-popular-genres.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=Ay6KCkajdBzztJ4bptpW&from=0&page=0&page_size=24&start_count=0`;
        const response = await axios.get(url);
        // console.log("respons 122---0>", response.data.data.catalog_list_items)
        setMovieGenresList(response.data.data.catalog_list_items)
    }

    const HealthGenresDetailsURL = getHealthGenreUrl();

    const fetchHealthGenresDetails = async (url) => {
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                // console.log("lsss", response?.data?.data);
                // console.log("subcitem", response?.data?.data?.items)
                setHealthGenresData(response?.data?.data);
                setHealthGenresCatalogListData(response?.data?.data?.catalog_list_items);

                // console.log("moredata:", response?.data?.data.catalog_list_items);
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            if (error.message === "Request failed with status code 422") {
                console.log(error.message);
            } else if (error?.response?.status === 422) {
                console.log(error?.response?.data?.error?.message);
            }
        }
    };

    useEffect(() => {
        if (HealthGenresDetailsURL) {
            fetchHealthGenresDetails(HealthGenresDetailsURL);
        } else {
            return;
        }
    }, []);



    return (
        
        <>
        {/* <Header /> */}
                <div className="main background-layer">

                    <Container fluid className="pading-left-right-class">
                        <Row>

                            {movieGenresList.length ?

                                movieGenresList.map((val, i) => {
                                    const layoutType = val.web_layout_type ?? val.layout_type;
                                    let thumbnailSize = null;
                                  
                                    if (layoutType === 'etv-exclusive_banner' || layoutType === 'tv_shows_banner') {
                                      thumbnailSize = 'web_banner';
                                    } else if (['episode', 'latest_episode', 'videos', 'movie_poster'].includes(layoutType)) {
                                      thumbnailSize = 'medium_16_9';
                                    } else if (['tv_shows', 'movies', 'top10'].includes(layoutType)) {
                                      thumbnailSize = 'medium_3_4';
                                    }
                                    return (
                                        renderCategoryBlockWithLayoutType(
                                            layoutType,
                                            val.display_title,
                                            val.layout_structure,
                                            thumbnailSize
                                        )
                                    )
                                })


                                : null}
                        </Row>
                    </Container>
                </div>


            </>

    );
};

export default MoreDetails;
