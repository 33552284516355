import React, { useEffect, useRef, useState } from "react";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { useSelector } from "react-redux";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
// import "./Mylist.css";
import axios from "axios";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Componets/loader";
import { FaPlay } from "react-icons/fa";
import { guestuserid } from "../../App";

const MyList = () => {

  const slider1 = "/images/slider1.png";
  const vegicon = "/images/veg.png";
  const nonvegicon = "/images/nonveg.png";
  const effectRan = useRef(false)

  const { sessionId } = useSelector((state) => state.auth);
  const regionsData = useSelector(state => state.region.regionsData)

  const [watchlistdata, setWatchlistdata] = useState({});
  const [catalogListData, setCatalogListData] = useState([]);
  const [showSelectButton, setShowSelectButton] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedListitemIds, setSelectedListitemIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [watchlistLoading, setWatchlistLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [listitemid, setlistitemid] = useState("");
  const [listtitle, setListTitle] = useState("");
  const [deleteListItem, setDeleteListItem] = useState("");

  const session = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const getMyListURL = `${BASE_URL}users/${session}/playlists/watchlater/listitems?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(effectRan.current === false){
      fetchGetMyListData();
      return () => {
        effectRan.current = true
      }
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  
  const fetchGetMyListData = async () => {
    try {
      setWatchlistLoading(true)
      const response = await axios.get(getMyListURL);
      if (response.status === 200) {
        setWatchlistdata(response?.data?.data);
        setCatalogListData(response?.data?.data?.items);
        const listitemid = response?.data?.data?.items.map(
          (item) => item.listitem_id
        );
        const listitemtitle = response?.data?.data?.items.map(
          (item) => item.title
        );
        setListTitle(listitemtitle);
        setlistitemid(listitemid);
        setWatchlistLoading(false)
        // console.log("watchlistdata:", response?.data?.data);
      }
    } catch (error) {
      setWatchlistLoading(false)
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const handleSelectChange = (itemId) => {
    setSelectedCheckboxes((prevSelectedCheckboxes) => ({
      ...prevSelectedCheckboxes,
      [itemId]: !prevSelectedCheckboxes[itemId],
    }));

    setSelectedListitemIds((prevSelectedListitemIds) => {
      if (prevSelectedListitemIds.includes(itemId)) {
        return prevSelectedListitemIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedListitemIds, itemId];
      }
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    setSelectedListitemIds((prevSelectedListitemIds) => {
      if (selectAll) {
        return [];
      } else {
        return catalogListData.map((item) => item.listitem_id);
      }
    });

    setSelectedCheckboxes(
      Object.fromEntries(
        catalogListData.map((item) => [item.listitem_id, !selectAll])
      )
    );
  };
  const getuserid = localStorage.getItem("user_id")

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteListItem = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.delete(
        `${BASE_URL}users/${session}/playlists/watchlater/listitems/${selectedListitemIds.join(
          ","
        )}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`
      );

      if (response?.status === 200) {
        setSelectedListitemIds([]);
        setDeleteListItem(response?.data);
        setShowDeleteModal(false);
        await fetchGetMyListData();
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const loginStatus = localStorage.getItem("login_status")

  const isDeleteButtonDisabled = Object.values(selectedCheckboxes).every(
    (isSelected) => !isSelected
  );
  const itemSelected = (index,listItem) => {
    localStorage.setItem("selectedMenuItem", "watchlater");
    localStorage.setItem("selectedItem", "NA")
// console.log("listItemjsjs", listItem)
      if (window && window.sdk && window.sdk.trackEvent) {
        window.sdk.trackEvent("artwork_tapped", {
          source:"watchlater",
          video_name:listItem.title,
          event_id: 21,
          event_time: new Date().toISOString().slice(0, 19),
          tray_name: "NA",
          platform: "web",
          u_id:
          loginStatus === "true"
          ? getuserid
          : guestuserid,
          country: regionsData?.country_name,
          content_type: listItem.media_type ? listItem.media_type : 'NA',
          position_within_tray: index+1,
          artwork_type: listItem.media_type ? listItem.media_type : 'NA',
     });
    
      } else {
        console.error('Analytics SDK is not initialized yet.');
        // Handle initialization state or queue events for later
      }
   
  
  };
  return (
    <div className="main-page">
      {/* <Header /> */}
      <div className="main background-layer">
        <div className="layer-bacground">
          <Container fluid className="pading-left-right-class">
            <Row className="justify-content-center">
              <Col lg="12" md="12" sm="12">
                <div className="noData">
                  <h2 className="heading-style">
                    {watchlistdata?.display_title}
                  </h2>
                  {watchlistdata &&
                  watchlistdata?.items?.length > 0 &&
                  !showSelectButton ? (
                    <Link
                      to=""
                      onClick={() => setShowSelectButton(true)}
                      className="applied-filters-style"
                    >
                      Delete
                    </Link>
                  ) : (
                    watchlistdata?.items?.length === 0 && (
                      <p>
                        Browse for Shows, Serials, Episodes, Movies, Recipes and
                        Videos and save a particular video to this section to
                        watch them later
                      </p>
                    )
                  )}

                  {watchlistdata?.items?.length > 0
                    ? showSelectButton && (
                        <div className="select-all">
                          <form className="my-5" onSubmit={handleSubmit}>
                            <div className="radios-left text-start">
                              <label className="checkboxcolorsize">
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAllChange}
                                
                                />
                                <span className="checkmarks"></span>
                                Select All
                              </label>

                              <div className="row mt-2">
                                <div className="col-6">
                                  <Link
                                    onClick={() => setShowSelectButton(false)}
                                    className="btn-delect"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                                <div className="col-6">
                                  <button
                                    disabled={isDeleteButtonDisabled}
                                    onClick={handleDelete}
                                    className={`cancle-delect  ${
                                      isDeleteButtonDisabled ? "disabled" : ""
                                    }`}
                                    style={{
                                      cursor: isDeleteButtonDisabled
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )
                    : ""}

                  {loading ? (
                    <Loader />
                  ) : (
                    <Row>
                    {watchlistLoading ? <div className="text-center"> <Spinner /> </div> : watchlistdata && watchlistdata.items && catalogListData.map((item, index) => {

let formattedDate = '';
if (item.release_date_string) {
  let releaseDate = new Date(item.release_date_string);
  const day = releaseDate.getDate().toString().padStart(2, "0");
  const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
  const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
  formattedDate = `${day} ${monthAbbreviation} ${year}`;
}

let displayTitle = item.title;
if (item.release_date_string) {
  displayTitle = item.title.length > 20 ? `${item.title.substring(0, 26)}... ` : `${item.title} (${formattedDate})`;
} else {
  displayTitle = `${item.title.substring(0, 26)}... `;  // Full title when release_date_string is empty
}

    const dynamicUrl = item.seo_url; // Assuming link is a property of each item
    const ourseourl = dynamicUrl.startsWith("/") ? dynamicUrl : `/${dynamicUrl}`;
    return(
                          <Col
                            lg={2}
                            md={3}
                            sm={4}
                            xs={6}
                            key={index}
                            className="mb-4"
                          >
                            <Link to={ourseourl}    onClick={(event) => {
                                    itemSelected(index,item);
                                  }}   >
                              <div className="carousel-car continuewatch">
                                <div className="lock-icon-class cms-container cw-card">
                                <div className="thumbnailMovieCard relateddet">
                                  <img
                                    src={
                                      item?.thumbnails?.high_16_9?.url ||
                                      slider1
                                    }
                                    width="100%"
                                    alt={`${index}`}
                                  />
                                     <div className="FoodType">
{
  item?.sub_genres?.length > 0 ? (
    item.sub_genres.includes("veg") ? (
      <img
        src={vegicon}
        className=""
        loading="lazy"
        alt="Vegetarian Icon"
      />
    ) : item.sub_genres.includes("non_veg") ? (
      <img
        src={nonvegicon}
        className=""
        loading="lazy"
        alt="Non-Vegetarian Icon"
      />
    ) : null
  ) : null
}

                            </div>
<div className='iconcame'>
                              <p><FaPlay /></p>
                            </div>

                                </div>
                                                  {!item?.access_control?.is_free ? <div className="lock-postion-class"></div> : ""}

                                </div>
                                
                              </div>
                            </Link>
                            <div className="d-flex align-items-center wish-list-movies-list">
                              {showSelectButton && (
                                <div className="radios-left">
                                  <label className="checkboxcolorsize">
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedCheckboxes[item.listitem_id] ||
                                        false
                                      }
                                      onChange={() =>
                                        handleSelectChange(item.listitem_id)
                                      }
                                    />
                                    <span className="checkmarks"></span>
                                  </label>
                                </div>
                              )}
                            { (item.show_meta_data === 'yes' && item?.media_type) && <p className="displayTitle mb-0"> {displayTitle}  </p>}

                            </div>
                          </Col>
   );
  })}
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />

      <div
        className={`modal fade ${showDeleteModal ? "show" : ""}`}
        style={{ display: showDeleteModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <p>
                Are you sure you want to delete selected videos from Watch Later
                section?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteListItem}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyList;
