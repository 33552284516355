import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "./emailvalid.css";
import axios from "axios";
import { useLocation } from "react-router-dom";

import {
  BASE_URL,
  RESEND_VERIFICATION_URL,
  auth_token,
} from "../../services/config";
import Successpage from "../Register/successpage";
import VerifyFailed from "../Register/failedpage";
import { useDispatch, useSelector } from "react-redux";
import { setOTPVerifyData } from "../../Redux/Slice/signupDetailsSlice";

export default function EmailValidation({
  showEmailValidpopup,
  handleEmailPopupClose,
  setShowEmailValidpopup,
}) {
  const EmailValidicon = "/images/emailvalid.png";
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const dispatch = useDispatch();

  const { emailSignupData } = useSelector((state) => state.signup_data);

  const regionsData = useSelector((state) => state.region.regionsData);

  const [confirmationSuccess, setConfirmationSuccess] = useState({});
  const [confirmationfailed, setConfirmationfailed] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const confirmation_token = urlParams.get("confirmation_token");

  useEffect(() => {
    const confirmationToken = confirmation_token;
    verifyConfirmation(confirmationToken, auth_token, getaccessToken?.access_token);
  }, []);

  const verifyConfirmation = async (
    confirmationToken,
    authToken,
    accessToken
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL}users/verification/${confirmationToken}?auth_token=${authToken}&access_token=${getaccessToken?.access_token}`
      );

      if (response?.status === 200) {
        localStorage.setItem("session_id", response?.data?.data?.session);

        dispatch(setOTPVerifyData({ otpVerifyData: response?.data?.data }));
        toast.success("User email verified");
        setConfirmationSuccess(response.data);
        setIsVerified(false);
        setShowSuccess(true);
      }
    } catch (error) {
      console.error("Verification Error:", error);

      if (error?.response?.status === 422) {
        if (
          error?.response?.data?.error?.message === "User is already verified"
        ) {
          setConfirmationfailed(error?.response?.data?.error?.message);
        }
        setShowSuccess(false);
      }
    }
  };

  const handleResendMail = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        RESEND_VERIFICATION_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            email_id: emailSignupData?.email_id,
            region: regionsData?.country_code2,
            type: "email",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success("Verification mail has been sent");
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        toast.error(error?.response?.data?.error?.message);
      }
      console.error(
        "Server responded with an error:",
        error?.response?.data?.error?.message
      );

      if (error?.response?.status === 422) {
        if (error?.response?.data?.code === 1041) {
        } else if (
          error?.response?.data?.error?.message === "Enter valid OTP"
        ) {
        }
      }
    }
  };

  return (
    <>
      {confirmationSuccess ? (
        <Successpage
          confirmationSuccess={confirmationSuccess}
          showSuccess={showSuccess}
          setShowSuccess={setShowSuccess}
          setShowEmailValidpopup={setShowEmailValidpopup}
        />
      ) : (
        <VerifyFailed
          confirmationfailed={confirmationfailed}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
          setShowEmailValidpopup={setShowEmailValidpopup}
        />
      )}
      <div className="email-validation">
        <Modal
          show={showEmailValidpopup}
          onHide={handleEmailPopupClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="border-class email-validation"
        >
          <Modal.Body>
            <div className="card-deck row just-content size-display">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="">
                  <img src={EmailValidicon} alt="email validation icon" />
                  <h6 className="text-center fw-bold fs-3 my-3 text-size-24">
                    Email Validation
                  </h6>
                  <div className="text-center">
                    <p className="emailvalid-text">
                      Verification link has been sent to
                    </p>
                    <p style={{color: "#f00"}} className="email-resp">{emailSignupData?.email_id}</p>

                    <p>Please click the link in that email to continue</p>
                    <p className="holdtext-color">
                      Hold Tight! This may take minutes Didn't get it? &nbsp;
                      <span>
                        <Link onClick={handleResendMail} className="font-blold">
                          Resend Email
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
