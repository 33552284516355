import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bufferLoad: true,
};

export const bufferingSlice = createSlice({
  name: "bufferloading",
  initialState,
  reducers: {
    setBufferLoad: (state, action) => {
        state.bufferLoad = action.payload.bufferLoad  
      },
  },
});

export const {setBufferLoad} = bufferingSlice.actions;

export default bufferingSlice.reducer;