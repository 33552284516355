import React from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import Header from '../../Componets/Header/Header'
import InputField from '../../Componets/inputfield'
import Footer from '../../Componets/Footer/Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect,useState,useRef } from "react";

import {
  BASE_URL,
  userregionapi,
  sessionID,
  auth_token,
} from "../../services/config";
import { useSelector } from 'react-redux'
const ResetPassword = () => {
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const regionsData = useSelector((state) => state.region.regionsData);
  const resetpassword = "/images/notfound.png";
  const verificationfailed = "/images/img_verification_fail.png";
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [newConfPassword, setNewConfPassword] = useState('');
  const [isValidkey, setIsValidkey] = useState({})
  const queryParams = new URLSearchParams(location.search);
  const secretKey = queryParams.get('forgot_pin_secret_key');

const navigate = useNavigate()
// const isVerifiedURL = `${BASE_URL}/users/verify_reset_password_key?key=${secretKey}&auth_token=${auth_token}&access_token=${access_token}`

// useEffect(() => {
//   const verifyPasswordResetKey = async () => {
//     try {
//       const response = await axios.get(isVerifiedURL);
//       if(response?.status === 200){
//         setIsValidkey(response?.data?.data)
//       }
//     console.log('aanannanna', response)
//     } catch (error) {
//       console.log("errorssss", error)
//     } finally {
//     }
//   };

//   verifyPasswordResetKey();  // Call the function inside useEffect
// }, [secretKey]);


  const [showPassword, setShowPassword] = useState(false);

  const [showfailedmessage, setShowfailedmessage] = useState(false);

  const [formDataErrors, setFormDataErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const initialFormData = {
 
    password: "",
    confirmPassword: ""
  };

  const [formData, setFormData] = useState(initialFormData);

  const [formValid, setFormValid] = useState(false);
  const [showValidPopup, setShowValidPopup] = useState(false);

  const [passwordError, setPasswordError] = useState("");

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    
      if (name === "password") {
        if (!isValidPassword(value)) {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            password:
              "Password must be 8 characters or longer and include at least one number or symbol.",
          }));
          setFormValid(false);
        } else {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            password: "",
          }));
          setFormValid(true);
        }
      }

      if (name === "confirmPassword") {
        if (value !== formData.password) {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "Password and confirm password do not match",
          }));
          setFormValid(false);
        } else {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "",
          }));
          setFormValid(true);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  
    function isValidPassword(password) {
      const regex = /^(?=.*[0-9!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      return regex.test(password);
    }
  
 
  const handleChange = (e) => {
    if (e.target.id === 'newpassword') {
      setNewPassword(e.target.value);
    } else if (e.target.id === 'cofirmpassword') {
      setNewConfPassword(e.target.value);
    }
  };

  const handleerrorModalClose = () => {
    setShowfailedmessage(false)
  }
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   const newPasswordValue = newPassword;
  //   const newConfPasswordValue = newConfPassword;
  
  //   if (newPasswordValue === "") {
  //     toast.error('Please enter a password');
  //   } else if (newConfPasswordValue === "") {
  //     toast.error('Please enter confirm password');
  //   } else if (newPasswordValue !== newConfPasswordValue) {
  //     toast.error('Password and confirm password do not match');
  //   } else {
  //     try {
  //       const userData = {
  //         key: secretKey,
  //         password: newPasswordValue,
  //         confirm_password: newConfPasswordValue,
  //         region: regioncode,
  //       };
  
  //       const nextApiUrl = `${BASE_URL}/users/reset_password`;
  //       const response = await axios.post(nextApiUrl, userData);
  
  //       if (response.data.error) {
  //         console.log('Error:', response.data.error.message);
  //       } else {
  //         // Handle success if needed
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   }
  // };
  function isValidPassword(password) {
    const regex = /^(?=.*[0-9!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return regex.test(password);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  if (formData.password === "") {
      toast.error('Please enter a password');
    }else if (!isValidPassword(formData.password)) {
      toast.error('Password must be at least 8 characters long and contain at least one digit or special character');
    } else if (formData.confirmPassword === "") {
      toast.error('Please enter confirm password');
    }else if (!isValidPassword(formData.confirmPassword)) {
      toast.error('Password must be at least 8 characters long and contain at least one digit or special character');
    } else if (formData.password !== formData.confirmPassword) {
      toast.error('Password and confirm password do not match');
    } else {
     // alert(secretKey);
      try {

        const userinfo = {
          key: secretKey,
          password: formData.password,
          confirm_password: formData.confirmPassword,
          region: regionsData?.country_code2
        }; 
        const userData = {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user:userinfo
        };
  
        const nextApiUrl = `${BASE_URL}users/reset_password`;
        const response = await axios.post(nextApiUrl, userData,  {
          headers: {
            "Content-Type": "application/json",
          },
        });

       if(response?.status === 200){
        if(response?.data?.data?.message){
          toast.success(response?.data?.data?.message)
        }
       }

        console.log("responsssss", response)
         
       
      } catch (error) {
        console.log("reset error", error)
        if(error.response.data.error.message){
          setFormData({
            password: "",
      confirmPassword: ""
          })
  
          toast.error(error.response.data.error.message)
          setShowfailedmessage(true)
          navigate('/')
        }

      
        
     
      }
    }
    
  };
  

  return (
    <>
      {/* <Header /> */}
      <div className='main '>
        <Container>
          <h4>Reset Password</h4>
          <div className='about-main'>
            <Row>
              <Col lg={12} className="pading-botm-40">
                <div className="form-styling-new content-profiles border-styw">
                  <form onSubmit={handleSubmit} >
                    <div className="form-group lable-space">
                      <label htmlFor="password">New Password</label>
                      <InputField
                 type="password"
                 name="password"
                 value={formData.password}
                 onChange={handleInputChange}
                 placeholder="New Password *"
                 autoComplete="off"
               />
                    </div>
                    

                    <div className="form-group lable-space">
                      <label htmlFor="cofirmpassword">Confirm Password</label>
                      <InputField
                 type="password"
                 name="confirmPassword"
                 value={formData.confirmPassword}
                 onChange={handleInputChange}
                 placeholder="Confirm Password *"
                 autoComplete="off"
               />  
                    </div>

                    <button className="next-button-color"  disabled={false}>Submit</button>
                    
                  </form>

                  {showfailedmessage  && (
        <Modal
          show={showfailedmessage }
          onHide={handleerrorModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
          <div className="card-deck">
              <Row className="justify-content-center">
                <Col lg={12} md={12} sm={12}>
                  <img src={verificationfailed} alt="" /> 
                  <div className="successPopup">
                    <img src={resetpassword} alt="" /> 
                    <p>Password Reset failed due to Link has expired, Please try again later.</p>
                  </div>
                  
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default ResetPassword;