import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useEffect } from 'react'
import { useState } from 'react'
import { access_token, PLAN_DETAILS_API } from '../services/config'
import { FaCheck } from 'react-icons/fa';
// import "../pages/Subscription-Plans/subscriptionplan.css"

import axios from 'axios'

const Packdetails = () => {

        //http://localhost:8080/packdetails.html?authToken=<ios>&planindex=<index>&&id=<productid>&&region=IN
        const timericon = "/images/timer-icon.png";
        const crossmarkicon = "/images/cross-mark.png";
        const tickicon = "/images/tick.png";

    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [packDetails, setPackDetails] = useState([]);
    const [selectedPackDetailsData, setSelectedPackDetailsData] = useState({});
    const [expiryDate, setExpiryDate] = useState('');
    const [id, setId] = useState('');
    const [mainHeading, setMainHeading] = useState('');
    const [price, setPrice] = useState('');
    const [iOSPrice, setIOSPrice] = useState('');
    const [packDetailsData, setPackDetailsData] = useState([]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [strikedOffPrice, setStrikedOffPrice] = useState('');
    const [strikedPrice, setStrikedPrice] = useState('');
    const [offerDescription, setOfferDescription] = useState('');

    const queryParams = new URLSearchParams(window.location.search);
    const authToken = queryParams.get('authToken');
    const region = queryParams.get('region');
    const planId = queryParams.get('plan_id');
    const productId = queryParams.get('id');
    const platformPrice = queryParams.get('platform');
    const showDate = queryParams.get('show_date');
    const date = queryParams.get('date');

    useEffect(() => {

        let apiBaseUrl = '';
        if (window.location.hostname === 'www.etvwin.com') {
            apiBaseUrl = 'https://prod.api.etvwin.com';
        } else {
          apiBaseUrl = 'https://preprodapi.etvwin.com';
        }

        let final = '';
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (date) {
            const d = new Date(date);
            const dd = d.getDate();
            const mm = d.getMonth();
            const yy = d.getFullYear();
            final = dd + ' ' + months[mm] + ' ' + yy;
        }

        const loadPlans = async (region, token) => {
            try {
                const response = await fetch(`${apiBaseUrl}/catalogs/subscription/items/${planId}?region=${region}&auth_token=${token}&access_token=${access_token}`);
                const data = await response.json();
                // console.log('Fetched data:', data); // Log the full fetched data
                setSelectedPackDetailsData(data.data); // Ensure you're setting the right data structure
                return data;
            } catch (error) {
                console.error('Error loading plans: ', error);
                throw error;
            }
        };
        

        loadPlans(region, authToken)
            .then(selectedPlan => {
                // console.log("nsnsnns", selectedPlan?.data)
                setPackDetails(selectedPlan.data);
                setExpiryDate(final);
                setId(productId);
                setMainHeading(selectedPlan?.data?.title);

                
                for (let i = 0; i < selectedPlan?.data?.plans?.length; i++) {
                    if (productId === selectedPlan?.data?.plans[i].id) {
                        setPackDetailsData(selectedPlan?.data?.pack_details)
                        // console.log("ppppppp", selectedPlan?.data?.pack_details)
                        setPrice(selectedPlan?.data?.plans[i].currency_symbol + selectedPlan?.data?.plans[i].price);
                        // console.log("iossss", selectedPlan?.data?.plans[i])

                        // console.log("lskskksk", selectedPlan?.data?.plans[i].ios_display_price)
                        setIOSPrice(selectedPlan?.data?.plans[i].currency_symbol + selectedPlan?.data?.plans[i].ios_display_price);
                        setTitle(" per " + selectedPlan?.data?.plans[i].title);
                        setMessage(selectedPlan?.data?.plans[i].message);
                        setStrikedOffPrice(selectedPlan?.data?.plans[i].currency_symbol + selectedPlan?.data?.plans[i].striked_price);
                        setStrikedPrice(selectedPlan?.data?.plans[i].striked_price);
                        setOfferDescription(selectedPlan?.data?.plans[i].offer_description);
                    }
                }
                setIsPageLoaded(true);
            })
            .catch(error => {
                console.error('Error loading plans: ', error);
            });

    }, []);

    // console.log("packDetailsjsjsjsj", packDetails)


    function getPlatform() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      
        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS';
        }
      
        // Detect Android
        if (/android/i.test(userAgent)) {
          return 'Android';
        }
      
        // Detect Windows or Mac
        if (/Windows NT|Macintosh/.test(userAgent)) {
          return 'Desktop';
        }
      
        return 'Unknown platform';
      }

      const platform = getPlatform()
      
      console.log("authToken",authToken)

      console.log("region",region)
      console.log("planId",planId)
      console.log("productId",productId)


      // authToken && region && planId && productId
      

  return (
    <>

{/* const queryParams = new URLSearchParams(window.location.search);
        const authToken = queryParams.get('authToken');
        const region = queryParams.get('region');
        const planId = queryParams.get('plan_id');
        const productId = queryParams.get('id'); */}

{(authToken && region && planId && productId) ? 

<>
            <Container>
            <div className="pack-external">
            <h2 className='mt-2 mb-3'>Your current subscription</h2>


            <div class="current-plan" >




            <div class="flex-div">
            
            <p class="plan-text">{mainHeading} plan </p>
                <p class="plan-text"> {platformPrice == "ios" ? iOSPrice : price} </p>


            </div>



            <div class="flex-div">
                <p class="plan-period"><del>{title}</del></p>
                <p class="plan-period"><del>{strikedOffPrice}</del></p>
            </div>



            {/* <div class="flex-div-expiry-text">
                <div><img class="timer-icon" src={timericon} alt="timer" /></div>
                <div class="expires-on">
                    <p>Expiring on</p>
                    <p>{expiryDate}</p>
                </div>
            </div> */}
           

            
           
        </div>


        <div className="current-plan-details">
        <h1>Available features</h1>
        <div className="current-plan-details-list">

        {packDetailsData.map((item, index) => (
  <table key={index}>
    <tr>
      <td className="info-text">{item?.info}</td>
      <td className="value-td">
        {item.value === 'yes' || item.value === 'No' ? (
          <img 
            alt={item.value === 'yes' ? "yes" : "no"} 
            className={item.value === 'yes' ? "tick-mark" : "cross-mark"} 
            src={item.value === 'yes' ? tickicon : crossmarkicon} 
          />
        ) : (
          <span>{item.value}</span>  
        )}
      </td>
    </tr>
  </table>
))}



        </div>
        </div>

        <p class="note-text">HD, Full HD, 4K (2160p) Video Qualities are available only when content is supported in their respective resolutions</p>


 
           
            </div>

            </Container>
        </> : null
}

    </>
  )
}

export default Packdetails