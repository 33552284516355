import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BASE_URL,
  SIGN_OUT_URL,
  USERS_ENDPOINT,
  access_token,
  auth_token,
  sessionID,
} from "../../services/config";
import { setIsprofilepicremove } from "../../Redux/Slice/profileremoveSlice";

export default function RemoveProfilePicture({
  showRemoveProfilePicture,
  handleRemoveProfilePicModalClose,
  setShowRemoveProfilePicture,
  profiledata,
  fetchProfileDetails,
  setProfiledata,
  setUploadedImageData
}) {
  const sessionID = localStorage.getItem("session_id");
  const navigate = useNavigate()
  const PROFILE_DETAILS = `${BASE_URL}users/${sessionID}/account.gzip?auth_token=${auth_token}&access_token=${access_token}`;
const dispatch = useDispatch()
const location = useLocation()

const queryParams = new URLSearchParams(location.search);
const tab = queryParams.get('tab'); 
const isUserLoggedInStatus = localStorage.getItem("login_status");

console.log("skksksks", tab)


const handleProfilePicRemoval = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.put(
      PROFILE_DETAILS,
      {
        firstname: profiledata.firstname,
        email_id: profiledata.email_id,
        mobile_number: profiledata.mobile_number,
        gender: profiledata.gender,
        birthdate: profiledata.birthdate,
        profile_pic: "", 
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response?.status === 200) {
      toast.success(response?.data?.data?.message);
      dispatch(setIsprofilepicremove({ isprofilepicremove: true }));
      setShowRemoveProfilePicture(false);
      setProfiledata({
        ...profiledata,
        profile_pic: "",
      });
      
      // Reset the uploaded image data in the parent
      setUploadedImageData(""); // Add this line
      // setSelectedFile(null); // Optionally clear the selected file

      await fetchProfileDetails();
    }
  } catch (error) {
    console.error("Error updating profile:", error);
    toast.error("Failed to Remove Profile Pic! Please try again.");
  }
};

const handleNoButtonClick = () => {
  setShowRemoveProfilePicture(false)
};

  return (
    <>
      {showRemoveProfilePicture && (
        <Modal
          show={showRemoveProfilePicture}
          onHide={handleNoButtonClick}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="card-deck row just-content">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-left-styles mb-4">
                  <h6 className="text-center">
                    Are you sure you want to remove your profile picture?
                  </h6>
                </div>

                <div className="form-style">
                  <Row className="">
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="red-button w-100">
                        <Link to="" onClick={handleProfilePicRemoval} className="w-100">
                          Yes
                        </Link>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="cancel-button w-100">
                        <Link to="/profile?tab=info" onClick={handleNoButtonClick} className="w-100">
                          No
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
