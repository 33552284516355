import { GoChevronLeft, GoChevronRight } from 'react-icons/go';



export  const CustomPrevArrow     = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <div className="">
        {/* <FontAwesomeIcon icon={faChevronLeft} /> */}
        </div>
      </div>
    );
  };


  // const CustomNextArrow = ({ onClick }) => (
  //   <div className="custom-next-arrow" onClick={onClick}>
  //     <FontAwesomeIcon icon={faChevronRight} />
  //   </div>
  // );

  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block" }}
  //       onClick={onClick}
  //     />
  //   );
  // }
  
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block"}}
  //       onClick={onClick}
  //     />
  //   );
  // }


  const SamplePrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
           <GoChevronLeft />
            {/* <FaChevronLeft className='arrows' style={{ fontWeight: 100, background: 'transparent'}} /> */}
        </div>
    );
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
      <div 
          className={className}
          // className={`arrow ${className}`}
          onClick={onClick}
          style={{ cursor: "pointer" }} 
      >
        <GoChevronRight  />

          {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
      </div>
  );
}
  


  export var twogrid = {
    dots: false,
    // asNavFor: '.twogrid',
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    autoplay: false,
    delay: 3000,
    slidestoshow: 2,
    slidestoscroll: 1,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 1.3,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };

 
  export var singelgrid = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    autoplay: false,
    delay: 3000,
    slidestoshow: 1,
    slidestoscroll: 1,
    // swipe: false,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 1,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 1.2,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 1.1,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };




  

  export var horizontalmodel = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    // swipe: false,
    autoplay: false,
    centermode: false,
    delay: 3000,
    slidestoshow: 6.5,
    slidestoscroll: 3,
    initialSlide: 0,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow/>,
    //   nextArrow: <SampleNextArrow wrapperClassName="arrow-wrapper" />,
    // prevarrow: <SamplePrevArrow wrapperClassName="arrow-wrapper" />,
    // prevarrow: null,
    
  

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 3.5,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: true,
          
        },
      },
    ],
  };


  export var verticalmodel = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    autoplay: false,
    delay: 3000,
    slidestoshow: 9,
    slidestoscroll: 4,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow/>,
    // prevarrow: null,
    swipeToSlide: true,
    // swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 4,
          slidestoscroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 3,
          slidestoscroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 3,
          slidestoscroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  // {
  //   breakpoint: 1024,
  //   settings: {
  //     slidestoshow: 3,
  //     slidestoscroll: 1,
  //     infinite: true,
  //     dots: true,
  //   },
  // },



  export var bigmodel = {
    dots: false,
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    autoplay: false,
    delay: 3000,
    slidestoshow: 6.5,
    slidestoscroll: 4,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow/>,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 3.8,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 2.8,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 1.8,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };

