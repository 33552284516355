import React, { useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../../Componets/Footer/Footer'
import { useEffect } from 'react'
import { auth_token, BASE_URL } from '../../services/config'
import { useSelector } from 'react-redux'
import axios from 'axios'

const TermsAndConditions = () => {
  const effectRan = useRef(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [teamandcondiitons, setTeamandcondiitons] = useState([]);

  useEffect(() => {
    if(effectRan.current === false){
      getTermsData();
      return () => {
        effectRan.current = true
      }
    }
  }, []);


  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);
  
  const TERMS_CONDITONS = `${BASE_URL}/config/static_page/terms_conditions?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;

  const getTermsData = async () => {
    try {
      const response = await axios.get(TERMS_CONDITONS);
  
      const menuitemslistd = response?.data.static_page;
  
      if (response?.status === 200) {
        // console.log("Tearmsconditions:", response?.data);
  
        setTeamandcondiitons(menuitemslistd);
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
     
    }
  };

  




// useEffect(() => {
//   window.scrollTo(0, 0); 
// }, []);




  return (
    <>
    {/* <Header /> */}
  
  

    <div className='main '>
      <Container>

        {/* <h4>{teamandcondiitons.display_title}</h4> */}
        <div className='about-main cms-data'>
        <div className='privachy'>
        <div dangerouslySetInnerHTML={{ __html: teamandcondiitons.description }} />
      {/* <p><strong><span style={{fontSize:"28px"}}><span style={{color:"#FFFFFF"}}>Terms and Conditions</span></span></strong></p>

<p>&nbsp;</p>

<p><span style={{color: "#88888"}}>Eenadu Television Private Limited (Company) owns and operates the website </span><a href="https://www.etvwin.com/home" target="_blank"><span style={{color:"#ffcc00"}}>&ldquo;etvwin.com&rdquo;</span></a><span style={{color: "#88888"}}> (site) and associated mobile Apps, Smart TV Apps (Android TV &amp; Fire TV Stick), mobile sites, other service/s under the name and style of &ldquo;ETV Win&rdquo;, hereinafter referred to as &ldquo;ETV Win platform&rdquo;.</span></p>

<p>&nbsp;</p>July

<p><span style={{color: "#88888"}}>The users of ETV Win platform are cautioned to read all the terms and conditions under which they can access the portal www.etv.co.in and only visitors agreeing for these terms may visit the website. IF YOU DO NOT AGREE TO ANY OF THESE TERMS, YOU ARE ADVISED NOT TO FURTHER ACCESS ETV Win Platform.</span></p>

<p><br />
<span style={{color: "#88888"}}>This is a legal agreement (&quot;Agreement&quot;) between the users of ETV Win Platform and Eenadu Television Private Limited, a Company incorporated in A P, India, under Companies Act 1956, having its registered office at Eenadu Television Private Limited, Ramoji Film City, Hyderabad, Telangana, India, hereinafter referred to as ETPL, the owner, /operator of the portal, hereinafter referred jointly and severally as portal.</span></p>

<p><span style={{color: "#88888"}}>This is a legal agreement (&quot;Agreement&quot;) between the users of ETV Win Platform and Eenadu Television Private Limited, a Company incorporated in A P, India, under Companies Act 1956, having its registered office at Eenadu Television Private Limited, Ramoji Film City, Hyderabad, Telangana, India, hereinafter referred to as ETPL, the owner, /operator of the portal, hereinafter referred jointly and severally as portal.</span></p>

<p><br />
<span style={{color: "#88888"}}>1. Access to ETV Win platform cannot be had unless and until these terms and conditions are read and consented to.</span></p>

<p><br />
<span style={{color: "#88888"}}>2. Ownership: ETV Win platform is owned and operated by Eenadu Television Private Limited a Company in Ramoji Group. The Company hereby reserves the right to change or discontinue any service, feature, offer, gift, contest, design, layout, content, platform, equipment, systems, program, software.</span></p>

<p><br />
<span style={{color: "#88888"}}>3. SUBSCRIPTION PLANS: ETV WIN is available on Subscription basis on various devices like, Television, Mobile, I Pad, Tab etc.., on various platforms like Android, Fire stick, iOS, WebOS, Tizen TV etc. ETPL reserves right to make changes to any of the devices, platforms etc.. With no notice or short notice.</span></p>

<p><br />
<span style={{color: "#88888"}}>The User shall strictly adhere to the number of screens and number of devices as per the plan opted. The user profile requires certain personal data to be provided to identify users. User names are subject to availability.</span></p>

<p><br />
<span style={{color: "#88888"}}>ETPL shall have a right to provide live content of its Satellite Television Channel network ETV on ETV WIN and to discontinue live TV at short or no notice.</span></p>

<p><br />
<span style={{color: "#88888"}}>ETPL shall endeavor to grade the content in accordance with law and industry practice. Users may bring to notice of ETV any issues related to grading.</span></p>

<p><br />
<span style={{color: "#88888"}}>ETPL may provide coupon codes through various channels. The codes shall be utilized within the stipulated time and in the stipulated manner.</span></p>

<p><br />
<span style={{color: "#88888"}}>ETPL reserves the right to integrate any advertisements with the content unless the content is provided as &lsquo;ad-free&rsquo; with Special premium.</span></p>

<p><br />
<span style={{color: "#88888"}}>4. PAYMENT: ETPL has signed up with certain payment gateways who will provide channels of UPI/Net Banking/Debit Card/Credit Card etc.. User agrees to adhere to the Terms and Conditions of payment gateway and settle all payment related issues directly with payment gateway and the concerned banks. ETPL shall not be responsible or liable for any payment related issues with payment gate way or banks.</span></p>

<p><br />
<span style={{color: "#88888"}}>5. QUALITY: Quality of Content displayed on the devices of the user is dependent on the package and plan.</span></p>

<p><br />
<span style={{color: "#88888"}}>The Video quality/video bit rate also depends on the compatibility of the device of the user.</span></p>

<p><br />
<span style={{color: "#88888"}}>The colour, quality, audio and video bit rate shall depend on the original quality of the content, but not on the device of the user. ETPL shall not be responsible for any such quality related issues due to non-availability of the quality in the original content.</span></p>

<p><br />
<span style={{color: "#88888"}}>Any feature offered on ETV WIN is subject to availability.</span></p>

<p><br />
<span style={{color: "#88888"}}>6. ERRORS AND DISCREPANCIES: While ETPL takes every care and caution to make the website free of errors and discrepancies, it is possible for some mistakes to creep into ETV Win Platform by inadvertence, oversight, human error, innocence and honest belief in the information in good faith. ETPL is not liable in respect of innocent dissemination of such information.</span></p>

<p><br />
<span style={{color: "#88888"}}>7. While every endeavour is made to make ETV Win platform free of any bugs, errors, viruses, failures, delay in transmission etc. ETPL does not guarantee that the platform or any of the services offered would be free of bugs, errors, viruses, failures and ETPL does not accept any responsibility or liability for any such bugs, errors, viruses, failures delay in transmission etc in the site.</span></p>

<p><br />
<span style={{color: "#88888"}}>8. ETPL reserves the right to make such additions, alterations, modifications, insertions, improvements, decorations for the betterment and quality enhancement of the material displayed on ETV Win platform. The users shall also agree for such modifications to the material for compatibility with the technological advancement and shall, without demur, agree for any changes that may be required to be made at the discretion of ETPL and also provide such information or material that may be required for update or up gradation of the site in view of any technological advancement.</span></p>

<p><br />
<span style={{color: "#88888"}}>9. ETPL does not give any warranty whatsoever either express or implied, material displayed on ETV Win platform as to the quality, quantity, utility, originality, genuineness, legality, punctuality of any goods or services offered by ETPL or its agents, business affiliates, associates, agencies, franchisees etc. ETPL also does not give any warranty whatsoever either express or implied, of any disruption, interruption, breakdown of the site or services offered by ETPL or its agents, business affiliates, associates, agencies, franchisees etc.</span></p>

<p><br />
<span style={{color: "#88888"}}>10. ETPL is not liable for any damages to any users or visitors or user or third parties for any injury or damage or hardship that they might have been sustained by them on account of any error, failure, discrepancy, defect, shortfall, mis-description; any bugs, errors, viruses, failures, delay in transmission etc., in the site; any defamatory, offensive, excessive, exaggerated, false, malafide, inaccurate, erroneous, blasphemous, obscene, profane, pirated, offensive, salacious, ghastly, indecent, racial or illegal material on ETV Win platform. The visitors/users of ETV Win platform specifically agree to this and discharge ETPL from any liability whatsoever for the said acts.</span></p>

<p><br />
<span style={{color: "#88888"}}>11. ETPL or its staff or Directors shall not be liable for any of the claims, losses, damages, costs, expenses that any one has sustained in any manner whatsoever or likely to sustain in any manner whatsoever by the publication/display of any advertisement.</span></p>

<p><br />
<span style={{color: "#88888"}}>12. ETPL, their staff and directors shall be indemnified by the user for the costs and consequences, including but not limited to reasonable advocates fees and all expenses at actuals for defending themselves in any litigation that may arise out of ETV Win platform.</span></p>

<p><br />
<span style={{color: "#eeeeee"}}>GRIEVANCE REDRESSAL</span><br />
<span style={{color: "#88888"}}>Any complaint relating to content on ETV Win must be made within a reasonable time not exceeding 7 (seven) days from the date of the posting of the content on the portal to the following person appointed by the Company as the Grievance Officer under Rule 11(2)(a) of Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021.</span></p>

<p>&nbsp;</p>

<p><span style={{color: "#88888"}}>Smt P Madhavi<br />
Grievance Officer<br />
Eenadu Television Private Limited<br />
II Floor, ETV Building<br />
Ramoji Film City, Abdullapurmet Mandal,<br />
Ranga Reddy District 501 512 , Telangana<br />
Email: </span><span style={{color: "#88888"}}><a href="mailto:grievance@etvwin.com?subject=Grievance">grievance@etvwin.com</a></span></p>

<p style={{textAlign: "center"}}>&nbsp;</p>

<p style={{textAlign: "center"}}><span style={{color: "#88888"}}>ETV Win is the member of Digital Media Content Regulatory Council (DMCRC)<br />
which is a Self-Regulating Body (SRB) formed by Association of Digital Media Publishers under Rule 12</span></p>

<p style={{textAlign: "center"}}><br />
<span style={{color: "#88888"}}>Monthly compliance report under Rule 18(3) on grievances received and action taken thereon.</span></p>

<p style={{textAlign: "center"}}>&nbsp;</p>

<p style={{textAlign: "center"}}><span style={{color: "#cccccc"}}>&nbsp;&nbsp;&nbsp;</span><span style={{fontSize: "22px"}}><span style={{color: "#88888"}}> Month </span></span><span style={{color: "#88888"}}><span style={{fontSize:"22px"}}>October</span></span><span style={{fontSize:"22px"}}><span style={{color: "#88888"}}> 2024</span></span></p>

<p style={{textAlign: "center"}}>&nbsp;</p>

<div style={{ overflowX: "auto" }}>
  <table
    align="center"
    border="1"
    cellPadding="1"
    cellSpacing="1"
    className="table table-bordered responsive-table"
    style={{ height: "260px", width: "700px", maxWidth: "100%" }}
  >
    <tbody>
      <tr>
        <td><span style={{ color: "#888888" }}>S.NO</span></td>
        <td><span style={{ color: "#888888" }}>Number of grievances received during the month</span></td>
        <td><span style={{ color: "#888888" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>1.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Replies sent during the month</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>2.</span></td>
        <td><span style={{ color: "#777777" }}>Number of clippings deleted</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>3.</span></td>
        <td><span style={{ color: "#777777" }}>Number of apologies tendered</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>4.</span></td>
        <td><span style={{ color: "#777777" }}>Number of appeals filed before SRB</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>5.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Orders /Directions received from SRB</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>6.</span></td>
        <td><span style={{ color: "#777777" }}>Number of appeals filed before IDC</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>7.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Orders /Directions received from IDC</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
    </tbody>
  </table>
</div>

<style jsx>{`
  .responsive-table {
    width: 100%;
  }
  @media (max-width: 768px) {
    .responsive-table td,
    .responsive-table span {
      font-size: 12px;
      padding: 8px;
    }
  }
`}</style>


<p>&nbsp;</p>

<p style={{textAlign: "center"}}><span style={{color: "#88888"}}>&nbsp;&nbsp;&nbsp; <span style={{fontSize:"22px"}}>Month August 2024</span></span></p>

<p style={{textAlign: "center"}}>&nbsp;</p>

<div style={{ overflowX: "auto" }}>
  <table
    align="center"
    border="1"
    cellPadding="1"
    cellSpacing="1"
    className="table table-bordered"
    style={{ height: "260px", width: "700px", maxWidth: "100%" }}
  >
    <tbody>
      <tr>
        <td><span style={{ color: "#88888" }}>S.NO</span></td>
        <td><span style={{ color: "#88888" }}>Number of grievances received during the month</span></td>
        <td><span style={{ color: "#88888" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>1.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Replies sent during the month</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>2.</span></td>
        <td><span style={{ color: "#777777" }}>Number of clippings deleted</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>3.</span></td>
        <td><span style={{ color: "#777777" }}>Number of apologies tendered</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>4.</span></td>
        <td><span style={{ color: "#777777" }}>Number of appeals filed before SRB</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>5.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Orders /Directions received from SRB</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>6.</span></td>
        <td><span style={{ color: "#777777" }}>Number of appeals filed before IDC</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>7.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Orders /Directions received from IDC</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
    </tbody>
  </table>
</div>

<style jsx>{`
  @media (max-width: 768px) {
    .table td, .table span {
      font-size: 12px;
      padding: 8px;
    }
    .table {
      width: 100%;
    }
  }
`}</style>


<p>&nbsp;</p>

<p style={{textAlign: "center"}}><span style={{color: "#88888"}}>&nbsp;&nbsp;&nbsp; <span style={{fontSize:"22px"}}>Month September&nbsp;2024</span></span></p>

<p style={{textAlign: "center"}}>&nbsp;</p>

<div style={{ overflowX: "auto" }}>
  <table
    align="center"
    border="1"
    cellPadding="1"
    cellSpacing="1"
    className="table table-bordered responsive-table"
    style={{ height: "260px", width: "700px", maxWidth: "100%" }}
  >
    <tbody>
      <tr>
        <td><span style={{ color: "#888888" }}>S.NO</span></td>
        <td><span style={{ color: "#888888" }}>Number of grievances received during the month</span></td>
        <td><span style={{ color: "#888888" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>1.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Replies sent during the month</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>2.</span></td>
        <td><span style={{ color: "#777777" }}>Number of clippings deleted</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>3.</span></td>
        <td><span style={{ color: "#777777" }}>Number of apologies tendered</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>4.</span></td>
        <td><span style={{ color: "#777777" }}>Number of appeals filed before SRB</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>5.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Orders /Directions received from SRB</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>6.</span></td>
        <td><span style={{ color: "#777777" }}>Number of appeals filed before IDC</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
      <tr>
        <td><span style={{ color: "#777777" }}>7.</span></td>
        <td><span style={{ color: "#777777" }}>Number of Orders /Directions received from IDC</span></td>
        <td><span style={{ color: "#777777" }}>0</span></td>
      </tr>
    </tbody>
  </table>
</div>

<style jsx>{`
  .responsive-table {
    width: 100%;
  }
  @media (max-width: 768px) {
    .responsive-table td,
    .responsive-table span {
      font-size: 12px;
      padding: 8px;
    }
  }
`}</style>


<p>&nbsp;</p>

<p><span style={{color: "#88888"}}>Only Courts at Hyderabad in the Indian State of Telangana shall exclusively have jurisdiction in case of any dispute or any legal proceedings.</span></p> */}

 

</div>
      
        </div>

      </Container>
      <Footer />
    </div>

  </>
  )
}

export default TermsAndConditions